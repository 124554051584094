import { FC, useCallback, useEffect, useState } from 'react';
import { NotificationsProps } from './Notifications.interfaces';
import styles from './Notifications.module.css';
import Header from '@/components/Header/Header';
import {
  notificationLoadList,
  readAllNotification,
  readNotification,
} from './service';
import {
  LundapadelMainApiNotificationLoadListRequest,
  NotificationInfo,
} from '@/integration-api/server-rest-lundapadelApi';
import NotificationStatusWrapper from '../NotificationStatusWrapper/NotificationStatusWrapper';
import NotificationCard from '../NotificationCard/NotificationCard';
import InnerPadding from '@/components/InnerPadding/InnerPadding';
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch';
import { FetchErrorMessage } from '@/types/types';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';
import { getPlatform } from '@/utils/utils';
import useInfiniteScrol from '@/hooks/useInfiniteScroll';
import ListOverlayLoader from '@/components/SearchRecent/ListOverlayLoader';

const LOADING_LIMIT = 20;

const Notifications: FC<NotificationsProps> = (props): JSX.Element => {
  const { onCloseNotifications } = props;
  const [notifications, setNotifications] = useState<Array<NotificationInfo>>(
    []
  );
  const [reloadNotification, setReloadNotification] = useState(true);
  const [unread, setUnread] = useState(false);

  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const { isEndOfList, listRef, resetIsEndOfList, resetScroll } =
    useInfiniteScrol<HTMLDivElement>();

  const loadNotificationHandle = useCallback(
    async (
      params: LundapadelMainApiNotificationLoadListRequest = {
        limit: LOADING_LIMIT,
        offset: 0,
        system: getPlatform(),
      }
    ) => {
      setLoading(true);
      try {
        const { result } = await notificationLoadList(params);
        setLoading(false);
        if (result) {
          const { offset } = params;
          setOffset(offset + LOADING_LIMIT);
          const loadedNotif = result.infos ?? [];
          setNotifications((prev) =>
            offset === 0 ? loadedNotif : [...prev].concat(loadedNotif)
          );
        }
      } catch (err: unknown) {
        if (err instanceof Promise) {
          const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> =
            await err;
          PopupMessage.open(userErrorMessage ?? errorMessage);
        }
        setLoading(false);
      }
    },
    []
  );

  const onReadNotification = useCallback(async (uid?: string) => {
    try {
      await readNotification(uid ? [uid] : []);
      setReloadNotification(true);
    } catch (err: unknown) {
      if (err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> =
          await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    }
  }, []);

  const handleReadAll = useCallback(async () => {
    try {
      await readAllNotification(notifications?.[0].timestamp);
      setReloadNotification(true);
      setUnread(false);
    } catch (err) {
      if (err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> =
          await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    }
  }, [notifications]);

  const toggleSwitch = async () => {
    setUnread((state) => !state);
    resetScroll();
    await loadNotificationHandle({
      limit: LOADING_LIMIT,
      offset: 0,
      unread: !unread ? !unread : undefined,
      system: getPlatform(),
    });
  };

  useEffect(() => {
    if (reloadNotification) {
      resetScroll();
      loadNotificationHandle();
      setReloadNotification(false);
    }
  }, [loadNotificationHandle, reloadNotification, resetScroll]);

  useEffect(() => {
    if (!isEndOfList) return;
    resetIsEndOfList();
    // если оффсет увеличился, а кол-во уведомлений не изменилось то запросы больше не отправляем
    if (offset > notifications.length + LOADING_LIMIT) return;
    loadNotificationHandle({
      limit: LOADING_LIMIT,
      offset,
      unread: unread ? unread : undefined,
      system: getPlatform(),
    });
  }, [
    isEndOfList,
    loadNotificationHandle,
    notifications.length,
    offset,
    resetIsEndOfList,
    unread,
  ]);

  return (
    <>
      <Header
        handleClick={onCloseNotifications}
        additional={
          <span className={styles['read-all']} onClick={handleReadAll}>
            Прочитать все
          </span>
        }
      >
        Уведомления
      </Header>
      <InnerPadding className={styles['padding']}>
        <div className={styles['toggle']}>
          <span>Показать только непрочитанные</span>
          <ToggleSwitch id="content" onChange={toggleSwitch} checked={unread} />
        </div>
      </InnerPadding>
      <div className={styles['wrapper']} ref={listRef}>
        {notifications && notifications.length > 0 && (
          <NotificationStatusWrapper>
            {notifications.map((notification, index) => (
              <NotificationCard
                first={index === 0}
                key={notification.notificationUid}
                notification={notification}
                onReadNotification={
                  !notification.read ? onReadNotification : undefined
                }
              />
            ))}
          </NotificationStatusWrapper>
        )}
      </div>
      <ListOverlayLoader
        loading={loading}
        className={styles['overlay-loader']}
      />
    </>
  );
};

export default Notifications;
