/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GameCreateReservationResult,
    GameCreateReservationResultFromJSON,
    GameCreateReservationResultFromJSONTyped,
    GameCreateReservationResultToJSON,
} from './GameCreateReservationResult';

/**
 * 
 * @export
 * @interface GameCreateReservationResponse
 */
export interface GameCreateReservationResponse {
    /**
     * 
     * @type {GameCreateReservationResult}
     * @memberof GameCreateReservationResponse
     */
    result?: GameCreateReservationResult;
    /**
     * 
     * @type {Array<object>}
     * @memberof GameCreateReservationResponse
     */
    messages?: Array<object>;
}

export function GameCreateReservationResponseFromJSON(json: any): GameCreateReservationResponse {
    return GameCreateReservationResponseFromJSONTyped(json, false);
}

export function GameCreateReservationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameCreateReservationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : GameCreateReservationResultFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function GameCreateReservationResponseToJSON(value?: GameCreateReservationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': GameCreateReservationResultToJSON(value.result),
        'messages': value.messages,
    };
}

