import styles from './FilterModal.module.css';
import AutoSuggest from '@/components/Autosuggest/Autosuggest';
import { FilterModalProps } from './FilterModal.interfaces';
import FullScreenModal from '../FullScreenModal/FullScreenModal';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import { useAppSelector } from '@/hooks/hooks';
import { Role } from '@/integration-api/server-rest-lundapadelApi';
import { useSearchParams } from 'react-router-dom';
import { searchParamsToObject } from '@/pages/application/rating/utils';
import { useEffect, useState } from 'react';
import { loadListClubCities } from '@/pages/application/profile/service';

function FilterModal(props: FilterModalProps) {
  const { visible, onDone } = props;

  const currentUserRoles = useAppSelector(state => state.auth.currentProfile.roles);
  let [searchParams, setSearchParams] = useSearchParams(window.location.search);

  const [cities, setCities] = useState<Array<string>>([]);

  const handleFormChange: React.ChangeEventHandler<HTMLInputElement> = ({target}) => {
    setSearchParams({
      ...searchParamsToObject(searchParams),
      [target.id]: String(target.checked),
    })
  }

  const handleCityChange = (city: string) => {
    if (!city) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('cityFilter');
      setSearchParams(newSearchParams);
    } else {
      setSearchParams({
        ...searchParamsToObject(searchParams),
        cityFilter: city,
      })
    }
  }

  useEffect(() => {
    loadListClubCities()
      .then(({result}) => {
        setCities(result?.cities ?? []);
      })
  }, [])

  return (
    <FullScreenModal visible={visible} onDone={() => onDone?.(searchParams)} title='Фильтр' >
      <form>
        <label className={styles['checkbox-wrapper']}>
          <span className={styles['checkbox-label']}>Показать в рейтинге моих напарников</span>
            <ToggleSwitch
              id='onlyPartners'
              checked={searchParams.get('onlyPartners') === 'true'}
              onChange={handleFormChange}
            />
        </label>

        {currentUserRoles?.includes(Role.COACH) || currentUserRoles?.includes(Role.ADMIN) ?
          <label className={styles['checkbox-wrapper']}>
            <span className={styles['checkbox-label']}>Показать только неверифицированных игроков</span>
            <ToggleSwitch
              id='onlyUnverified'
              onChange={handleFormChange}
              checked={searchParams.get('onlyUnverified') === 'true'}
            />
          </label> : null
        }

        <label className={styles['checkbox-wrapper']}>
          <span className={styles['checkbox-label']}>Сортировать по внутриигровому рейтингу </span>
          <ToggleSwitch
            id='sortByRating'
            onChange={handleFormChange}
            checked={searchParams.get('sortByRating') === 'true'}
          />
        </label>

        <div className={styles['city']}>
          <div>Фильтрация городов по городу</div>
          <AutoSuggest 
            values={cities}
            defaultSuggestValue={searchParams.get('cityFilter') ?? ''}
            onInputChange={handleCityChange}
            placeholder='Все города'
          />
        </div>
      </form>
    </FullScreenModal>
  );
}

export default FilterModal;
