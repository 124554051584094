/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePaymentResult
 */
export interface CreatePaymentResult {
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentResult
     */
    paymentUid?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentResult
     */
    confirmationToken?: string | null;
}

export function CreatePaymentResultFromJSON(json: any): CreatePaymentResult {
    return CreatePaymentResultFromJSONTyped(json, false);
}

export function CreatePaymentResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePaymentResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentUid': !exists(json, 'paymentUid') ? undefined : json['paymentUid'],
        'confirmationToken': !exists(json, 'confirmationToken') ? undefined : json['confirmationToken'],
    };
}

export function CreatePaymentResultToJSON(value?: CreatePaymentResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentUid': value.paymentUid,
        'confirmationToken': value.confirmationToken,
    };
}

