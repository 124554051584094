import { FormikErrors, useFormik } from 'formik';
import { MessageBlockProps } from '../ChatPage.intefaces';
import styles from './MessageBlock.module.css'
import sendMessageBtn from '@/static/images/icons/send-message-btn.svg'
import { useContext, useRef } from 'react';
import { ChatContext } from '../ChatContext';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';
import HLine from '@/components/HLine/HLine';

function MessageBlock({ handleSendMessage }: MessageBlockProps) {
  const { dialogBlockRef } = useContext(ChatContext);
  const ref = useRef<HTMLFormElement>(null);
  const formik = useFormik<{ message: string }>({
    initialValues: {
      message: ""
    },
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validate: values => {
      const errors: FormikErrors<{ message: string }> = {}
      if(!values.message) {
        errors.message = "Введите сообщение!";
        PopupMessage.open("Введите сообщение!");
      }
      return errors;
    },
    onSubmit: handleFormSubmit,
  })

  function handleFormSubmit(values: { message: string }) {
    handleSendMessage(values.message);
    formik.resetForm();
    setTimeout(() => {
      dialogBlockRef?.current?.scrollTo({ top: dialogBlockRef.current.scrollHeight, behavior: 'smooth' });
    }, 100)
  }

  return (
    <form onSubmit={formik.handleSubmit} ref={ref}>
      <HLine/>
      <div className={styles.wrapper}>
        <input 
          name="message" 
          onChange={formik.handleChange} 
          value={formik.values.message}
          className={styles.input} 
          placeholder='Сообщение' 
        />
        <button type='submit'>
          <img src={sendMessageBtn} alt='send message' />
        </button>
      </div>
      <HLine/>
    </form>
  );
}

export default MessageBlock;
