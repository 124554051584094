/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MetricScreenView {
    MAIN_SCREEN = 'MAIN_SCREEN',
    RATING_SCREEN = 'RATING_SCREEN',
    GAME_SCREEN = 'GAME_SCREEN',
    PLAY_SCREEN = 'PLAY_SCREEN',
    PARTNER_SCREEN = 'PARTNER_SCREEN',
    FILTERS_SCREEN = 'FILTERS_SCREEN',
    SUBSCRIPTION_LIST = 'SUBSCRIPTION_LIST',
    SUBSCRIPTION_SCREEN = 'SUBSCRIPTION_SCREEN'
}

export function MetricScreenViewFromJSON(json: any): MetricScreenView {
    return MetricScreenViewFromJSONTyped(json, false);
}

export function MetricScreenViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetricScreenView {
    return json as MetricScreenView;
}

export function MetricScreenViewToJSON(value?: MetricScreenView | null): any {
    return value as any;
}

