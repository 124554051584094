import { ChangeEvent, FC } from 'react';
import styles from './MatchDetails.module.css';
import infoIcon from '@/static/images/icons/info-icon-orange.svg';
import { Ruble } from '@/components/Icon/icons';
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch';

interface BookingBlockInterface {
  title: string;
  switchLabel: string;
  highlight: boolean;
  switchChecked: boolean;
  switchHandler: (e: ChangeEvent<HTMLInputElement>) => void;
}

const BookingBlock: FC<BookingBlockInterface> = ({
  title,
  switchLabel,
  highlight,
  switchChecked,
  switchHandler,
}) => {
  return (
    <div className={highlight ? styles.highlighted : ''}>
      {title && (
        <div className={highlight ? styles['new-addition'] : styles.warning}>
          {highlight ? <Ruble /> : <img src={infoIcon} alt="info" />}
          <div className={highlight ? '' : styles.warningText}>{title}</div>
        </div>
      )}
      <div className={styles.switchBlock} style={{ paddingBottom: '8px' }}>
        <div className={styles.switchItem}>
          <div className={styles.switchText}>{switchLabel}</div>
          <ToggleSwitch
            checked={switchChecked}
            id="checkboxCourtBooked"
            name="courtBooked"
            onChange={switchHandler}
            checkedColor={highlight ? '#C3D82E' : undefined}
          />
        </div>
      </div>
    </div>
  );
};

export default BookingBlock;
