import styles from './ProfileReservationsPage.module.css';
import Header from "@/components/Header/Header";
import FullScreenPage from "@/components/Layouts/FullScreenPage";
import UnderlineTabs from "@/components/UnderlineTabs/UnderlineTabs";
import { useEffect, useMemo, useState } from "react";
import { reservationLoadList } from "../service";
import { ReservationInfo } from "@/integration-api/server-rest-lundapadelApi";
import { ErrorUtils } from "@/utils/utils";
import ActiveReservationCard from "@/components/ActiveReservationCard/ActiveReservationCard";
import DateWrapper from "../../myGames/components/DateWrapper/DateWrapper";
import { getMatchesByDate } from "../../myGames/components/utils";
import NavBar from "@/components/NavBar/NavBar";
import HLine from "@/components/HLine/HLine";
import { PuffLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { ProfileReservationsPageTabs } from "./ProfileReservations.interfaces";
import EmptyMatchListPlaceholder from '@/components/EmptyMatchListPlaceholder/EmptyMatchListPlaceholder';

const tabs: Array<ProfileReservationsPageTabs> = ['planned', 'expired'];

const ProfileReservationsPage = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<ProfileReservationsPageTabs>('planned');
  const [reservations, setReservations] = useState<Array<ReservationInfo>>();
  const [loading, setLoading] = useState(false);
  
  const renderReservations = useMemo(() => {
    if(!reservations || !reservations.length) {
      return (
        <EmptyMatchListPlaceholder
          className={styles['placeholder']}
          action={activeTab === 'planned' ? 'emptyActiveReservations' : 'emptyExpiredReservations'}
          title={activeTab === 'planned' ? 
            'Здесь будет отображаться список ваших текущих бронирований' :
            "Здесь будет отображаться список ваших прошедших бронирований"
          }
          bgImage
        />
      )
    }
    if(activeTab === 'planned') {
      const reservationsByDate = getMatchesByDate(reservations);
      return reservationsByDate.map(([date, entities]) => {
        return entities.length ?
          <DateWrapper key={date} date={new Date(date)}>
            {entities.map(entity => (
              <ActiveReservationCard
                key={entity.uid}
                type="list"
                reservation={entity}
                onClick={() => {
                  const jsonReservation = JSON.stringify({
                    uid: entity.uid,
                    //@ts-ignore
                    ids: entity.ids,
                    expired: false,
                  })
                  sessionStorage.setItem('currentReservation', jsonReservation);
                  navigate(`reservation`);
                }}
                expired={false}
              />
            ))}
          </DateWrapper> : null
      });
    } else {
      return (
        <div className={styles['expired-reservations']}>
          {reservations.map(reservation => (
            <ActiveReservationCard
              type="list"
              key={reservation.uid}
              reservation={reservation}
              onClick={() => {
                const jsonReservation = JSON.stringify({
                  uid: reservation.uid,
                  ids: reservation.ids,
                  expired: true
                })
                sessionStorage.setItem('currentReservation', jsonReservation)
                navigate(`reservation`);
              }}
              expired={true}
            />
          ))}
        </div>
      );
    }
  }, [reservations, activeTab]);

  useEffect(() => {
    setLoading(true);
    reservationLoadList({ active: activeTab === 'planned' })
      .then(({ result }) => {
          setReservations(result?.infos);
      })
      .catch(err => ErrorUtils.handleErrorMessage(err))
      .finally(() => setLoading(false));
  }, [activeTab]);

  return (  
    <FullScreenPage
      header={
        <>        
          <Header>Мои бронирования</Header>
          <UnderlineTabs<ProfileReservationsPageTabs>
            tabs={tabs}
            activeTab={activeTab}
            name="reservations"
            selectTab={e => setActiveTab(e.target.value as ProfileReservationsPageTabs)}
          />
        </>
      }
      footer={
        <>
          <HLine calssName={styles['hline']}/>
          <NavBar/>
        </>
      }
      className={styles['page']}
    >
      {loading ? 
        <PuffLoader color='#4EB857' cssOverride={{margin: 'auto'}}/> :
        <>
          {renderReservations}
        </>
      }
    </FullScreenPage>
  );
}
 
export default ProfileReservationsPage;
