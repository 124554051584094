import { GameCreateSuggestParameters } from "@/integration-api/server-rest-lundapadelApi";
import RestClient from "@/integration/RestClient";

export function gameCreateSuggestLoadList() {
  return RestClient.gameApi.gameCreateSuggestLoadList()
}

export function gameCreateSuggest(parameters: GameCreateSuggestParameters) {
  return RestClient.gameApi.gameCreateSuggest({
    gameCreateSuggestRequest: {
      parameters
    }
  })
}
