/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Gender,
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
} from './Gender';
import {
    Grade,
    GradeFromJSON,
    GradeFromJSONTyped,
    GradeToJSON,
} from './Grade';
import {
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';
import {
    ViewObjectIdentity,
    ViewObjectIdentityFromJSON,
    ViewObjectIdentityFromJSONTyped,
    ViewObjectIdentityToJSON,
} from './ViewObjectIdentity';

/**
 * 
 * @export
 * @interface CurrentPlayerProfileView
 */
export interface CurrentPlayerProfileView {
    /**
     * 
     * @type {ViewObjectIdentity}
     * @memberof CurrentPlayerProfileView
     */
    identity?: ViewObjectIdentity;
    /**
     * 
     * @type {number}
     * @memberof CurrentPlayerProfileView
     */
    playerId?: number;
    /**
     * 
     * @type {string}
     * @memberof CurrentPlayerProfileView
     */
    avatarUid?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentPlayerProfileView
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentPlayerProfileView
     */
    displayRating?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentPlayerProfileView
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentPlayerProfileView
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof CurrentPlayerProfileView
     */
    rankingPlace?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CurrentPlayerProfileView
     */
    nameInitials?: string;
    /**
     * 
     * @type {Grade}
     * @memberof CurrentPlayerProfileView
     */
    grade?: Grade;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentPlayerProfileView
     */
    gradeVerified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CurrentPlayerProfileView
     */
    displayGrade?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentPlayerProfileView
     */
    leftSide?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentPlayerProfileView
     */
    rightSide?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CurrentPlayerProfileView
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentPlayerProfileView
     */
    countryCode?: string;
    /**
     * 
     * @type {Gender}
     * @memberof CurrentPlayerProfileView
     */
    gender?: Gender;
    /**
     * 
     * @type {Array<Role>}
     * @memberof CurrentPlayerProfileView
     */
    roles?: Array<Role>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CurrentPlayerProfileView
     */
    grades?: { [key: string]: string; };
}

export function CurrentPlayerProfileViewFromJSON(json: any): CurrentPlayerProfileView {
    return CurrentPlayerProfileViewFromJSONTyped(json, false);
}

export function CurrentPlayerProfileViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentPlayerProfileView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identity': !exists(json, 'identity') ? undefined : ViewObjectIdentityFromJSON(json['identity']),
        'playerId': !exists(json, 'playerId') ? undefined : json['playerId'],
        'avatarUid': !exists(json, 'avatarUid') ? undefined : json['avatarUid'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'displayRating': !exists(json, 'displayRating') ? undefined : json['displayRating'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'rankingPlace': !exists(json, 'rankingPlace') ? undefined : json['rankingPlace'],
        'nameInitials': !exists(json, 'nameInitials') ? undefined : json['nameInitials'],
        'grade': !exists(json, 'grade') ? undefined : GradeFromJSON(json['grade']),
        'gradeVerified': !exists(json, 'gradeVerified') ? undefined : json['gradeVerified'],
        'displayGrade': !exists(json, 'displayGrade') ? undefined : json['displayGrade'],
        'leftSide': !exists(json, 'leftSide') ? undefined : json['leftSide'],
        'rightSide': !exists(json, 'rightSide') ? undefined : json['rightSide'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'gender': !exists(json, 'gender') ? undefined : GenderFromJSON(json['gender']),
        'roles': !exists(json, 'roles') ? undefined : ((json['roles'] as Array<any>).map(RoleFromJSON)),
        'grades': !exists(json, 'grades') ? undefined : json['grades'],
    };
}

export function CurrentPlayerProfileViewToJSON(value?: CurrentPlayerProfileView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identity': ViewObjectIdentityToJSON(value.identity),
        'playerId': value.playerId,
        'avatarUid': value.avatarUid,
        'displayName': value.displayName,
        'displayRating': value.displayRating,
        'city': value.city,
        'email': value.email,
        'rankingPlace': value.rankingPlace,
        'nameInitials': value.nameInitials,
        'grade': GradeToJSON(value.grade),
        'gradeVerified': value.gradeVerified,
        'displayGrade': value.displayGrade,
        'leftSide': value.leftSide,
        'rightSide': value.rightSide,
        'phone': value.phone,
        'countryCode': value.countryCode,
        'gender': GenderToJSON(value.gender),
        'roles': value.roles === undefined ? undefined : ((value.roles as Array<any>).map(RoleToJSON)),
        'grades': value.grades,
    };
}

