import styles from './OwnershipChangeRequestsCard.module.css';
import { HTMLAttributes } from 'react';
import { OwnerChangeRequestView, RelatedEntityType } from '@/integration-api/server-rest-lundapadelApi';
import Button, { ButtonVariants } from '@/components/Button/Button';
import { HandleOwnershipChangeArgs, TourgameRelatedEntityType } from '../OwnershipChangeRequestsSection/OwnershipChangeRequestsSection.interfaces';
import { parseRelatedEntityToJSX } from './utils';
import ClockIcon from '@/static/images/icons/ClockIcon';
import formatter from '@/utils/utils';
import { calculateEndTime } from '@/pages/application/create/components/MatchResultSettings/utils';
import { TIME_FORMAT_HH_MM } from '@/utils/constants';
import LocationIcon from '@/static/images/icons/LocationIcon';

type OwnershipChangeRequestsCardProps = {
  request: OwnerChangeRequestView;
  handleAction: (args: HandleOwnershipChangeArgs) => Promise<void>;
} & HTMLAttributes<HTMLDivElement>

const OwnershipChangeRequestsCard = ({ request, handleAction, onClick, ...props }: OwnershipChangeRequestsCardProps) => {
  return (  
    <div className={styles['wrapper']} {...props}>
      <div onClick={onClick}>
        <div className={styles['headling']}>
          <span>Вас просят стать организатором</span>
          {parseRelatedEntityToJSX(request.relatedEntityType)}
        </div>
        <div className={styles['info']}>
          <div className={styles['info-field']}>
            <div className={styles['icon']}>
              <ClockIcon fill="var(--green-lunda)"/>
            </div>
            <span>
              {formatter.formatDateToDayDateMonth(request.plannedDate)} | {formatter.formatDate(request.plannedDate!, TIME_FORMAT_HH_MM)} 
              {request.relatedEntityType === RelatedEntityType.GAME ?
                <>
                  {calculateEndTime(formatter.formatDate(request.plannedDate!, TIME_FORMAT_HH_MM), request.duration!)}
                </> : null
              }
            </span>
          </div>
          <div className={styles['info-field']}>
            <div className={styles['icon']}>
              <LocationIcon fill="var(--green-lunda)"/>
            </div>
            <span>{request.clubName}</span>
          </div>
        </div>
      </div>
      <div className={styles['actions']}>
        <Button
          className={styles['button']}
          variant={ButtonVariants.PRIMARY}
          onClick={() => handleAction({ 
            accepted: true, 
            entityType: request.relatedEntityType as TourgameRelatedEntityType, 
            relatedEntityUid: request.relatedEntityUid 
          })}
        >
           Принять
        </Button>
        <Button 
          className={styles['button']}
          variant={ButtonVariants.GRAYBLUE_OUTLINED}
          onClick={() => handleAction({ 
            accepted: false, 
            entityType: request.relatedEntityType as TourgameRelatedEntityType, 
            relatedEntityUid: request.relatedEntityUid 
          })}
        >
          Отклонить
        </Button>
      </div>
    </div>
  );
}
 
export default OwnershipChangeRequestsCard;
