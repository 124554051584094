import { CreatePaymentParam, GameDateBookedParam, GameTimeBookedParam, GameView, SaveStatusPaymentParam, TournamentView, CreateTournamentInviteListParam, AvailableBookingTimeLoadListParameters, AvailableCourtsLoadListParameters, BookingParameters, PaymentAmountCalculateParameters } from "@/integration-api/server-rest-lundapadelApi";
import RestClient from "@/integration/RestClient";

export function saveTournament(parameters: TournamentView) {
  return RestClient.tournamentApi.saveTournament({
    saveTournamentRequest: {
      parameters
    }
  })
}

export function gameSave(parameters: GameView) {
  return RestClient.gameApi.gameSave({
    gameSaveRequest: {
      parameters
    }
  })
}

export function gameBookedListTime(parameters: GameDateBookedParam) {
  return RestClient.gameApi.gameBookedListTime({
    gameBookedListTimeRequest: {
      parameters
    }
  });
}

export function gameTimeBooked(parameters: GameTimeBookedParam) {
  return RestClient.gameApi.gameTimeBooked({
    gameTimeBookedRequest: {
      parameters
    }
  });
}

export function getEnableStatusYookassa() {
  return RestClient.yookassaApi.getEnableStatusYookassa()
}

export function createYookassaPayment(parameters: CreatePaymentParam) {
  return RestClient.yookassaApi.createYookassaPayment({
    createYookassaPaymentRequest: {
      parameters
    }
  })
}

export function saveStatusYookassaPayment(parameters: SaveStatusPaymentParam) {
  return RestClient.yookassaApi.saveStatusYookassaPayment({
    saveStatusYookassaPaymentRequest: {
      parameters
    }
  });
}

export function createInviteListForTournament(parameters: CreateTournamentInviteListParam) {
  return RestClient.tournamentApi.createInviteListForTournament({
    createInviteListForTournamentRequest: {
      parameters
    }
  })
}

export function availableSlotsLoadList(parameters:  AvailableBookingTimeLoadListParameters) {
  return RestClient.bookingApi.availableSlotsLoadList({
    availableSlotsLoadListRequest: {
      parameters
    }
  })
}

export function availableCourtsLoadList(parameters: AvailableCourtsLoadListParameters) {
  return RestClient.bookingApi.availableCourtsLoadList({
    availableCourtsLoadListRequest: {
      parameters
    }
  })
}

export function bookingCreate(parameters: BookingParameters) {
  return RestClient.bookingApi.bookingCreate({
    bookingCreateRequest: {
      parameters
    }
  })
}

export function paymentAmountCalculate(parameters: PaymentAmountCalculateParameters) {
  return RestClient.bookingApi.paymentAmountCalculate({
    paymentAmountCalculateRequest: {
      parameters
    }
  })
}

export function bookingAvailableCheck() {
  return RestClient.bookingApi.bookingAvailableCheck();
}

export function createGame(parameters: GameView) {
  return RestClient.gameApi.createGame({
    createGameRequest: {
      parameters
    }
  })
}

export function updateGame(parameters: GameView) {
  return RestClient.gameApi.updateGame({
    updateGameRequest: {
      parameters
    }
  })
}

export function createTournament(parameters: TournamentView) {
  return RestClient.tournamentApi.createTournament({
    createTournamentRequest: {
      parameters
    }
  });
}

export function updateTournament(parameters: TournamentView) {
  return RestClient.tournamentApi.updateTournament({
    updateTournamentRequest: {
      parameters
    }
  })
}
