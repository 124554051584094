import Icon, { IconProps } from "../Icon";

interface PaidReservationProps extends IconProps {} 

const PaidReservation = ({ ...props }: PaidReservationProps) => {
  return (  
    <Icon {...props}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.75 6H2.25V13.5H15.75V6H14.625M11.625 5.25C11.9185 5.41973 12.1699 5.6536 12.3603 5.93414C12.5507 6.21467 12.6753 6.53462 12.7247 6.87007C12.7741 7.20551 12.747 7.54778 12.6455 7.8713C12.544 8.19482 12.3707 8.49121 12.1386 8.73834C11.9064 8.98546 11.6214 9.17692 11.3049 9.29841C10.9883 9.4199 10.6484 9.46828 10.3106 9.43992C9.97268 9.41157 9.64558 9.30723 9.3537 9.13468C9.06183 8.96214 8.81272 8.72586 8.625 8.4435M9.75 4.5C9.75 5.09674 9.51295 5.66903 9.09099 6.09099C8.66903 6.51295 8.09674 6.75 7.5 6.75C6.90326 6.75 6.33097 6.51295 5.90901 6.09099C5.48705 5.66903 5.25 5.09674 5.25 4.5C5.25 3.90326 5.48705 3.33097 5.90901 2.90901C6.33097 2.48705 6.90326 2.25 7.5 2.25C8.09674 2.25 8.66903 2.48705 9.09099 2.90901C9.51295 3.33097 9.75 3.90326 9.75 4.5Z" stroke="#C3D82E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </Icon>
  );
}
 
export default PaidReservation;
