import { FC, InputHTMLAttributes } from 'react';
import styles from './ToggleSwitch.module.css';

export interface ToggleSwitchProps
  extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  checkedColor?: string;
}
const ToggleSwitch: FC<ToggleSwitchProps> = ({
  id,
  checkedColor,
  ...inputProps
}) => {
  const addLabelStyle = !!checkedColor && inputProps.checked;
  return (
    <span className={styles.wrap}>
      <input type="checkbox" id={id} {...inputProps} className={styles.input} />
      <label
        htmlFor={id}
        className={styles.label}
        style={addLabelStyle ? { backgroundColor: checkedColor } : {}}
      />
    </span>
  );
};

export default ToggleSwitch;
