import { FetchErrorMessage } from '@/types/types';
import { PopupMessage } from '../PopupMessage/PopupMessage';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Grade,
  InvitationStatus,
  PlayerProfileField,
  PlayerProfileInfo,
} from '@/integration-api/server-rest-lundapadelApi';
import { FetchPlayersListParams } from './SearchRecent.interfaces';
import useInfiniteScrol from '@/hooks/useInfiniteScroll';
import { playerLoadList } from '@/pages/application/game/service';
import { EntityView } from '@/pages/application/game/GameInvitaionPage/GameInvitaionPage';
import useDebounce from '@/hooks/useDebounce';

const DISPLAYED_LIST_STEP = 100;

export default function useSearchRecent(
  fetchParams: FetchPlayersListParams,
  invitationsList: EntityView['invitations']
) {
  const [suggestions, setSuggestions] = useState<Array<PlayerProfileInfo>>([]);
  const [loading, setLoading] = useState(false);
  const [displayedCount, setDisplayedCount] = useState(DISPLAYED_LIST_STEP);
  const isInitialRender = useRef(true);

  const { listRef, isEndOfList, resetIsEndOfList, resetScroll } =
    useInfiniteScrol();

  const debouncedLoadPlayerList = useDebounce(loadPlayersList, 500);
  const debouncedScroolHandler = useDebounce(loadPlayersList, 100);

  useEffect(() => {
    if (isEndOfList) {
      debouncedScroolHandler(displayedCount);
      resetIsEndOfList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEndOfList]);

  const { range, onlyPartners, textPattern, excludedUids } = fetchParams;
  const [minRating, maxRating] = range;
  const stringifiedExcludeUids = excludedUids.toString();

  useEffect(() => {
    // При первом рендере грузим список без задержки, при изменении фильтров - задержка 500 мс
    if (isInitialRender.current) {
      loadPlayersList(DISPLAYED_LIST_STEP);
      isInitialRender.current = false;
    } else {
      resetScroll();
      debouncedLoadPlayerList(DISPLAYED_LIST_STEP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stringifiedExcludeUids,
    fetchParams.onlyPartners,
    fetchParams.textPattern,
    minRating,
    maxRating,
    invitationsList,
  ]);

  function loadPlayersList(offset: number) {
    setLoading(true);
    playerLoadList({
      lowerRatingFilter: Grade[`_${minRating}` as keyof typeof Grade],
      upperRatingFilter: Grade[`_${maxRating}` as keyof typeof Grade],
      textPattern,
      onlyPartners,
      sortBy: onlyPartners
        ? PlayerProfileField.COUNT_MATCHES_WITH_PARTNER
        : PlayerProfileField.RATING,
      matchContext: true,
      excludedUids,
      sortDesc: true,
      limit: offset,
    })
      .then(({ result }) => {
        setSuggestions(
          (invitationsList?.length
            ? result?.infos?.filter(filterInvitedPlayers)
            : result?.infos) ?? []
        );
        setDisplayedCount(offset + DISPLAYED_LIST_STEP);
      })
      .catch((err) => {
        if (err instanceof Promise) {
          err.then((err) => {
            const {
              userErrorMessage,
              errorMessage,
            }: Awaited<FetchErrorMessage> = err;
            PopupMessage.open(userErrorMessage ?? errorMessage);
          });
        }
      })
      .finally(() => setLoading(false));
  }

  const filterInvitedPlayers = useCallback(
    (player: PlayerProfileInfo) => {
      return !invitationsList?.find(
        (invite) =>
          invite.player?.uid === player.uid &&
          (invite.invitationStatus === InvitationStatus.SENT ||
            invite.invitationStatus === InvitationStatus.ACCEPTED)
      );
    },
    [invitationsList]
  );

  return { suggestions, listRef, loading };
}
