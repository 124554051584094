import styles from './UnderlineTabs.module.css';
import { ChangeEvent, HTMLAttributes, useMemo } from "react";
import { tabNameParser } from './utils';

type UnderlineTabsProps<T> = {
  tabs: Array<T>;
  activeTab: T;
  selectTab: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
} & HTMLAttributes<HTMLElement>;

const UnderlineTabs = <T extends string>({ tabs, activeTab, name, selectTab, className, ...props }: UnderlineTabsProps<T>) => {
  const renderTabs = useMemo(() => {
    return tabs.map((tab, i) => (
      <label className={styles['navbar-item']} key={i}>
        <input checked={activeTab === tab} type='radio' name={name} style={{ display: 'none' }} value={tab} onChange={selectTab}/>
        <span>{tabNameParser(tab)}</span>
      </label>
    ))
  }, [tabs, activeTab, name, selectTab]);

  return (  
    <nav {...props} className={`${className} ${styles['navbar']}`}>
      {renderTabs}
    </nav>
  );
}
 
export default UnderlineTabs;
