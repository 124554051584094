import styles from './PlayPageContent.module.css';
import { UIEvent, useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  AdvertisementPlaceType,
  GameSortParam,
  Grade,
  TourGameEntityType,
  TourGameLoadListResult,
  UIGameSettingsView,
} from '@/integration-api/server-rest-lundapadelApi';
import { loadListClubCities } from '../../profile/service';
import { FetchErrorMessage } from '@/types/types';
import {
  getUIGameSettings,
  loadTourGameList,
  saveUIGameSettings,
} from '../service';
import useDebounce from '@/hooks/useDebounce';
import CreateMatchBtn from '../../match/components/CreateMatchBtn/CreateMatchBtn';
import FullScreenModal from '@/components/FullScreenModal/FullScreenModal';
import RadioButton from '@/components/RadioButton/RadioButton';
import AutoSuggest from '@/components/Autosuggest/Autosuggest';
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch';
import EmptyMatchListPlaceholder from '@/components/EmptyMatchListPlaceholder/EmptyMatchListPlaceholder';
import { PuffLoader } from 'react-spinners';
import InnerPadding from '@/components/InnerPadding/InnerPadding';
import Headling from '@/components/Headling/Headling';
import CountCircle from '@/components/CountCircle/CountCircle';
import { Link } from 'react-router-dom';
import GameListCard from '@/components/GameListCard/GameListCard';
import TournamentCard from '@/components/TournamentCard/TournamentCard';
import FilterOutlined from '@/static/images/icons/FilterOutlined';
import BallSmallIcon from '@/static/images/icons/BallSmallIcon';
import DBManager from '@/utils/DBManager/DBManager';
import SelectRating from '../../create/components/SelectRating/SelectRating';
import FullscreenLoader from '@/components/FullscreenLoader/FullscreenLoader';
import { useAdsSrcLink } from '@/components/AdsBanner/hooks';
import AdsBanner from '@/components/AdsBanner/AdsBanner';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';
import { useAppSelector } from '@/hooks/hooks';
import { getPlatform } from '@/utils/utils';

type ModifiedUIGameSettingsView = Omit<
  UIGameSettingsView,
  'minGrade' | 'maxGrade'
> & {
  range: [number, number];
};

const tgIdPrefix = 'tg-id-';

const PlayPageContent = () => {
  const adsBanner = useAdsSrcLink(AdvertisementPlaceType.PLAY_PAGE);
  const { grades } = useAppSelector((state) => state.auth.currentProfile);

  const [tourGamesInfo, setTourGamesInfo] = useState<TourGameLoadListResult>();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [offset, setOffset] = useState(0);
  const [cities, setCities] = useState<Array<string>>([]);
  const [readyToScroll, setReadyToScroll] = useState(false);
  const [fullscreenLoading, setFullscreenLoading] = useState(false);

  const formik = useFormik<ModifiedUIGameSettingsView>({
    initialValues: {
      onlyMyInvite: false,
      onlyMyPartners: false,
      onlyMyRating: false,
      onlyTournament: false,
      showAll: false,
      onlyFavoriteClubs: false,
      city: '',
      range: [1, 1],
      gameSortBy: GameSortParam.PLANNED_DATE,
    },
    onSubmit: handleSubmitForm,
  });

  async function handleSubmitForm(values: ModifiedUIGameSettingsView) {
    try {
      setLoading(true);
      const uiValues: UIGameSettingsView = {
        ...values,
        minGrade: `GRADE_${values.range[0]}` as Grade,
        maxGrade: `GRADE_${values.range[1]}` as Grade,
        metrics: {
          system: getPlatform(),
        },
      };
      await saveUIGameSettings(uiValues);
      const { result } = await loadTourGameList(uiValues);
      setOffset(20);
      setTourGamesInfo(result);
      setModalVisible(false);
    } catch (err) {
      if (err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> =
          await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    } finally {
      setLoading(false);
    }
  }

  const renderTourGames = () => {
    return tourGamesInfo?.infos?.map((tg, index) => {
      let card = <></>;
      if (tg.type === TourGameEntityType.GAME) {
        card = <GameListCard key={tg.uid} game={tg} id={tgIdPrefix + tg.uid} />;
      } else {
        card = (
          <Link to={`/tournament/${tg.uid}`} key={tg.uid}>
            <TournamentCard
              tournament={tg}
              restricted
              id={tgIdPrefix + tg.uid}
            />
          </Link>
        );
      }

      // показывать рекламу после 2-й карточки и потом через каждые 10
      const showBanner =
        adsBanner &&
        (index === 2 || (adsBanner?.visible && (index - 2) % 10 === 0));

      return (
        <>
          {showBanner && (
            <AdsBanner
              type="play"
              bannerSrc={adsBanner.src}
              redirectLink={adsBanner.link}
              index={(index - 2) / 10}
            />
          )}
          {card}
        </>
      );
    });
  };

  const resetFilters = useCallback(() => {
    const newSp: ModifiedUIGameSettingsView = {
      onlyMyInvite: false,
      onlyMyPartners: false,
      onlyMyRating: false,
      onlyTournament: false,
      showAll: false,
      onlyFavoriteClubs: false,
      city: '',
      range: [1, Object.values(grades ?? {}).length],
      gameSortBy: GameSortParam.PLANNED_DATE,
    };
    setLoading(true);
    saveUIGameSettings({ ...formik.values, ...newSp })
      .then(() => {
        formik.setValues(newSp);
        return loadTourGameList({ ...formik.values, ...newSp });
      })
      .then(({ result }) => {
        setTourGamesInfo(result);
        setOffset(20);
      })
      .catch((err) => {
        if (err instanceof Promise) {
          err.then((err) => {
            const {
              userErrorMessage,
              errorMessage,
            }: Awaited<FetchErrorMessage> = err;
            PopupMessage.open(userErrorMessage ?? errorMessage);
          });
        }
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, grades]);

  const scrollHandler = (e: UIEvent) => {
    if (
      Math.floor(
        (e.target as HTMLDivElement).scrollTop +
          window.innerHeight -
          (window.innerHeight - (e.target as HTMLDivElement).clientHeight) +
          80
      ) >= (e.target as HTMLDivElement).scrollHeight &&
      offset < (tourGamesInfo?.total ?? 0)
    ) {
      setFullscreenLoading(true);
      loadTourGameList({
        ...formik.values,
        minGrade: `GRADE_${formik.values.range[0]}` as Grade,
        maxGrade: `GRADE_${formik.values.range[1]}` as Grade,
        offset,
      })
        .then(({ result }) => {
          if (result) {
            setOffset((state) => state + 20);
            setTourGamesInfo((state) => ({
              total: result.total,
              infos: state?.infos
                ? result.infos
                  ? [...state.infos, ...result.infos]
                  : [...state.infos]
                : [],
            }));
          }
        })
        .catch((err) => {
          if (err instanceof Promise) {
            err.then((err) => {
              const {
                userErrorMessage,
                errorMessage,
              }: Awaited<FetchErrorMessage> = err;
              PopupMessage.open(userErrorMessage ?? errorMessage);
            });
          }
        })
        .finally(() => setFullscreenLoading(false));
    }
  };

  useEffect(() => {
    if (navigator.onLine) {
      setLoading(true);
      Promise.all([loadListClubCities(), getUIGameSettings()])
        .then(
          ([
            { result: clubCitiesResult },
            { result: uiGameSettingsResult },
          ]) => {
            const range: [number, number] = [
              Number(uiGameSettingsResult?.minGrade?.replace(/\D/g, '')),
              Number(uiGameSettingsResult?.maxGrade?.replace(/\D/g, '')),
            ];
            formik.setValues({ ...uiGameSettingsResult, range });
            setCities(clubCitiesResult?.cities ?? []);
            return loadTourGameList(uiGameSettingsResult ?? {});
          }
        )
        .then(({ result }) => {
          setTourGamesInfo(result);
          setReadyToScroll(true);
          setOffset(20);
          DBManager.create('tourgames', result, 'newTourgames');
        })
        .catch((err) => {
          if (err instanceof Promise) {
            err.then((err) => {
              const {
                userErrorMessage,
                errorMessage,
              }: Awaited<FetchErrorMessage> = err;
              PopupMessage.open(userErrorMessage ?? errorMessage);
            });
          }
        })
        .finally(() => setLoading(false));
    } else {
      DBManager.read('tourgames', 'newTourgames')
        .then((newTourgames) => {
          setTourGamesInfo(newTourgames);
          setReadyToScroll(true);
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedHandler = useDebounce(scrollHandler, 200);

  useEffect(() => {
    let ignore = false;

    // возврат к игре/турниру после просмотра
    function scrollToGameCard() {
      const lastPaths = JSON.parse(
        sessionStorage.getItem('lastPaths') || '[]'
      ) as string[];
      if (
        lastPaths[0].includes('game') ||
        lastPaths[0].includes('tournament')
      ) {
        const tgUid = lastPaths[0].split('/')[2];

        const tgElement = document.querySelector(`#${tgIdPrefix}${tgUid}`);

        if (tgUid?.length !== 36) return; // отсекает возврат из страницы создания матча

        if (tgElement) {
          tgElement?.scrollIntoView();
          setReadyToScroll(false);
        } else {
          function getMoreTG(
            offset: number,
            tourGamesInfo?: TourGameLoadListResult['infos']
          ) {
            loadTourGameList({
              ...formik.values,
              offset,
            }).then(({ result }) => {
              if (!ignore) {
                // для игнора старых результатов
                const tgIndex = result?.infos?.findIndex(
                  (tourgame) => tourgame.uid === tgUid
                )!;
                const newTourgamesInfo = [
                  ...(tourGamesInfo || []),
                  ...(result?.infos || []),
                ];
                const nextOffset = offset + 20;

                if (tgIndex !== -1) {
                  // если в загруженных матчах нашелся тот, к которому нужно скроллить
                  setOffset(newTourgamesInfo.length);
                  setTourGamesInfo({
                    infos: newTourgamesInfo,
                    total: result?.total,
                  });
                } else if (result?.total! > nextOffset) {
                  // если есть что еще загружать
                  getMoreTG(nextOffset, newTourgamesInfo);
                }
              }
            });
          }
          getMoreTG(offset, tourGamesInfo?.infos);
        }
      }
    }

    if (readyToScroll) scrollToGameCard();
    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourGamesInfo]);

  return (
    <>
      <section className={styles['wrapper']} onScroll={debouncedHandler}>
        <CreateMatchBtn />
        <InnerPadding className={styles['padding']}>
          <div className={styles['toggle']}>
            <span>Игры в любимых клубах</span>
            <ToggleSwitch
              disabled={!navigator.onLine}
              id="onlyFavoriteClubsMain"
              name="onlyFavoriteClubs"
              onChange={(e) => {
                handleSubmitForm({
                  ...formik.values,
                  onlyFavoriteClubs: e.target.checked,
                });
                formik.setFieldValue('onlyFavoriteClubs', e.target.checked);
              }}
              checked={formik.values.onlyFavoriteClubs}
            />
          </div>
        </InnerPadding>
        <InnerPadding className={styles['headling']}>
          <div className={styles['info']}>
            <Headling appearence="big-normal" hasExpansion>
              Ищут игроков
            </Headling>
            {tourGamesInfo?.total ? (
              <CountCircle>{tourGamesInfo?.total}</CountCircle>
            ) : null}
          </div>
          <div
            style={{ position: 'relative' }}
            onClick={() => navigator.onLine && setModalVisible(true)}
          >
            {formik.values.onlyFavoriteClubs ||
            formik.values.onlyMyInvite ||
            formik.values.onlyMyPartners ||
            formik.values.onlyMyRating ||
            formik.values.onlyTournament ||
            formik.values.showAll ||
            formik.values.city ||
            formik.values.range[0] !== 1 ||
            formik.values.range[1] !== Object.keys(grades ?? {}).length ? (
              <div className={styles['filters-selected']}>
                <BallSmallIcon />
              </div>
            ) : null}
            <div>
              <FilterOutlined />
            </div>
          </div>
        </InnerPadding>
        {loading ? (
          <PuffLoader color="#4EB857" cssOverride={{ margin: 'auto' }} />
        ) : (
          <>
            {tourGamesInfo?.total ? (
              <div className={styles['tourgames-wrapper']}>
                {renderTourGames()}
              </div>
            ) : !formik.values.onlyFavoriteClubs &&
              (formik.values.onlyMyInvite ||
                formik.values.onlyMyPartners ||
                formik.values.onlyMyRating ||
                formik.values.onlyTournament ||
                formik.values.showAll ||
                formik.values.city ||
                formik.values.range[0] !== 1 ||
                formik.values.range[1] !== Object.keys(grades ?? {}).length) ? (
              <EmptyMatchListPlaceholder
                className={styles['empty-my-games']}
                bgImage
                title="Игры по выбранным фильтрам не найдены"
                action="wipeFiltersOrNewGame"
                clearFilters={resetFilters}
              />
            ) : formik.values.onlyFavoriteClubs ? (
              <EmptyMatchListPlaceholder
                className={styles['empty-my-games']}
                bgImage
                action="selectFavoriteClubs"
                title="Здесь будет отображаться список игр в ваших любимых клубах"
              />
            ) : (
              <EmptyMatchListPlaceholder
                className={styles['empty-my-games']}
                bgImage
                action="newGame"
                title="Здесь будет отображаться список всех игр, к которым вы cможете присоединиться"
              />
            )}
          </>
        )}
      </section>
      <FullScreenModal
        visible={modalVisible}
        onDone={formik.handleSubmit}
        title="Фильтр"
      >
        <form onSubmit={formik.handleSubmit}>
          <label className={styles['checkbox-wrapper']}>
            <span className={styles['checkbox-label']}>
              Показать игры с моим рейтингом
            </span>
            <ToggleSwitch
              id="onlyMyRating"
              name="onlyMyRating"
              onChange={formik.handleChange}
              checked={formik.values.onlyMyRating}
            />
          </label>

          <label className={styles['checkbox-wrapper']}>
            <span className={styles['checkbox-label']}>
              Показать игры с моими напарниками
            </span>
            <ToggleSwitch
              id="onlyMyPartners"
              name="onlyMyPartners"
              onChange={formik.handleChange}
              checked={formik.values.onlyMyPartners}
            />
          </label>

          <label className={styles['checkbox-wrapper']}>
            <span className={styles['checkbox-label']}>
              Игры с моими заявками
            </span>
            <ToggleSwitch
              id="onlyMyInvite"
              name="onlyMyInvite"
              onChange={formik.handleChange}
              checked={formik.values.onlyMyInvite}
            />
          </label>

          <label className={styles['checkbox-wrapper']}>
            <span className={styles['checkbox-label']}>
              Показать заполненные игры
            </span>
            <ToggleSwitch
              id="showAll"
              name="showAll"
              onChange={formik.handleChange}
              checked={formik.values.showAll}
            />
          </label>

          <label className={styles['checkbox-wrapper']}>
            <span className={styles['checkbox-label']}>
              Показать только турниры
            </span>
            <ToggleSwitch
              id="onlyTournament"
              name="onlyTournament"
              onChange={formik.handleChange}
              checked={formik.values.onlyTournament}
            />
          </label>

          <label className={styles['checkbox-wrapper']}>
            <span className={styles['checkbox-label']}>
              Игры в любимых клубах
            </span>
            <ToggleSwitch
              id="onlyFavoriteClubs"
              name="onlyFavoriteClubs"
              onChange={formik.handleChange}
              checked={formik.values.onlyFavoriteClubs}
            />
          </label>
          <SelectRating
            title="Рейтинг"
            value={formik.values.range}
            filter
            onChange={(values) =>
              formik.setFieldValue('range', [values[0], values[1]])
            }
          />
          <div className={styles['city']}>
            <div className={styles['city-title']}>Фильтрация игр по городу</div>
            <AutoSuggest
              values={cities}
              defaultSuggestValue={String(formik.values.city ?? '')}
              onInputChange={(value) => formik.setFieldValue('city', value)}
              placeholder="Все города"
            />
          </div>
          <div className={styles['radio']}>
            <h3>Сортировка</h3>
            <RadioButton
              name="gameSortBy"
              value={GameSortParam.PLANNED_DATE}
              label="По времени"
              onChange={formik.handleChange}
              checked={formik.values.gameSortBy === GameSortParam.PLANNED_DATE}
            />

            <RadioButton
              name="gameSortBy"
              value={GameSortParam.RATING}
              label="По рейтингу"
              onChange={formik.handleChange}
              checked={formik.values.gameSortBy === GameSortParam.RATING}
            />
          </div>
        </form>
      </FullScreenModal>
      <FullscreenLoader loading={fullscreenLoading} />
    </>
  );
};

export default PlayPageContent;
