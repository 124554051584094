/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AcceptChangeOwnerParam
 */
export interface AcceptChangeOwnerParam {
    /**
     * 
     * @type {string}
     * @memberof AcceptChangeOwnerParam
     */
    relatedEntityUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AcceptChangeOwnerParam
     */
    accepted?: boolean;
}

export function AcceptChangeOwnerParamFromJSON(json: any): AcceptChangeOwnerParam {
    return AcceptChangeOwnerParamFromJSONTyped(json, false);
}

export function AcceptChangeOwnerParamFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcceptChangeOwnerParam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'relatedEntityUid': !exists(json, 'relatedEntityUid') ? undefined : json['relatedEntityUid'],
        'accepted': !exists(json, 'accepted') ? undefined : json['accepted'],
    };
}

export function AcceptChangeOwnerParamToJSON(value?: AcceptChangeOwnerParam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'relatedEntityUid': value.relatedEntityUid,
        'accepted': value.accepted,
    };
}

