import BasePageWrapper from "@/components/BasePageWrapper/BasePageWrapper";
import { Outlet } from "react-router-dom";
import { YMaps } from "@pbe/react-yandex-maps";

const CreatePage = () => {

  return ( 
    <BasePageWrapper>
      <YMaps>
        <Outlet/>
      </YMaps>
    </BasePageWrapper>
  );
}
 
export default CreatePage;
