import Header from '@/components/Header/Header';
import styles from './GameCreatePage.module.css';
import { useAppSelector } from '@/hooks/hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ClubSelectionPage from '../ClubSelectionPage/ClubSelectionPage';
import { FormikErrors, useFormik } from 'formik';
import {
  BookingModalState,
  GameFormValues,
  PaymentData,
} from './GameCreatePage.interfaces';
import {
  AvailableSlot,
  AvailableSlotsLoadListResponse,
  ClubView,
  GameRole,
  InvitationStatus,
  LocalDateTime,
  Role,
  toLocalDate,
  toLocalDateTime,
  ReservationStatus,
  AvailableCourt,
} from '@/integration-api/server-rest-lundapadelApi';
import { loadClubs } from '../../services';
import HLine from '@/components/HLine/HLine';
import SelectDate from '../components/SelectDate/SelectDate';
import SelectTime from '../components/SelectTime/SelectTime';
import SelectClubSection from '../components/SelectClubSection/SelectClubSection';
import SelectRating from '../components/SelectRating/SelectRating';
import MatchResultSettings from '../components/MatchResultSettings/MatchResultSettings';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Button from '@/components/Button/Button';
import MatchDetails from '../components/MatchDetails/MatchDetails';
import MatchDurationRadio from '../components/MatchDurationRadio/MatchDurationRadio';
import { getPlannedDate, handleGame } from '../utils';
import {
  availableCourtsLoadList,
  availableSlotsLoadList,
  bookingAvailableCheck,
  gameBookedListTime,
  gameTimeBooked,
} from '../service';
import TimeWarningModal from '../components/TimeWarningModal/TimeWarningModal';
import GamePlayersFormItem from '../components/GamePlayersFormItem/GamePlayersFormItem';
import MatchSearchPage from '../../match/MatchSearchPage/MatchSearchPage';
import { gameInitialValues, loopThourghErrors } from '../utils';
import { gameLoad } from '../../game/service';
import CreateTabs from '../components/CreateTabs/CreateTabs';
import dayjs from 'dayjs';
import { PuffLoader } from 'react-spinners';
import CrossIcon from '@/static/images/icons/CrossIcon';
import BookingPaymentPage from '../BookingPaymentPage/BookingPaymentPage';
import SelectCourtForBooking from '../components/SelectCourtForBooking/SelectCourtForBooking';
import { useImmer } from 'use-immer';
import BookingStatusModal from '../components/BookingStatusModal/BookingStatusModal';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';
import { ErrorUtils } from '@/utils/utils';

let isGameWithBooking = false;
let initialLoad = true;

const letSelectCourt: AvailableCourt = {
  court: {
    uid: '',
    type: '',
    caption: 'Выберите корт',
  },
  number: NaN,
  description: '',
  imageUid: '',
  price: NaN,
};

const GameCreatePage = () => {
  const current = useAppSelector((state) => state.auth.currentProfile);
  const navigate = useNavigate();
  const { id } = useParams();

  const [paymentPageVisible, setPaymentPageVisible] = useState(false);
  const [searchPageVisible, setSearchPageVisible] = useState(false);
  const [clubsPageVisible, setClubsPageVisisble] = useState(false);
  const [clubList, setClubList] = useState<Array<ClubView>>();
  const [errorField, setErrorField] = useState<string>();
  const [bookedTimes, setBookedTimes] = useState<Array<LocalDateTime>>([]);
  const [waringModal, setWarningModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [gameDate, setGameDate] = useState<LocalDateTime>();
  const [bookingAvailable, setBookingAvailable] = useState(false);
  const [availableSlots, setAvailableSlots] = useState<Array<AvailableSlot>>(
    []
  );
  const [availableCourts, setAvailableCourts] = useState<Array<string>>([]);

  const [paymentData, setPaymentData] = useImmer<PaymentData>({});

  const [bookingModalState, setBookingModalState] = useImmer<BookingModalState>(
    {
      visible: false,
      type: 'free',
    }
  );

  const clubRef = useRef<HTMLDivElement>(null);
  const timeRef = useRef<HTMLDivElement>(null);
  const kindRef = useRef<HTMLDivElement>(null);

  const formik = useFormik<GameFormValues>({
    initialValues: gameInitialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validate: (values) => {
      const errors: FormikErrors<GameFormValues> = {};
      if (!values.plannedDate.time) {
        scrollToError(timeRef);
        errors.plannedDate = {
          time: 'Выберите время!',
        };
        setErrorField('time');
      }
      if (!values.club) {
        scrollToError(clubRef);
        errors.club = 'Выберите клуб!';
        setErrorField('club');
      }
      if (values.playersLimit === 8 && !values.gameTournamentKind) {
        scrollToError(kindRef);
        errors.gameTournamentKind = 'Выберите тип турнира!';
        setErrorField('kind');
      }
      if (
        bookingAvailable &&
        !isGameWithBooking &&
        values.club?.yClientsIntegrated &&
        values.plannedDate.time &&
        values.courtBooked &&
        !values.selectedCourts.length &&
        !isCourtsSelected()
      ) {
        errors.courtBooked = 'Выберите корт!';
      }
      loopThourghErrors(errors);
      return errors;
    },
    onSubmit: handleSubmitForm,
  });

  function isCourtsSelected() {
    for (const court of formik.values.selectedCourts) {
      let price: number | null;
      try {
        price = (JSON.parse(court) as AvailableCourt).price ?? null;
      } catch {
        return false;
      }
      if (price === null) return false;
    }
    return true;
  }

  async function submitForm(values: GameFormValues) {
    try {
      const gameUid = await handleGame({
        current,
        type: id ? 'edit' : 'create',
        values,
      });
      if (!paymentPageVisible) {
        if (id) {
          navigate(-1);
        } else {
          navigate(`/game/${gameUid}/?afterCreation`);
        }
      }
    } catch (err) {
      ErrorUtils.handleErrorMessage(err);
    }
  }

  async function handleSubmitForm(values: GameFormValues) {
    if (!isCourtsSelected) return;
    try {
      const { result } = await gameTimeBooked({
        plannedDate: getPlannedDate({
          date: values.plannedDate.date,
          time: values.plannedDate.time,
        }),
      });
      if (result) {
        if (
          formik.values.courtBooked &&
          formik.values.club?.yClientsIntegrated &&
          bookingAvailable &&
          !isGameWithBooking
        ) {
          const { result: availabilityResult } = await bookingAvailableCheck();
          if (availabilityResult?.available) {
            if (result.booked || result.reserved) {
              setWarningModal(true);
            } else {
              setPaymentPageVisible(true);
              setErrorField(undefined);
            }
          } else {
            throw new Error('Функционал бронирования временно недоступен');
          }
        } else {
          if (result.booked) {
            if (
              id &&
              dayjs(gameDate).format('H:mm') ===
                formik.values.plannedDate?.time &&
              dayjs(gameDate).format('YYYY-MM-DD') ===
                dayjs(formik.values.plannedDate?.date).format('YYYY-MM-DD')
            ) {
              return submitForm(values);
            } else {
              setWarningModal(true);
            }
          } else {
            return submitForm(values);
          }
        }
      }
    } catch (err) {
      ErrorUtils.handleErrorMessage(err);
    }
  }

  const modalHandler = useCallback(
    async (values: GameFormValues) => {
      if (
        formik.values.courtBooked &&
        formik.values.club?.yClientsIntegrated &&
        bookingAvailable &&
        !isGameWithBooking
      ) {
        setPaymentPageVisible(true);
        setErrorField(undefined);
        setWarningModal(false);
      } else {
        submitForm(values);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      submitForm,
      formik.values.courtBooked,
      formik.values.club?.yClientsIntegrated,
      bookingAvailable,
      isGameWithBooking,
    ]
  );

  const scrollToError = (ref: any) => {
    const centerScreen =
      ref.current.offsetTop -
      (window.window.screen.height - ref.current.clientHeight) / 2;
    window.scrollTo({
      left: 0,
      top: centerScreen,
      behavior: 'smooth',
    });
  };

  const handleClubSelection = useCallback(
    (uid: string) => {
      const club = clubList?.find((c) => c.reference?.uid === uid);
      formik.setFieldValue('club', club);
      if (formik.values.playersLimit === 8 && !club?.lunda) {
        formik.setFieldValue('playersLimit', 7);
      }
      if (formik.values.courtBooked && !club?.yClientsIntegrated) {
        formik.setFieldValue('courtBooked', false);
      }
      setClubsPageVisisble(false);
    },
    [clubList, formik]
  );

  const scrollToClubSelection = useCallback(() => {
    scrollToError(clubRef);
    setErrorField('club');
    PopupMessage.open('Выберите клуб!');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.club, clubRef]);

  useEffect(() => {
    if (!id && current.grades && current.grade) {
      const maxValidGrade = Object.keys(current.grades).length;
      const minGrade =
        Number(current.grade.match(/\d/g)?.[0]) - 1 < 1
          ? 1
          : Number(current.grade.match(/\d/g)?.[0]) - 1;
      const maxGrade =
        Number(current.grade.match(/\d/g)?.[0]) + 1 > maxValidGrade
          ? maxValidGrade
          : Number(current.grade.match(/\d/g)?.[0]) + 1;
      formik.setFieldValue('minGrade', minGrade);
      formik.setFieldValue('maxGrade', maxGrade);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.grades, current.grade, id]);

  useEffect(() => {
    if (formik.values.gameTournamentKind && formik.values.playersLimit < 8) {
      formik.setFieldValue('gameTournamentKind', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.gameTournamentKind, formik.values.playersLimit]);

  useEffect(() => {
    if (current.grades) {
      if (id) {
        Promise.all([
          gameLoad({ uid: id }),
          loadClubs(),
          bookingAvailableCheck(),
        ])
          .then(
            ([
              { result },
              { result: resultClubs },
              { result: bookingAvailabilityResult },
            ]) => {
              if (result && resultClubs) {
                setClubList(resultClubs.views);
                setGameDate(result.plannedDate);
                setBookingAvailable(!!bookingAvailabilityResult?.available);
                const minGrade = Object.entries(current.grades ?? {}).find(
                  (entry) => `${entry[0]}` === result.minGrade
                );
                const maxGrade = Object.entries(current.grades ?? {}).find(
                  (entry) => `${entry[0]}` === result.maxGrade
                );
                const formValues: GameFormValues = {
                  ...gameInitialValues,
                  description: result.description ?? '',
                  courtBooked:
                    window.location.search === '?booking'
                      ? true
                      : !!result.courtBooked,
                  courtNumber: result.courtNumber ?? '',
                  duration: `${(result.duration ?? 120) / 60}`.replace(
                    '.',
                    ','
                  ),
                  everyoneCanInvite:
                    !!result.permissions?.inviteCreate?.includes(
                      GameRole.PLAYER
                    ),
                  isCoachParticipating: !!result.isAuthUserParticipating,
                  plannedDate: {
                    date: dayjs(result.plannedDate).toDate(),
                    time: dayjs(result.plannedDate).format('H:mm'),
                  },
                  playersLimit: result.playersLimit ?? 4,
                  privateGame: !!result.privateGame,
                  ranking: !!result.ranking,
                  minGrade: Number(minGrade?.[0].split('_')?.[1]),
                  maxGrade: Number(maxGrade?.[0].split('_')?.[1]),
                  invitations:
                    result.invitations
                      ?.filter(
                        (inv) =>
                          inv.sender?.uid !== inv.player?.uid &&
                          inv.invitationStatus === InvitationStatus.SENT
                      )
                      .map((inv) => inv.player ?? {}) ?? [],
                  club: resultClubs.views?.find(
                    (cl) => cl.reference?.uid === result.club?.uid
                  ),
                  identity: result.identity,
                  members: result.members ?? [],
                  everyoneCanSetScore:
                    !!result.permissions?.scoringResult?.includes(
                      GameRole.PLAYER
                    ),
                  gameTournamentKind: result.gameTournamentKind,
                };
                formik.setValues(formValues);
                const activeReservation = result.reservationInfos?.find(
                  (res) =>
                    res.status === ReservationStatus.PAID ||
                    res.status === ReservationStatus.PAYMENT_AWAITING
                );
                if (
                  result.courtBooked &&
                  result.club?.yClientsIntegrated &&
                  activeReservation
                ) {
                  isGameWithBooking = true;
                } else {
                  if (
                    bookingAvailabilityResult &&
                    window.location.search === '?booking'
                  ) {
                    return [
                      availableSlotsLoadList({
                        club: {
                          uid: result.club?.uid,
                          type: 'com.gridnine.platform.lundapadel.model.club.Club',
                        },
                        date: toLocalDate(dayjs(result.plannedDate)),
                        duration: result.duration,
                        // eslint-disable-next-line no-console
                      }).catch((err) => console.log(err)),
                      dayjs(result.plannedDate).format('HH:mm:ss'),
                    ] as [Promise<AvailableSlotsLoadListResponse>, string];
                  }
                }
              }
            }
          )
          .then((res) => {
            if (res) {
              const requestRes = res[0];
              const timeRes = res[1];
              requestRes
                .then(({ result }) => {
                  const isFree = result?.slots?.find(
                    (slot) => slot.time === timeRes
                  );
                  setAvailableSlots(result?.slots ?? []);
                  setBookingModalState((state) => {
                    state.type = isFree ? 'free' : 'booked';
                    state.visible = true;
                  });
                  if (!isFree) {
                    formik.setFieldValue('plannedDate.time', undefined);
                  }
                })
                // eslint-disable-next-line no-console
                .catch((err) => console.log(err));
            }
          })
          .catch((err) => ErrorUtils.handleErrorMessage(err))
          .finally(() => {
            setLoading(false);
            initialLoad = false;
          });
      } else {
        Promise.all([loadClubs(), bookingAvailableCheck()])
          .then(([{ result }, { result: bookingAvailabilityResult }]) => {
            const invitationsFromLocalStorage =
              localStorage.getItem('gameInvitations');
            if (invitationsFromLocalStorage) {
              formik.setFieldValue(
                'invitations',
                JSON.parse(invitationsFromLocalStorage)
              );
              localStorage.removeItem('gameInvitations');
            }
            setClubList(result?.views ?? []);
            setBookingAvailable(!!bookingAvailabilityResult?.available);
          })
          .catch((err) => ErrorUtils.handleErrorMessage(err))
          .finally(() => {
            setLoading(false);
            initialLoad = false;
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, current.grades]);

  useEffect(() => {
    if (formik.values.plannedDate?.date) {
      gameBookedListTime({
        plannedDate: toLocalDate(formik.values.plannedDate.date),
      })
        .then(({ result }) => {
          setBookedTimes(result?.times ?? []);
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err));
    }
  }, [formik.values?.plannedDate?.date]);

  useEffect(() => {
    if (!initialLoad) {
      if (
        bookingAvailable &&
        formik.values.plannedDate.date &&
        formik.values.duration &&
        formik.values.courtBooked &&
        formik.values.club?.yClientsIntegrated
      ) {
        availableSlotsLoadList({
          club: formik.values.club.reference,
          date: toLocalDate(formik.values.plannedDate.date),
          duration: Number(formik.values.duration.replace(',', '.')) * 60,
        })
          .then(({ result }) => {
            if (result?.slots) {
              setAvailableSlots(result?.slots);
            }
          })
          // eslint-disable-next-line no-console
          .catch((err) => console.log(err));
      } else {
        setAvailableSlots([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bookingAvailable,
    formik.values.courtBooked,
    formik.values.club,
    formik.values.duration,
    formik.values.plannedDate.date,
    initialLoad,
  ]);

  useEffect(() => {
    if (!initialLoad) {
      if (
        bookingAvailable &&
        formik.values.plannedDate.date &&
        formik.values.duration &&
        formik.values.courtBooked &&
        formik.values.club?.yClientsIntegrated &&
        formik.values.plannedDate.time
      ) {
        availableCourtsLoadList({
          club: formik.values.club.reference,
          date: toLocalDate(formik.values.plannedDate.date),
          duration: Number(formik.values.duration.replace(',', '.')) * 60,
          time: dayjs(formik.values.plannedDate.time, 'H:mm').format(
            'HH:mm:ss'
          ),
        })
          .then(({ result }) => {
            const stringifiedCourts =
              result?.courts?.map((court) => JSON.stringify(court)) ?? [];
            setAvailableCourts(stringifiedCourts);
            formik.setFieldValue('selectedCourts', [
              JSON.stringify(letSelectCourt),
            ]);
          })
          // eslint-disable-next-line no-console
          .catch((err) => console.log(err));
      } else {
        formik.setFieldValue('selectedCourts', []);
        setAvailableCourts([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bookingAvailable,
    formik.values.courtBooked,
    formik.values.club,
    formik.values.plannedDate.time,
    formik.values.duration,
    formik.values.plannedDate.date,
    initialLoad,
  ]);

  useEffect(() => {
    return () => {
      isGameWithBooking = false;
      initialLoad = true;
    };
  }, []);

  return (
    <>
      <BookingStatusModal
        visible={bookingModalState.visible}
        onClose={() =>
          setBookingModalState((state) => {
            state.visible = false;
          })
        }
        type={bookingModalState.type}
      />
      <TimeWarningModal<GameFormValues>
        visible={waringModal}
        onClose={() => setWarningModal(false)}
        submitForm={modalHandler}
        formValues={formik.values}
      />
      {!(paymentPageVisible || clubsPageVisible || searchPageVisible) ? (
        <div>
          <Header
            className={styles['header']}
            handleClick={() =>
              id ||
              window.location.search.includes('fromPartners') ||
              window.location.search.includes('fromReservations')
                ? navigate(-1)
                : navigate('/play')
            }
          >
            {id ? 'Редактирование игры' : 'Создание игры'}
          </Header>
          {!id &&
          (current.roles?.includes(Role.COACH) ||
            current.roles?.includes(Role.ADMIN)) ? (
            <CreateTabs activeTab="game" />
          ) : null}
          {loading ? (
            <PuffLoader color="#4EB857" cssOverride={{ margin: 'auto' }} />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              {!isGameWithBooking ? (
                <div ref={clubRef}>
                  <SelectClubSection
                    clubList={clubList}
                    selectedClub={formik.getFieldProps('club').value}
                    handleClubSelection={handleClubSelection}
                    onClick={() =>
                      clubList ? setClubsPageVisisble(true) : undefined
                    }
                  />
                </div>
              ) : null}
              <HLine
                fieldsErrors={errorField === 'club'}
                calssName={styles['hline']}
              />
              <SelectRating
                title="Выберите рейтинг игроков"
                onChange={(value) => {
                  formik.setFieldValue('minGrade', value[0]);
                  formik.setFieldValue('maxGrade', value[1]);
                }}
                value={[formik.values.minGrade, formik.values.maxGrade]}
              />
              <HLine
                calssName={styles['hline']}
                fieldsErrors={errorField === 'kind'}
              />
              <div ref={kindRef}>
                <GamePlayersFormItem
                  playersLimit={formik.values.playersLimit}
                  evetyoneCanInvite={formik.values.everyoneCanInvite}
                  isCoachParticipating={formik.values.isCoachParticipating}
                  invitations={formik.values.invitations}
                  lundaClub={!!formik.values.club?.lunda}
                  tourenamentKind={formik.values.gameTournamentKind}
                  inputChange={(value) =>
                    formik.setFieldValue('playersLimit', value)
                  }
                  switcherChange={(value) =>
                    formik.setFieldValue(value, !formik.values[value])
                  }
                  tournamentChange={(kind) =>
                    formik.setFieldValue('gameTournamentKind', kind)
                  }
                  onInviteClick={() => setSearchPageVisible(true)}
                />
              </div>
              <HLine
                calssName={styles['hline']}
                fieldsErrors={errorField === 'kind'}
              />
              <MatchDetails
                onChange={formik.handleChange}
                formikValues={formik.values}
                clublist={clubList}
                setFieldValue={formik.setFieldValue}
                scrollToClubSelection={scrollToClubSelection}
                isGameWithBooking={isGameWithBooking}
                isBookingAvailable={bookingAvailable}
              />
              {!isGameWithBooking ? (
                <>
                  <SelectDate
                    onActiveDateChange={(date) => {
                      formik.setFieldValue('plannedDate.date', date);
                      if (formik.values.courtBooked) {
                        formik.setFieldValue('plannedDate.time', undefined);
                        formik.setFieldValue('selectedCourts', []);
                      }
                    }}
                    initialStartDate={toLocalDateTime(
                      formik.values.plannedDate?.date
                    )}
                    setErrorField={(field: string) => setErrorField(field)}
                  />
                  <MatchDurationRadio
                    durationValue={formik.values.duration}
                    onChange={(e) => {
                      formik.setFieldValue('duration', e.target.value);
                      if (formik.values.courtBooked) {
                        formik.setFieldValue('plannedDate.time', undefined);
                        formik.setFieldValue('selectedCourts', []);
                      }
                    }}
                    booking={
                      !!(
                        formik.values.courtBooked &&
                        formik.values.club?.yClientsIntegrated
                      )
                    }
                  />
                  <HLine
                    fieldsErrors={errorField === 'time'}
                    calssName={styles['hline']}
                  />
                  <div ref={timeRef}>
                    <SelectTime
                      onChange={formik.handleChange}
                      bookedTimes={bookedTimes}
                      formikValues={formik.values}
                      availableSlots={
                        bookingAvailable &&
                        formik.values.club?.yClientsIntegrated &&
                        formik.values.courtBooked
                          ? availableSlots
                          : undefined
                      }
                    />
                  </div>
                  <HLine
                    fieldsErrors={errorField === 'time'}
                    calssName={styles['hline']}
                  />
                  {formik.values.courtBooked &&
                  formik.values.plannedDate.time &&
                  formik.values.club?.yClientsIntegrated &&
                  bookingAvailable ? (
                    <SelectCourtForBooking
                      availableCourts={availableCourts}
                      selectedCourts={formik.values.selectedCourts}
                      addCourt={(court) =>
                        formik.setFieldValue(
                          'selectedCourts',
                          formik.values.selectedCourts.concat(
                            JSON.stringify(letSelectCourt)
                          )
                        )
                      }
                      deleteCourt={() =>
                        formik.setFieldValue(
                          'selectedCourts',
                          formik.values.selectedCourts.slice(0, 1)
                        )
                      }
                      selectCourt={(court, number) => {
                        const newCourts = formik.values.selectedCourts.slice();
                        newCourts[number] = court;
                        formik.setFieldValue('selectedCourts', newCourts);
                      }}
                    />
                  ) : null}
                  {!(
                    formik.values.courtBooked &&
                    formik.values.club?.yClientsIntegrated
                  ) ? (
                    <MatchResultSettings getFieldValue={formik.getFieldProps} />
                  ) : null}
                </>
              ) : null}
              <OversideWrapper>
                {formik.values.courtBooked &&
                formik.values.club?.yClientsIntegrated &&
                bookingAvailable &&
                !isGameWithBooking ? (
                  <Button
                    className={styles['createMatchBtn']}
                    type="submit"
                    disabled={
                      !!paymentData.confirmationToken ||
                      !!paymentData.paymentUid ||
                      loading ||
                      !isCourtsSelected()
                    }
                  >
                    Перейти к оплате
                  </Button>
                ) : (
                  <Button
                    className={styles['createMatchBtn']}
                    type="submit"
                    disabled={
                      !!paymentData.confirmationToken ||
                      !!paymentData.paymentUid ||
                      loading
                    }
                  >
                    {!id ? <CrossIcon fill="var(--white)" /> : null}
                    {id ? 'Сохранить игру' : 'Создать игру'}
                  </Button>
                )}
              </OversideWrapper>
            </form>
          )}
        </div>
      ) : null}
      {clubsPageVisible && clubList ? (
        <ClubSelectionPage
          selectedClub={formik.getFieldProps('club').value}
          onClose={() => setClubsPageVisisble(false)}
          handleClubSelect={handleClubSelection}
        />
      ) : null}
      {searchPageVisible && current.grades ? (
        <MatchSearchPage
          onSubmit={(invitedPlayers) => {
            formik.setFieldValue('invitations', invitedPlayers);
            setSearchPageVisible(false);
          }}
          ratingRange={[formik.values.minGrade, formik.values.maxGrade]}
          initialSlectedPlayers={formik.values.invitations}
          onClose={() => setSearchPageVisible(false)}
          excludedUids={[]}
        />
      ) : null}
      {paymentPageVisible ? (
        <BookingPaymentPage
          formValues={formik.values}
          onClose={() => setPaymentPageVisible(false)}
          current={current}
          paymentData={paymentData}
          setPaymentData={setPaymentData}
        />
      ) : null}
    </>
  );
};

export default GameCreatePage;
