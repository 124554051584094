import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal';
import styles from './TournamentActionModal.module.css';
import { ConfirmationModalProps } from '@/components/ConfirmationModal/ConfirmationModal.interfaces';
import ModalBallIcon from '@/static/images/icons/ModalBallIcon';
import ModalArrowIcon from '@/static/images/icons/ModalArrowIcon';
import { TournamentModalPlayerData, TournamentPageModalState } from '../../TournamentPage.interfaces';

type TournamentModalProps = {
  type: keyof Omit<TournamentPageModalState, 'playerData'>;
  handler?: (uid?: string) => void;
  playerData?: TournamentModalPlayerData;
} & ConfirmationModalProps;

const TournamentActionModal = ({ type, handler, playerData, ...props }: TournamentModalProps) => {
  const renderModalContent = () => {
    switch(type) {
      case 'leaveTournamentVisible': {
        return (
          <> 
            <div className={styles['question']}>
              Вы уверены, что хотите отменить участие в турнире?
            </div>
            <div className={styles['description']}>
              После отмены участия ваше место может занять другой игрок.
            </div>
            <button className={styles['button']} onClick={() => handler?.()}>
              <div className={styles['button-inner']}>
                <ModalBallIcon/>
                <span>Отменить участие</span>
                <ModalArrowIcon/>
              </div>
            </button>
          </>
        );
      }
      case 'leaveQueueVisible': {
        return (
          <> 
            <div className={styles['question']}>
              Вы уверены, что хотите отменить участие в турнире?
            </div>
            <div className={styles['description']}>
              После отмены участия ваше место может занять другой игрок.
            </div>
            <button className={styles['button']} onClick={() => handler?.()}>
              <div className={styles['button-inner']}>
                <ModalBallIcon/>
                <span>Отменить участие</span>
                <ModalArrowIcon/>
              </div>
            </button>
          </>
        );
      }
      case 'joinQueueVisible': {
        return (
          <> 
            <div className={styles['question']}>
              Вы уверены, что хотите занять очередь в листе ожидания?
            </div>
            <div className={styles['description']}>
              Действующие участники могут покинуть турнир, тогда вы займете место участника в порядке очереди и получите уведомление об этом.
            </div>
            <button className={styles['button']} onClick={() => handler?.()}>
              <div className={styles['button-inner']}>
                <ModalBallIcon/>
                <span>Занять очередь</span>
                <ModalArrowIcon/>
              </div>
            </button>
          </>
        );
      }
      case 'kickPlayerVisible': {
        return (
          <>
            <div className={styles['question']}>
              Вы уверены, что хотите удалить <span style={{ color: 'var(--blue)' }}>{playerData?.displayName}</span> из турнира?
            </div>
            <div className={styles['description']}>
              Этот игрок получит уведомление и его место будет свободно.
            </div>
            <button className={styles['button']} onClick={() => handler?.(playerData?.uid)}>
              <div className={styles['button-inner']}>
                <ModalBallIcon/>
                <span>Удалить игрока</span>
                <ModalArrowIcon/>
              </div>
            </button>
          </>
        );
      }
      case 'cancelTournamentVisible': {
        return (
          <> 
            <div className={styles['question']}>
              Вы уверены, что хотите отменить турнир?
            </div>
            <div className={styles['description']}>
              Участники турнира получат уведомления об отмене турнира.<br/> Не забудьте позвонить каждому участнику турнира и предупредить об отмене.
            </div>
            <button className={styles['button']} onClick={() => handler?.()}>
              <div className={styles['button-inner']}>
                <ModalBallIcon/>
                <span>Отменить турнир</span>
                <ModalArrowIcon/>
              </div>
            </button>
          </>
        );
      }
      case 'removeInviteVisible': {
        return (
          <>
            <div className={styles['question']}>
              <span>Вы уверены, что хотите удалить <span style={{ color: 'var(--blue)'}}>{playerData?.displayName}</span> из списка приглашенных в этот турнир?</span>
            </div>
            <div className={styles['description']}>
              Игрок не сможет присоединиться к турниру по этому приглашению 
            </div>
            <button className={styles['button']} onClick={() => handler?.(playerData?.uid)}>
              <div className={styles['button-inner']}>
                <ModalBallIcon/>
                <span>Удалить игрока</span>
                <ModalArrowIcon/>
              </div>
            </button>
          </>
        )
      }
      case 'delegateOwnershipVisible': {
        return (
          <>
            <div className={styles['question']}>
              <span>Отправить запрос на передачу прав <span style={{ color: 'var(--blue)'}}>{playerData?.displayName}</span>?</span>
            </div>
            <div className={styles['description']}>
              После принятия запроса все права на турнир перейдут другому игроку  
            </div>
            <button className={styles['button']} onClick={() => handler?.(playerData?.uid)}>
              <div className={styles['button-inner']}>
                <ModalBallIcon/>
                <span>Отправить</span>
                <ModalArrowIcon/>
              </div>
            </button>
          </>
        )
      }
      default: {
        const _: never = type;
        throw new Error('Not every type is handled')
      }
    }
  }

  return (  
    <ConfirmationModal {...props}>
      {renderModalContent()}
    </ConfirmationModal>
  );
}
 
export default TournamentActionModal;
