import { CalculatedGameStatus, GameTournamentStatus, InvitationStatus, ReservationInfo, toLocalDateTime } from '@/integration-api/server-rest-lundapadelApi';
import styles from './GameStatusContent.module.css';
import GameDetailsCard from '../components/GameDetailsCard/GameDetailsCard';
import GamePlayerCarousel from '../components/GamePlayerCarousel/GamePlayerCarousel';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Button, { ButtonVariants } from '@/components/Button/Button';
import { canUserInvite, canUserSetScore, gameModifiableStatuses } from '../utils';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useAppSelector } from '@/hooks/hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { GameActions, HandleGameActionParams, GameModalsState } from './GameStatusContent.interfaces';
import { Updater } from 'use-immer';
import { IGamePageContext } from '../GamePage.interfaces';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';
import EntityWaitingList from '@/components/EntityWaitingList/EntityWaitingList';
import { bookingAvailableCheck } from '../service';
import formatter, { ErrorUtils } from '@/utils/utils';
import { TIME_FORMAT_HH_MM } from '@/utils/constants';

type PlannedGameContentProps = {
  activeReservation?: ReservationInfo;
  handelWaitingListVisible: (state: boolean) => void;
  handleGameAction: (action: GameActions, params?: HandleGameActionParams) => Promise<void>;
  setModalsState: Updater<GameModalsState>;
}

const PlannedGameContent = ({ activeReservation, handelWaitingListVisible, handleGameAction, setModalsState }: PlannedGameContentProps) => {
  const { game, handlerLoading } = useOutletContext<IGamePageContext>();
  const current = useAppSelector(state => state.auth.currentProfile);
  const navigate = useNavigate();
  const { id } = useParams();

  const [editMode, setEditMode] = useState(false);
  const [bookingAvailable, setBookingAvailable] = useState(false);
  const [offset, setOffset] = useState(0);

  const buttonsRef = useRef<HTMLDivElement>(null);

  const handleSetResultBtnClick = () => {
    if (game.condition?.locked) {
      PopupMessage.open('Игра редактируется дргуим пользователем.', 'error');
    } else {
      navigate(`/game/${id}/result`);
    }
  }

  const hasSentRequest = useMemo(() => {
    return !!game?.invitations?.find(inv => inv.sender?.uid === current?.identity?.uid && inv.player?.uid === current?.identity?.uid && inv.invitationStatus === InvitationStatus.SENT);
  }, [game?.invitations, current?.identity?.uid]);

  const hasBeenInvited = useMemo(() => {
    return !!game?.invitations?.find(inv => inv.player?.uid === current?.identity?.uid && inv.sender?.uid !== current?.identity?.uid && inv.invitationStatus === InvitationStatus.SENT);
  }, [game?.invitations, current?.identity?.uid, game?.owner?.uid]);  

  const renderButtons = () => {
    if(editMode) {
      return (
        <Button
          variant={ButtonVariants.PRIMARY}
          onClick={() => setEditMode(false)}
          disabled={handlerLoading || !navigator.onLine}
        >
          Сохранить состав игроков
        </Button>
      )
    }
    if(game && game.hasReceiveOwnershipRequest && game.status === CalculatedGameStatus.PLANNED) {
      return (
        <>
          <span className={styles['invite-received']}>Вас просят стать организатором игры</span>
          <Button
            variant={ButtonVariants.PRIMARY}
            onClick={() => handleGameAction('handleOwnershipChange', { accepted: true })}
            disabled={handlerLoading || !navigator.onLine}
          >
            Принять
          </Button>
          <Button
            variant={ButtonVariants.GRAYBLUE_OUTLINED}
            onClick={() => handleGameAction('handleOwnershipChange', { accepted: false })}
            disabled={handlerLoading || !navigator.onLine}
          >
            Отклонить
          </Button>
        </>
      );
    }
    if(canUserSetScore(game, current?.identity?.uid!) && game?.status === CalculatedGameStatus.STARTED && game?.members?.length! >= 4) {
      return (
        <Button
          variant={ButtonVariants.PRIMARY}
          onClick={handleSetResultBtnClick}
          disabled={handlerLoading || !navigator.onLine}
        >
          <span>Выставить результаты игры</span>
        </Button>
      );
    }
    if(game.owner?.uid === current?.identity?.uid && game?.status === CalculatedGameStatus.PLANNED && game?.tournamentStatus === GameTournamentStatus.PLANNED && game?.gameTournamentKind && game?.members?.length === game?.playersLimit) {
      if(game.deadlineToStart) {
        return (
          <span className={styles['mimitournament-deadline']}>
            Игру можно начать не раньше, чем в {formatter.formatDateToDayDateMonth(game.deadlineToStart)} в {formatter.formatDate(game.deadlineToStart, TIME_FORMAT_HH_MM)}
          </span>
        )
      } else {
        return (
          <Button
            variant={ButtonVariants.PRIMARY}
            onClick={() => handleGameAction('start')}
            disabled={handlerLoading || !navigator.onLine}
          >
            <span>Начать игру</span>
          </Button>
        );
      }
    }
    if(gameModifiableStatuses.includes(game?.status!) && canUserInvite(game, current?.identity?.uid!)) {
      return (
        <>
          {(game?.status === CalculatedGameStatus.STARTED && game?.members?.length! < 4) || (game?.status === CalculatedGameStatus.PLANNED && game?.members?.length! < game?.playersLimit!) ?            
            <Button
              variant={ButtonVariants.PRIMARY}
              onClick={() => navigate(`/game/${id}/invitation`)}
              disabled={handlerLoading || !navigator.onLine}
            >
              Пригласить игроков
            </Button> : null
          }
          {game.status === CalculatedGameStatus.PLANNED && game.owner?.uid !== current.identity?.uid ?
            <Button
              variant={ButtonVariants.CANCEL}
              bordered
              onClick={() => setModalsState(state => { 
                state.leaveGameVisible = true;
                state.playerData = { uid: current?.identity?.uid } 
              })}
              disabled={handlerLoading || !navigator.onLine}
            >
              Покинуть игру
            </Button> : null
          }
        </>
      );
    }
    if(current?.identity?.uid !== game?.owner?.uid) {
      if(game?.isAuthUserParticipating && game?.status === CalculatedGameStatus.PLANNED) {
        return (
          <>
            <Button
              variant={ButtonVariants.CANCEL}
              bordered
              onClick={() => setModalsState(state => { 
                state.leaveGameVisible = true;
                state.playerData = { uid: current?.identity?.uid } 
              })}
              disabled={handlerLoading || !navigator.onLine}
            >
              Покинуть игру
            </Button>
          </>
        );
      }
      if(gameModifiableStatuses.includes(game?.status!) && !game?.isAuthUserParticipating && game?.members?.length !== game?.playersLimit) {
        if(hasBeenInvited) {
          return (
            <>
              <span className={styles['invite-received']}>Вы приглашены в игру!</span>
              <Button
                variant={ButtonVariants.PRIMARY}
                onClick={() => handleGameAction('acceptInvite', { playerUid: current?.identity?.uid })}
                disabled={handlerLoading || !navigator.onLine}
              >
                Принять
              </Button>
              <Button
                variant={ButtonVariants.GRAYBLUE_OUTLINED}
                onClick={() => handleGameAction('removeInvite', { playerUid: current?.identity?.uid })}
                disabled={handlerLoading || !navigator.onLine}
              >
                Отклонить
              </Button>
            </>
          );
        }
        if(!hasBeenInvited) {
          return (
            <>
              {!hasSentRequest ?  
                <Button
                  variant={ButtonVariants.PRIMARY}
                  onClick={() => handleGameAction('join')}
                  disabled={handlerLoading || !navigator.onLine}
                >
                  Присоединиться к игре
                </Button> : 
                <Button
                  variant={ButtonVariants.DISABLED}
                  onClick={() => handleGameAction('cancelRequest')}
                  disabled={handlerLoading || !navigator.onLine}
                >
                  Отменить запрос
                </Button>
              }
            </>
          );
        }
      }
    }
    return null;
  }

  useEffect(() => {
    bookingAvailableCheck()
      .then(({ result }) => {
        setBookingAvailable(!!result?.available);
      })
      .catch(err => ErrorUtils.handleErrorMessage(err))
  }, []);

  useEffect(() => {
    setOffset(buttonsRef.current?.clientHeight ?? 0);
  }, [buttonsRef.current?.clientHeight, offset, game, editMode]);

  return (
    <>
      <div className={styles['wrapper']}>
        <GameDetailsCard 
          game={game}
          activeReservation={activeReservation}
          showQR={game?.owner?.uid === current.identity?.uid}
        />
        {navigator.onLine && bookingAvailable && game?.status === CalculatedGameStatus.PLANNED && game?.club?.yClientsIntegrated ?
          <>
            {!game?.courtBooked && game?.owner?.uid === current.identity?.uid ?
              <Button
                style={{ marginTop: '8px' }}
                variant={ButtonVariants.CYAN_OUTLINED}
                onClick={() => navigate(`/game/${id}/settings?booking`)}
              >
                Забронировать корт
              </Button> : null
            }
            {game?.courtBooked && activeReservation && (game?.owner?.uid === current.identity?.uid || activeReservation?.profileUid === current.identity?.uid) ?
              <Button
                style={{ marginTop: '8px' }}
                variant={ButtonVariants.GRAYBLUE_OUTLINED}
                onClick={() => setModalsState(state => { state.cancelBookingVisible = true })}
              >
                Снять бронирование
              </Button> : null
            }
          </> : null
        }
        <GamePlayerCarousel
          players={game?.members?.map(member => member.player ?? {}).concat(Array(game?.playersLimit! - game?.members?.length! > 0 ? game?.playersLimit! - game?.members?.length! : 0).fill(undefined)) ?? []}
          playersLimit={game?.playersLimit}
          type='participants'
          editMode={editMode}
          setEditMode={state => setEditMode(state)}
          owner={(current?.identity?.uid === game?.owner?.uid) && game?.status === CalculatedGameStatus.PLANNED && navigator.onLine}
          ownerUid={game?.owner?.uid}
          playerHandler={(uid, displayName) => {
            setModalsState(state => { 
              state.kickPlayerVisible = true;
              state.playerData = { uid, displayName } 
            }
          )}}
          openInvitesPage={() => navigate(`/game/${id}/invitation?viaCircle`)}
          canInvite={navigator.onLine && canUserInvite(game, current?.identity?.uid ?? '') && ((game?.status === CalculatedGameStatus.STARTED && game?.members?.length! < 4) || (game?.status === CalculatedGameStatus.PLANNED && game?.members?.length! < game?.playersLimit!))}
        />
        {game.invitations?.length ?
          <EntityWaitingList
            entity='game'
            invitations={game.invitations}
            onOpen={() => handelWaitingListVisible(true)}
            owner={game?.owner?.uid}
          /> : null
        }
        <div style={{ height: offset + 16 }}></div>
      </div>
      <OversideWrapper className={styles['buttons-wrapper']} buttonsRef={buttonsRef}>
        {renderButtons()}
      </OversideWrapper>
    </>
  );
}
 
export default PlannedGameContent;
