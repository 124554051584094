import { SubscriptionView } from '@/integration-api/server-rest-lundapadelApi';

export type FormValuesState = SubscriptionView & { onlyTournaments?: boolean }

type Actions = 'update';
type Payload = {
  [key in keyof FormValuesState] : FormValuesState[key];
}

export function createPageReducer<T extends FormValuesState>(state: T, action: {type: Actions, payload?: Payload}): T {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        ...action?.payload,
      }
    }
    default: return {...state}
  }
}
