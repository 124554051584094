type CourtIconProps = {
  fill?: string
}

const CourtIcon = ({ fill }: CourtIconProps) => {
  return (  
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9526 15.0663C11.9526 14.8025 11.8042 14.6517 11.5446 14.6517H8.44982C8.19023 14.6517 8.04185 14.8025 8.04185 15.0663V17H11.9529L11.9526 15.0663ZM9.99183 11.3662C10.8399 11.3662 11.5657 10.7522 11.7565 9.94973H8.2432C8.33107 10.3537 8.55268 10.7147 8.871 10.9725C9.18933 11.2303 9.58481 11.3693 9.99183 11.3662ZM11.7568 9.08268C11.566 8.27482 10.8402 7.67696 9.99209 7.66625C9.14427 7.66089 8.43427 8.2692 8.24346 9.08268H11.7568ZM8.04211 3.93366C8.04211 4.1975 8.19049 4.3483 8.45008 4.3483H11.5449C11.8045 4.3483 11.9529 4.1975 11.9529 3.93366V2H8.04185L8.04211 3.93366ZM12.806 2V3.95C12.806 4.74687 12.3448 5.21536 11.5605 5.21536H8.43401C7.64969 5.21536 7.18875 4.74687 7.18875 3.94973V2H6.64268C5.55108 2 5 2.56545 5 3.69125V9.08268H7.44307C7.64442 7.82241 8.69887 6.89054 9.99736 6.87446C11.3061 6.85839 12.3608 7.81705 12.5622 9.08268H15V3.69125C15 2.56545 14.4487 2 13.3571 2H12.806ZM12.806 17H13.3568C14.4487 17 14.9997 16.4292 14.9997 15.3087V9.95H12.5619C12.3661 11.2103 11.3114 12.1582 9.99736 12.1582C8.6936 12.1582 7.62308 11.2103 7.43253 9.95H5V15.3087C5 16.4295 5.5458 17 6.64268 17H7.18875V15.0503C7.18875 14.2531 7.64969 13.7846 8.43401 13.7846H11.5605C12.3448 13.7846 12.806 14.2534 12.806 15.0503V17Z" fill={fill ?? "#C3D82E"}/>
    </svg>
  );
}
 
export default CourtIcon;
