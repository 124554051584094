import { useCallback, useEffect, useRef, useState } from 'react';
import useDebounce from './useDebounce';

type UseInfiniteScrollParams = { offset?: number; delay?: number };

export default function useInfiniteScrol<T extends HTMLElement>(
  { offset = 1, delay = 50 }: UseInfiniteScrollParams = {
    offset: 1,
    delay: 50,
  }
) {
  const listRef = useRef<T>(null);
  const [isEndOfList, setIsEndOfList] = useState(false);

  const debouncedScrollHandler = useDebounce(scrollHandler, delay);

  useEffect(() => {
    const listRefNode = listRef.current;

    listRefNode?.addEventListener('scroll', debouncedScrollHandler);

    return () =>
      listRefNode?.removeEventListener('scroll', debouncedScrollHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function scrollHandler() {
    if (listRef.current == null) return;
    const { scrollHeight, scrollTop, clientHeight } = listRef.current;
    let isEndOfList =
      Math.round(scrollHeight) - offset <=
      Math.round(scrollTop) + Math.round(clientHeight);
    if (isEndOfList) {
      setIsEndOfList(true);
    }
  }

  const resetIsEndOfList = useCallback(() => {
    setIsEndOfList(false);
  }, []);

  const resetScroll = useCallback(() => {
    listRef.current?.scrollTo({ top: 0 });
    resetIsEndOfList();
  }, [resetIsEndOfList]);

  return { listRef, isEndOfList, resetIsEndOfList, resetScroll };
}
