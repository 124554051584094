import Icon, { IconProps } from "../Icon";

type MedalProps = { } & IconProps;

const Medal = ({ ...props }: MedalProps) => {
  return (  
    <Icon {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5528 8.17825L14.5896 1M7.51574 8.3372L4 1M10.322 7.8408L7.12393 1M11.6245 1L10.7773 3.125M4.81458 12.9C4.81458 14.2526 5.32951 15.5498 6.2461 16.5062C7.16268 17.4627 8.40584 18 9.70208 18C10.9983 18 12.2415 17.4627 13.1581 16.5062C14.0747 15.5498 14.5896 14.2526 14.5896 12.9C14.5896 11.5474 14.0747 10.2502 13.1581 9.29376C12.2415 8.33732 10.9983 7.8 9.70208 7.8C8.40584 7.8 7.16268 8.33732 6.2461 9.29376C5.32951 10.2502 4.81458 11.5474 4.81458 12.9Z" stroke="#C3D82E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.48047 12.0484L10.1096 10.7734V15.0234" stroke="#C3D82E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </Icon>
  );
}
 
export default Medal;
