/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CountReservationResult
 */
export interface CountReservationResult {
    /**
     * 
     * @type {number}
     * @memberof CountReservationResult
     */
    count?: number;
}

export function CountReservationResultFromJSON(json: any): CountReservationResult {
    return CountReservationResultFromJSONTyped(json, false);
}

export function CountReservationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountReservationResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function CountReservationResultToJSON(value?: CountReservationResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
    };
}

