import React, { forwardRef, ForwardRefRenderFunction, InputHTMLAttributes } from 'react';
import styles from './Input.module.css';

interface InputInterface extends InputHTMLAttributes<HTMLInputElement> {
  prefixIcon?: React.JSX.Element | null;
  icon?: React.JSX.Element;
  wrapperClassName?: string;
}

const Input: ForwardRefRenderFunction<HTMLInputElement, InputInterface> = (props, ref) => {
  const {
    prefixIcon,
    icon,
    wrapperClassName = '',
    ...inputProps
  } = props;

  return (
    <div className={`${styles.inputWrapper} ${wrapperClassName}`}>
      {prefixIcon ? <div className={styles.icon}>{prefixIcon}</div> : null}
      <input 
        {...inputProps} 
        className={`${styles.input} ${inputProps.className || ''}`} 
        onKeyDown={e => {
          if(e.key === 'Enter') {
            e.preventDefault();
            (e.target as HTMLInputElement).blur();
          }
        }}
        ref={ref}
      />
      <div className={styles.icon}>{icon}</div>
    </div>
  );
}

export default forwardRef(Input);
