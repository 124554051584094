import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import AppUrlListener from './components/AppUrlListener';
import { Capacitor } from '@capacitor/core';
import { turnOnFirebaseNotifications } from '@/integration/Notifications';
import { NotificationsService } from '@/integration/NotificationsPwa';
import styles from './App.module.css';
import useVersionChecker from '@/hooks/useVersionChecker';
import { CheckVersionStatus } from '@/integration-api/server-rest-lundapadelApi';
import UpdateRequiredPage from '../AppVersionChecker/components/UpdateRequiredPage/UpdateRequiredPage';
import FullscreenLoader from '../FullscreenLoader/FullscreenLoader';
import { PopupMessage } from '../PopupMessage/PopupMessage';

function App() {
  const locaton = useLocation();
  const navigate = useNavigate();
  const versionCheckResult = useVersionChecker();

  const redirect = (url: string) => {
    navigate(url);
  };

  useEffect(() => {
    if (!navigator.onLine) {
      PopupMessage.open('Нет соединения с интернетом!', 'no_internet');
    }
  }, [navigator.onLine, locaton]);

  useEffect(() => {
    const isPushNotificationsAvailable =
      Capacitor.isPluginAvailable('PushNotifications');

    if (isPushNotificationsAvailable) {
      turnOnFirebaseNotifications(redirect);
    }
  }, []);

  useEffect(() => {
    if ('Notification' in window) {
      const service = new NotificationsService();
      service.init();
    }
  }, []);

  useEffect(() => {
    const lastPath = JSON.parse(sessionStorage.getItem('lastPaths') || '[]');
    if (lastPath.slice(-1)[0] !== window.location.pathname) {
      sessionStorage.setItem(
        'lastPaths',
        JSON.stringify([...lastPath, window.location.pathname].splice(-2))
      );
    }
  }, [window.location.pathname]);

  // useEffect(() => {
  //   if (Capacitor.isPluginAvailable('Geolocation')) {
  //     Geolocation.requestPermissions().catch((err) => console.log(err));
  //   }
  // }, []);

  return (
    <div className={styles.app}>
      {versionCheckResult ? (
        versionCheckResult === CheckVersionStatus.SUPPORTED ? (
          <>
            <AppUrlListener />
            <Outlet />
          </>
        ) : (
          <UpdateRequiredPage />
        )
      ) : (
        <FullscreenLoader loading />
      )}
    </div>
  );
}

export default App;
