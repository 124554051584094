/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReservationStatus {
    PAYMENT_AWAITING = 'PAYMENT_AWAITING',
    PAID = 'PAID',
    CANCELED = 'CANCELED'
}

export function ReservationStatusFromJSON(json: any): ReservationStatus {
    return ReservationStatusFromJSONTyped(json, false);
}

export function ReservationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationStatus {
    return json as ReservationStatus;
}

export function ReservationStatusToJSON(value?: ReservationStatus | null): any {
    return value as any;
}

