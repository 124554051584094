import Icon, { IconProps } from "../Icon";

interface WalletProps extends IconProps {}

const Wallet = ({ ...props }: WalletProps) => {
  return (  
    <Icon {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.11111 8H5V14H15V8H14.1667M11.9444 7.4C12.1619 7.53578 12.3481 7.72288 12.4891 7.94731C12.6302 8.17174 12.7225 8.4277 12.759 8.69605C12.7956 8.96441 12.7756 9.23823 12.7004 9.49704C12.6252 9.75585 12.4968 9.99297 12.3249 10.1907C12.1529 10.3884 11.9418 10.5415 11.7073 10.6387C11.4728 10.7359 11.2211 10.7746 10.9708 10.7519C10.7205 10.7293 10.4782 10.6458 10.262 10.5077C10.0458 10.3697 9.86127 10.1807 9.72222 9.9548M10.5556 6.8C10.5556 7.27739 10.38 7.73523 10.0674 8.07279C9.75484 8.41036 9.33092 8.6 8.88889 8.6C8.44686 8.6 8.02294 8.41036 7.71038 8.07279C7.39782 7.73523 7.22222 7.27739 7.22222 6.8C7.22222 6.32261 7.39782 5.86477 7.71038 5.52721C8.02294 5.18964 8.44686 5 8.88889 5C9.33092 5 9.75484 5.18964 10.0674 5.52721C10.38 5.86477 10.5556 6.32261 10.5556 6.8Z" stroke="#C3D82E" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </Icon>
  );
}
 
export default Wallet;
