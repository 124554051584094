import Icon, { IconProps } from "../Icon";

interface ReservationLinkedProps extends IconProps {}

const ReservationLinked = ({ ...props }: ReservationLinkedProps) => {
  return (  
    <Icon {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_8995_56022)">
          <path d="M11.875 7.5C12.2202 7.5 12.5 7.77982 12.5 8.125C12.5 8.47018 12.2202 8.75 11.875 8.75H8.75C8.08696 8.75 7.45107 9.01339 6.98223 9.48223C6.51339 9.95107 6.25 10.587 6.25 11.25V13.75C6.25 14.413 6.51339 15.0489 6.98223 15.5178C7.45107 15.9866 8.08696 16.25 8.75 16.25H15C15.663 16.25 16.2989 15.9866 16.7678 15.5178C17.2366 15.0489 17.5 14.413 17.5 13.75V11.25C17.5 10.8112 17.3845 10.3801 17.165 10C17.0122 9.73534 16.8127 9.5019 16.5773 9.31045C16.3863 9.15512 16.25 8.93334 16.25 8.68714C16.25 8.2115 16.7239 7.88431 17.1164 8.15309C18.1027 8.82868 18.75 9.96313 18.75 11.2487V13.7487C18.75 14.7433 18.3549 15.6971 17.6517 16.4004C16.9484 17.1037 15.9946 17.4987 15 17.4987H8.75C7.75544 17.4987 6.80161 17.1037 6.09835 16.4004C5.39509 15.6971 5 14.7433 5 13.7487V11.25C5 10.2554 5.39509 9.30161 6.09835 8.59835C6.80161 7.89509 7.75544 7.5 8.75 7.5H11.875Z" fill="#C3D82E"/>
          <path d="M8.125 12.5C7.77982 12.5 7.5 12.2202 7.5 11.875C7.5 11.5298 7.77982 11.25 8.125 11.25H11.25C11.913 11.25 12.5489 10.9866 13.0178 10.5178C13.4866 10.0489 13.75 9.41304 13.75 8.75V6.25C13.75 5.58696 13.4866 4.95107 13.0178 4.48223C12.5489 4.01339 11.913 3.75 11.25 3.75H5C4.33696 3.75 3.70107 4.01339 3.23223 4.48223C2.76339 4.95107 2.5 5.58696 2.5 6.25V8.75C2.50001 9.18883 2.61553 9.61993 2.83495 9.99996C2.98779 10.2647 3.18728 10.4981 3.42272 10.6896C3.61371 10.8449 3.75 11.0666 3.75 11.3128C3.75 11.7884 3.276 12.1159 2.88342 11.8473C2.51747 11.597 2.19664 11.2824 1.93779 10.9161C1.49006 10.2825 1.24976 9.52577 1.25 8.75V6.25C1.25 5.25544 1.64509 4.30161 2.34835 3.59835C3.05161 2.89509 4.00544 2.5 5 2.5H11.25C12.2446 2.5 13.1984 2.89509 13.9017 3.59835C14.6049 4.30161 15 5.25544 15 6.25V8.75C15 9.74456 14.6049 10.6984 13.9017 11.4017C13.1984 12.1049 12.2446 12.5 11.25 12.5H8.125Z" fill="#C3D82E"/>
        </g>
        <defs>
          <clipPath id="clip0_8995_56022">
            <rect width="20" height="20" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
}
 
export default ReservationLinked;
