import { v4 as uuidv4 } from "uuid";

import RestMiddleware from "./RestMiddleware";

import {
  Configuration, LundapadelBookingApi, LundapadelClubApi,
  LundapadelGameApi,
  LundapadelMainApi,
  LundapadelMetricApi,
  LundapadelProfileApi,
  LundapadelTournamentApi,
  LundapadelYookassaApi,
  RequestOpts,
} from "../integration-api/server-rest-lundapadelApi";
import { StateProvider } from "./StateProvider";
import { setLoader } from "../models/loader/loader";
import { cleanupCurrenProfile } from "@/models/auth/auth";
import { ErrorUtils } from "@/utils/utils";

type Constructor<T> = new (...args: any[]) => T;

export interface RestClientOptions {
  loaderId?: string;
  hideNotification?: boolean;
  responseType?: string;
}

interface RequestOptsExtra extends RequestOpts {
  requestId: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
function ServerApiWrapper<T extends Constructor<{}>>(
  Base: T
) {
  return class extends Base {
    requestHideNotification = false;
    requestLoaderId?: string;
    requestResponseType?: string;
    requestsOptions: {
      [key: string]: {
        [key: string]: string | boolean;
      };
    } = {};

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(...args: any[]) {
      super(...args);
    }

    withOptions(options: RestClientOptions = {}) {
      const { loaderId, responseType, hideNotification } = options;
      this.requestLoaderId = loaderId;
      this.requestResponseType = responseType;
      this.requestHideNotification = hideNotification === true;

      return this;
    }

    loader(loading: boolean, context: RequestOptsExtra) {
      const { loaderId } = this.requestsOptions[context.requestId];
      StateProvider.getStore().dispatch(
        setLoader({ id: loaderId as string, loading })
      );
    }

    protected handleRequestOptionsBefore(context: RequestOptsExtra) {
      const requestId = uuidv4();
      context.requestId = requestId;
      this.requestsOptions[requestId] = {};
      if (this.requestLoaderId) {
        this.requestsOptions[requestId].loaderId = this.requestLoaderId;
        this.requestLoaderId = undefined;
      }
      if (this.requestResponseType) {
        this.requestsOptions[requestId].responseType = this.requestResponseType;
        this.requestResponseType = undefined;
      }
      if (this.requestHideNotification) {
        this.requestsOptions[requestId].hideNotification =
          this.requestHideNotification;
        this.requestHideNotification = false;
      }
    }

    protected handleRequestOptionsAfter(context: RequestOptsExtra) {
      delete this.requestsOptions[context.requestId];
    }

    protected applyRequestsOptionsToInit(
      context: RequestOptsExtra,
      fetchInit: any
    ) {
      const { loaderId, responseType } =
        this.requestsOptions[context.requestId];
      fetchInit.loaderId = loaderId;
      if (responseType) {
        fetchInit.responseType = responseType;
      }
    }

    protected async request(context: RequestOptsExtra): Promise<any> {
      this.handleRequestOptionsBefore(context);

      const { url, init: fetchInit } = await (this as any).createFetchParams(
        context
      );

      this.applyRequestsOptionsToInit(context, fetchInit);

      this.loader(true, context);
      const response = await (this as any).fetchApi(url, fetchInit);
      this.loader(false, context);

      // get request options
      const requestOptions = this.requestsOptions[context.requestId] || {};

      if (response.status >= 200 && response.status < 300) {
        if (response.status === 204) {
          response.json = function () {
            return {};
          };
        }
        this.handleRequestOptionsAfter(context);
        return response;
      }

      switch (response.status) {
        case 403: {
          window.location.pathname = '/auth';
          ErrorUtils.handleErrorMessage('Профиль игрока был заблокирован')
          break
        }
        case 401: {
          StateProvider.getStore().dispatch(cleanupCurrenProfile());

          response.json = () => {
            return {
              map: () => undefined,
            };
          };
          this.handleRequestOptionsAfter(context);
          break;
        }
        case 400:
        case 404:
          throw response
          .clone()
          .json();
        case 405: {
          if (!requestOptions.hideNotification) {
            // TODO: show notification
          }
          break;
        }

        case 500:
        case 503:
        case 504: {
          if (!requestOptions.hideNotification) {
            ErrorUtils.handleErrorMessage('Приложение временно недоступно');
          }
          break;
        }
      }

      this.handleRequestOptionsAfter(context);
      throw response;
    }
  };
}

interface ServerApiProps<T> {
  withOptions: (options: RestClientOptions) => T;
}

class ServerApi {
  mainApi: ServerApiProps<LundapadelMainApi> & LundapadelMainApi;
  profileApi: ServerApiProps<LundapadelProfileApi> & LundapadelProfileApi;
  tournamentApi: ServerApiProps<LundapadelTournamentApi> & LundapadelTournamentApi;
  clubApi: ServerApiProps<LundapadelClubApi> & LundapadelClubApi;
  gameApi: ServerApiProps<LundapadelGameApi> & LundapadelGameApi;
  metricApi: ServerApiProps<LundapadelMetricApi> & LundapadelMetricApi;
  yookassaApi: ServerApiProps<LundapadelYookassaApi> & LundapadelYookassaApi;
  bookingApi: ServerApiProps<LundapadelBookingApi> & LundapadelBookingApi;

  constructor(configuration: Configuration) {
    this.mainApi = new (ServerApiWrapper(LundapadelMainApi))(configuration);
    this.profileApi = new (ServerApiWrapper(LundapadelProfileApi))(configuration);
    this.tournamentApi = new (ServerApiWrapper(LundapadelTournamentApi))(configuration);
    this.clubApi = new (ServerApiWrapper(LundapadelClubApi))(configuration);
    this.gameApi = new (ServerApiWrapper(LundapadelGameApi))(configuration);
    this.metricApi = new (ServerApiWrapper(LundapadelMetricApi))(configuration);
    this.yookassaApi = new (ServerApiWrapper(LundapadelYookassaApi))(configuration);
    this.bookingApi = new (ServerApiWrapper(LundapadelBookingApi))(configuration);
  }
}

export const getBasePath = () => {
  if(process.env.REACT_APP_ENVIROMENT === 'production') {
    return 'https://app.lundapadel.ru';
  }
  if(process.env.REACT_APP_ENVIROMENT === 'staging') {
    return 'https://app.lundapadel-stag.gridnine.com';
  }
  if(process.env.REACT_APP_ENVIROMENT === 'development') {
    return 'https://app.lundapadel-dev.gridnine.com';
  }
  return '';
}

const RestClient: ServerApi = new ServerApi(
  new Configuration({
    basePath:  `${getBasePath()}/api`,
    headers: {
      accept: 'application/json'
    },
    middleware: [new RestMiddleware()]
  })
);

export default RestClient;
