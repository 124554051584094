import { useDetectCapacitorKeyboard } from '@/hooks/useDetectCapacitorKeyboard';
import { useEffect, useRef } from 'react';

// Когда появляется экранная клавиатура, wrapperRef.current прокручиваетcя к элементу, на котором фокус

export default function useScrollToActiveInput<T extends HTMLElement>(
  offset: number = 0
) {
  const wrapperRef = useRef<T>(null);
  const initialHeight = useRef('');

  const { isKeyboardOpen, keyboardHeight } = useDetectCapacitorKeyboard();

  useEffect(() => {
    const focused = document.activeElement;
    if (!wrapperRef.current) return;
    if (initialHeight.current === undefined)
      initialHeight.current = wrapperRef.current.style.height;
    if (isKeyboardOpen && focused) {
      const top = Math.floor(focused.getBoundingClientRect().top);
      wrapperRef.current.style.height = `calc(100% - ${keyboardHeight}px + ${offset}px + var(--sa-bottom))`;
      wrapperRef.current.scrollTo({ top, behavior: 'smooth' });
    } else {
      wrapperRef.current.style.height = initialHeight.current;
    }
  }, [isKeyboardOpen, keyboardHeight]);

  return { wrapperRef };
}
