/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GameCreateSuggestParameters,
    GameCreateSuggestParametersFromJSON,
    GameCreateSuggestParametersFromJSONTyped,
    GameCreateSuggestParametersToJSON,
} from './GameCreateSuggestParameters';

/**
 * 
 * @export
 * @interface GameCreateSuggestRequest
 */
export interface GameCreateSuggestRequest {
    /**
     * 
     * @type {GameCreateSuggestParameters}
     * @memberof GameCreateSuggestRequest
     */
    parameters?: GameCreateSuggestParameters;
}

export function GameCreateSuggestRequestFromJSON(json: any): GameCreateSuggestRequest {
    return GameCreateSuggestRequestFromJSONTyped(json, false);
}

export function GameCreateSuggestRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameCreateSuggestRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : GameCreateSuggestParametersFromJSON(json['parameters']),
    };
}

export function GameCreateSuggestRequestToJSON(value?: GameCreateSuggestRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': GameCreateSuggestParametersToJSON(value.parameters),
    };
}

