import Headling from '@/components/Headling/Headling';
import styles from './ActiveReservationCard.module.css';
import CourtIcon from '@/static/images/icons/CourtIcon';
import CalendarIcon from '@/static/images/icons/CalendarIcon';
import LocationIconOutline from '@/static/images/icons/LocationIconOutline';
import WalletIconOutline from '@/static/images/icons/WalletIconOutline';
import { HTMLAttributes, useCallback, useMemo } from 'react';
import { GameCreateSuggestParameters, ReservationInfo } from "@/integration-api/server-rest-lundapadelApi";
import formatter, { getPlatform } from '@/utils/utils';
import { calculateEndTime } from '@/pages/application/create/components/MatchResultSettings/utils';
import { TIME_FORMAT_HH_MM } from '@/utils/constants';
import ReservationLinked from '../Icon/components/ReservationLinked';
import cn from 'classnames';
import Button, { ButtonVariants } from '../Button/Button';
import CrossIcon from '@/static/images/icons/CrossIcon';

type ActiveReservationCardProps = {
  type: 'list' | 'detailed' | 'profile';
  reservation: ReservationInfo;
  expired: boolean;
  createSuggest?: (params: GameCreateSuggestParameters) => Promise<void>
} & HTMLAttributes<HTMLDivElement>

const ActiveReservationCard = ({ type, reservation, expired, createSuggest, onClick, ...rest }: ActiveReservationCardProps) => {
  const renderCourts = useMemo(() => {
    return reservation.courts?.map((court, i) => (
      <div className={styles['info-item']} key={i}>
        <div className={styles['stroked']}>
          <CourtIcon/>
        </div>
        <span>{court.name}</span>
      </div>
    ));
  }, [reservation.courts]);

  const handleClick = useCallback(() => {
    createSuggest?.({
      accept: true,
      clubUid: reservation.club?.uid,
      ids: reservation.ids,
      metrics: { system: getPlatform() }
    })
  }, [createSuggest, reservation.club?.uid, reservation.ids]);

  return (  
    <div className={cn(styles['wrapper'], {[styles['expired']]: expired })} {...rest}>
      <div onClick={onClick}>
        {type === 'detailed' ?
          <div className={styles['link-icon-wrapper']}>
            <ReservationLinked className={styles['link-icon']}/>
          </div> : null
        }
        {type === 'detailed' ?
          <Headling appearence='medium-bold' className={styles['headling']}>
            Бронирование
          </Headling> : null
        }
        <div className={styles['info']}>
          <div className={styles['info-item']}>
            <div>
              <CalendarIcon/> 
            </div>
            <span>
              {formatter.formatDateToDayDateMonth(reservation.plannedDate)} | {formatter.formatDate(reservation.plannedDate!, TIME_FORMAT_HH_MM)} 
              {calculateEndTime(formatter.formatDate(reservation.plannedDate!, TIME_FORMAT_HH_MM), reservation.duration ?? 0 )}
            </span>
          </div>
          <div className={styles['info-item']}>
            <div>
              <LocationIconOutline/>
            </div>
            <span>{reservation.club?.displayName}</span>
          </div>
          {renderCourts}
          <div className={styles['info-item']}>
            <div className={styles['stroked']}>
              <WalletIconOutline/>
            </div>
            <div>Оплачено: <span className={styles['price']}>{reservation.price} ₽ </span></div>
          </div>
          {type !== 'profile' ? 
            <div className={cn(styles['info-item'], { [styles['no-reservation']]: !reservation?.relatedEntityUid } )}>
              <ReservationLinked/>
              <span>{reservation?.relatedEntityUid ? 'Есть игра в приложении' : 'Нет игры в приложении'}</span>
            </div> : null
          }
      </div>
        {type === 'profile' ?
          <Button
            className={styles['button']}
            variant={ButtonVariants.YELLOW}
            onClick={handleClick}
          >
            <span className={styles['icon']}>
              <CrossIcon/>
            </span>
            <span>Создать игру к этому бронированию</span>
          </Button> : null
        }
      </div>
    </div>
  );
}
 
export default ActiveReservationCard;
