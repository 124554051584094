import LocationIcon from '@/static/images/icons/LocationIcon';
import Button, { ButtonVariants } from '../Button/Button';
import styles from './TournamentCard.module.css';
import CommnentIcon from '@/static/images/icons/CommentIcon';
import ClockIcon from '@/static/images/icons/ClockIcon';
import formatter from '@/utils/utils';
import { TIME_FORMAT_HH_MM } from '@/utils/constants';
import { useNavigate } from 'react-router-dom';
import { calculateEndTime } from '@/pages/application/create/components/MatchResultSettings/utils';
import { TournamentInviteCardProps } from './TournamentCard.interfaces';
import MedalTournamentIcon from '@/static/images/icons/MedalTournamentIcon';

const TournamentInviteCard = ({ invite, acceptInvite, rejectInvite, ...props }: TournamentInviteCardProps) => {
  const navigate = useNavigate();

  return ( 
    <div {...props} className={styles['card-wrapper-invite']}>
      <div className={styles['card']} onClick={() => navigate(`/tournament/${invite.relatedEntityUid}`)}>
        <div className={styles['invitation']}>Вас пригласили в турнир!</div>
        <div className={styles['card-field']}>
          <div className={styles['icon']}>
            <ClockIcon/>
          </div>
          <span>
            {formatter.formatDateToDayDateMonth(invite.plannedDate)} | {formatter.formatDate(invite.plannedDate!, TIME_FORMAT_HH_MM)} 
            {calculateEndTime(formatter.formatDate(invite.plannedDate!, TIME_FORMAT_HH_MM), 0)}
          </span>
        </div>
        <div className={styles['card-field']}>
          <div className={styles['icon']}>
            <LocationIcon />
          </div>
          <span>{invite.clubName}</span>
        </div>
        <div className={styles['card-field']}>
          <div>
            <MedalTournamentIcon/>
          </div>
          {invite.displayMinGrade === invite.displayMaxGrade ?
            <span>{invite.displayMinGrade}</span> :
            <span>{invite.displayMinGrade}...{invite.displayMaxGrade}</span>
          }
        </div>
        {invite.description ?
          <div className={styles['card-field']}>
            <div>
              <CommnentIcon />
            </div>
            <div className={styles['comment']}>{invite.description}</div>
          </div>
          : null
        }
      </div>
      <div className={styles['invite-actions']}>
        <Button
          variant={ButtonVariants.YELLOW}
          onClick={acceptInvite}
          className={styles['button']}
        >
          Принять
        </Button>
        <Button
          variant={ButtonVariants.GRAYBLUE_OUTLINED}
          onClick={rejectInvite}
          className={styles['button']}
        >
          Отклонить
        </Button>
      </div>
    </div>
  );
}
 
export default TournamentInviteCard;
