/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReservationInfo,
    ReservationInfoFromJSON,
    ReservationInfoFromJSONTyped,
    ReservationInfoToJSON,
} from './ReservationInfo';
import {
    TourGameInfo,
    TourGameInfoFromJSON,
    TourGameInfoFromJSONTyped,
    TourGameInfoToJSON,
} from './TourGameInfo';

/**
 * 
 * @export
 * @interface ReservationDetails
 */
export interface ReservationDetails {
    /**
     * 
     * @type {ReservationInfo}
     * @memberof ReservationDetails
     */
    info?: ReservationInfo;
    /**
     * 
     * @type {TourGameInfo}
     * @memberof ReservationDetails
     */
    tourGame?: TourGameInfo;
}

export function ReservationDetailsFromJSON(json: any): ReservationDetails {
    return ReservationDetailsFromJSONTyped(json, false);
}

export function ReservationDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'info': !exists(json, 'info') ? undefined : ReservationInfoFromJSON(json['info']),
        'tourGame': !exists(json, 'tourGame') ? undefined : TourGameInfoFromJSON(json['tourGame']),
    };
}

export function ReservationDetailsToJSON(value?: ReservationDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'info': ReservationInfoToJSON(value.info),
        'tourGame': TourGameInfoToJSON(value.tourGame),
    };
}

