/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseMetricView,
    BaseMetricViewFromJSON,
    BaseMetricViewFromJSONTyped,
    BaseMetricViewToJSON,
} from './BaseMetricView';

/**
 * 
 * @export
 * @interface GameCreateSuggestParameters
 */
export interface GameCreateSuggestParameters {
    /**
     * 
     * @type {string}
     * @memberof GameCreateSuggestParameters
     */
    clubUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GameCreateSuggestParameters
     */
    accept?: boolean;
    /**
     * 
     * @type {BaseMetricView}
     * @memberof GameCreateSuggestParameters
     */
    metrics?: BaseMetricView;
    /**
     * 
     * @type {Array<number>}
     * @memberof GameCreateSuggestParameters
     */
    ids?: Array<number>;
}

export function GameCreateSuggestParametersFromJSON(json: any): GameCreateSuggestParameters {
    return GameCreateSuggestParametersFromJSONTyped(json, false);
}

export function GameCreateSuggestParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameCreateSuggestParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clubUid': !exists(json, 'clubUid') ? undefined : json['clubUid'],
        'accept': !exists(json, 'accept') ? undefined : json['accept'],
        'metrics': !exists(json, 'metrics') ? undefined : BaseMetricViewFromJSON(json['metrics']),
        'ids': !exists(json, 'ids') ? undefined : json['ids'],
    };
}

export function GameCreateSuggestParametersToJSON(value?: GameCreateSuggestParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clubUid': value.clubUid,
        'accept': value.accept,
        'metrics': BaseMetricViewToJSON(value.metrics),
        'ids': value.ids,
    };
}

