import styles from './OwnershipChangeRequestsSection.module.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { acceptRequestChangeGameOwner, acceptRequestChangeTournamentOwner, requestChangeOwnerLoadList } from './service';
import { OwnerChangeRequestView, RelatedEntityType } from '@/integration-api/server-rest-lundapadelApi';
import { ErrorUtils, parseRelatedEntity } from '@/utils/utils';
import Headling from '@/components/Headling/Headling';
import CountCircle from '@/components/CountCircle/CountCircle';
import OwnershipChangeRequestsCard from '../OwnershipChangeRequestsCard/OwnershipChangeRequestsCard';
import { HandleOwnershipChangeArgs } from './OwnershipChangeRequestsSection.interfaces';
import { Link, useNavigate } from 'react-router-dom';
import SeeMore from '@/components/SeeMore/SeeMore';

const OwnershipChangeRequestsSection = () => {
  const navigate = useNavigate();

  const [requests, setRequests] = useState<Array<OwnerChangeRequestView>>();
  const [total, setTotal] = useState<number>(0);

  async function loadRequests() {
    try {
      const { result } = await requestChangeOwnerLoadList({ limit: 2, offset: 0 })
      setRequests(result?.infos);
      setTotal(result?.total ?? 0);
    } catch(err) {
      ErrorUtils.handleErrorMessage(err);
    }
  }

  const handleAction = useCallback(async ({ accepted, entityType, relatedEntityUid }: HandleOwnershipChangeArgs) => {
    try {
      switch(entityType) {
        case RelatedEntityType.GAME: {
          await acceptRequestChangeGameOwner({ accepted, relatedEntityUid });
          if(accepted) {
            navigate(`/game/${relatedEntityUid}`);
          } else {
            await loadRequests();
          }
          break;
        }
        case RelatedEntityType.TOURNAMENT : {
          await acceptRequestChangeTournamentOwner({ accepted, relatedEntityUid });
          if(accepted) {
            navigate(`/tournament/${relatedEntityUid}`);
          } else {
            await loadRequests();
          }
          break;
        }
        default: {
          break;
        }
      }
    } catch(err) {
      ErrorUtils.handleErrorMessage(err);
    }
  }, [loadRequests, navigate]);

  const renderRequests = useMemo(() => {
    return requests?.map(request => (
      <OwnershipChangeRequestsCard
        request={request}
        key={request.sendDate?.toString()}
        handleAction={handleAction}
        onClick={() => navigate(`/${parseRelatedEntity(request.relatedEntityType)}/${request.relatedEntityUid}`)}
      />
    ))
  }, [requests, handleAction]);

  useEffect(() => {
    loadRequests();
  }, []);

  return requests?.length ? (  
    <section className={styles['requests']}>
      <Headling appearence="big-normal" className={styles['headling']}>
        <div className={styles['headling-text']}>
          <span>Запросы</span>
          <CountCircle children={total}/>
        </div>
        <Link to={`/requests`}>
          <SeeMore>Все</SeeMore>
        </Link>
      </Headling>
      <div className={styles['content']}>
        {renderRequests}
      </div>
    </section>
  ) : null;
}
 
export default OwnershipChangeRequestsSection;
