import styles from './TimePicker.module.css';
import { InputHTMLAttributes, memo } from "react";

type TimePickerProps = {
  displayTime?: string;
} & InputHTMLAttributes<HTMLInputElement>

const TimePicker = ({ displayTime, className, ...props }: TimePickerProps) => {
  return (  
    <label htmlFor={props.id}>                  
      <span>{displayTime?.slice(0, 5) ?? '--:--'}</span>
      <input {...props} type='time' className={`${className ?? ''} ${styles['timepicker']}`.trim()}/>
    </label>
  );
}
 
export default memo(TimePicker);
