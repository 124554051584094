import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { useEffect, useRef, useState } from 'react';

export function useDetectCapacitorKeyboard() {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const keyboardHeight = useRef<number>();

  useEffect(() => {
    if (Capacitor.isPluginAvailable('Keyboard')) {
      Keyboard.addListener('keyboardWillShow', (info) => {
        if (keyboardHeight.current === undefined)
          keyboardHeight.current = info.keyboardHeight;
        setIsKeyboardOpen(true);
      });

      Keyboard.addListener('keyboardWillHide', () => {
        setIsKeyboardOpen(false);
      });
    }
    return () => {
      if (Capacitor.isPluginAvailable('Keyboard')) {
        Keyboard.removeAllListeners().catch((err) => console.log(err));
      }
    };
  }, []);

  return { isKeyboardOpen, keyboardHeight: keyboardHeight.current };
}
