/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReservationLoadListResult,
    ReservationLoadListResultFromJSON,
    ReservationLoadListResultFromJSONTyped,
    ReservationLoadListResultToJSON,
} from './ReservationLoadListResult';

/**
 * 
 * @export
 * @interface ReservationLoadListResponse
 */
export interface ReservationLoadListResponse {
    /**
     * 
     * @type {ReservationLoadListResult}
     * @memberof ReservationLoadListResponse
     */
    result?: ReservationLoadListResult;
    /**
     * 
     * @type {Array<object>}
     * @memberof ReservationLoadListResponse
     */
    messages?: Array<object>;
}

export function ReservationLoadListResponseFromJSON(json: any): ReservationLoadListResponse {
    return ReservationLoadListResponseFromJSONTyped(json, false);
}

export function ReservationLoadListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationLoadListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : ReservationLoadListResultFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function ReservationLoadListResponseToJSON(value?: ReservationLoadListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ReservationLoadListResultToJSON(value.result),
        'messages': value.messages,
    };
}

