import { AcceptChangeOwnerParam, RequestLoadListParam } from "@/integration-api/server-rest-lundapadelApi";
import RestClient from "@/integration/RestClient";

export function acceptRequestChangeGameOwner(parameters: AcceptChangeOwnerParam) {
  return RestClient.gameApi.acceptRequestChangeGameOwner({
    acceptRequestChangeGameOwnerRequest: {
      parameters
    }
  });
}

export function acceptRequestChangeTournamentOwner(parameters: AcceptChangeOwnerParam) {
  return RestClient.tournamentApi.acceptRequestChangeTournamentOwner({
    acceptRequestChangeTournamentOwnerRequest: {
      parameters
    }
  });
}

export function requestChangeOwnerLoadList(parameters: RequestLoadListParam) {
  return RestClient.tournamentApi.requestChangeOwnerLoadList({
    requestChangeOwnerLoadListRequest: {
      parameters
    }
  })
}
