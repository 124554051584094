import styles from './OwnershipChangeRequestsCard.module.css';
import { RelatedEntityType } from "@/integration-api/server-rest-lundapadelApi";
import TournamentCup from '@/components/Icon/components/TournamentCup';

export function parseRelatedEntityToJSX(entity?: RelatedEntityType): JSX.Element | null {
  switch(entity) {
    case RelatedEntityType.GAME: {
      return <span>игры</span>
    }
    case RelatedEntityType.TOURNAMENT:
      return (
        <div className={styles['entity-tournament']}>
          <span>турнира</span>
          <TournamentCup iconSize='middle'/>
        </div>
      );
    default: {
      return null;
    }
  }
}
