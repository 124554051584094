import React from 'react';
import Icon, { IconProps } from '../Icon';

interface CheckProps extends IconProps {};

const EmptyChat: React.FC<CheckProps> = (props) => {
  return (
    <Icon {...props}>
      <svg
        width="66"
        height="51"
        viewBox="0 0 66 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M63.9824 32.1719C64.2966 32.1719 64.6102 32.1456 64.9199 32.0934C64.8995 30.38 64.2099 28.7426 62.9983 27.531C61.7867 26.3194 60.1493 25.6298 58.4359 25.6094C58.3837 25.9191 58.3574 26.2327 58.3574 26.5469C58.3574 29.6485 60.8808 32.1719 63.9824 32.1719ZM58.2798 38.7344C58.3073 38.5683 58.3281 38.4004 58.341 38.2311C58.3519 38.0881 58.3574 37.9422 58.3574 37.7978C58.3574 34.6961 55.8341 32.1728 52.7324 32.1728C52.4183 32.1728 52.1047 32.199 51.7949 32.2513C51.8155 33.9646 52.5054 35.6019 53.7172 36.8133C54.9289 38.0248 56.5665 38.7142 58.2798 38.7344Z"
          fill="#3A4F62"
        />
        <path
          d="M59.3421 31.1872C58.7308 30.5793 58.2461 29.8561 57.9162 29.0596C57.5863 28.2631 57.4176 27.409 57.4199 26.5469C57.4199 26.2529 57.4395 25.9593 57.4785 25.668C56.0546 25.8608 54.7332 26.5155 53.7171 27.5316C52.701 28.5476 52.0463 29.869 51.8535 31.293C52.1449 31.254 52.4385 31.2344 52.7324 31.2344C53.5946 31.232 54.4486 31.4007 55.2451 31.7306C56.0416 32.0606 56.7648 32.5452 57.3728 33.1565C57.9841 33.7645 58.4687 34.4877 58.7986 35.2842C59.1286 36.0807 59.2973 36.9347 59.2949 37.7969C59.2949 37.965 59.2885 38.1347 59.2759 38.3014C59.2661 38.4268 59.2528 38.5517 59.236 38.6761C60.6601 38.4832 61.9815 37.8285 62.9976 36.8123C64.0137 35.7962 64.6684 34.4748 64.8613 33.0508C64.57 33.0898 64.2764 33.1094 63.9824 33.1094C63.1203 33.1117 62.2662 32.943 61.4697 32.6131C60.6732 32.2832 59.95 31.7985 59.3421 31.1872Z"
          fill="#3A4F62"
        />
        <g clip-path="url(#clip0_775_105864)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M28.5707 14.4115C28.7107 16.7173 29.3869 19.8344 30.6051 23.1812C31.8232 26.5281 33.3069 29.3452 34.6797 31.1959C36.3783 33.4878 37.2841 33.4074 37.0949 30.3042C36.9554 27.9999 36.2792 24.8829 35.0611 21.536C33.8429 18.1892 32.3587 15.3705 30.9858 13.5198C29.1479 11.0395 28.3999 11.5999 28.5707 14.4115ZM38.0317 20.4392C36.7309 16.8653 35.1032 13.7264 33.4816 11.5394C31.8733 9.37059 29.4243 7.12308 26.7412 8.63959L12.7896 16.5178C9.71605 18.253 7.69599 21.7811 8.42705 25.6737C8.72737 27.272 9.16689 29.0906 9.81046 30.8588C10.4367 32.5378 11.1966 34.1627 12.0821 35.7159C14.0207 39.1628 17.8271 40.5379 21.2865 39.8628L36.9881 36.7928C40.0087 36.2034 40.4352 32.8942 40.2707 30.1923C40.1047 27.4679 39.332 24.0116 38.0317 20.4392Z"
            fill="#3A4F62"
          />
        </g>
        <defs>
          <clipPath id="clip0_775_105864">
            <rect
              width="40"
              height="38"
              fill="white"
              transform="matrix(0.34202 0.939693 -0.938103 0.346357 35.6475 0)"
            />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
};
  
export default EmptyChat;
  