function HomeLogo() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 13.8489C1 14.4085 1.4154 14.8821 2.09709 14.8821C2.42728 14.8821 2.71487 14.7099 2.9705 14.4839L4.19541 13.4506V23.4708C4.19541 25.0636 5.14338 26 6.76239 26H21.1737C22.7927 26 23.7407 25.0636 23.7407 23.4708V13.3861L25.0401 14.4839C25.2958 14.7099 25.5834 14.8821 25.9136 14.8821C26.5313 14.8821 27 14.4946 27 13.8811C27 13.5152 26.8615 13.2246 26.5846 12.9986L23.7407 10.577V6.00281C23.7407 5.51848 23.4318 5.20636 22.9525 5.20636H21.5145C21.0459 5.20636 20.7263 5.51848 20.7263 6.00281V8.01544L15.5072 3.58119C14.5912 2.80627 13.4408 2.80627 12.5141 3.58119L1.4154 12.9986C1.13847 13.2354 1 13.5475 1 13.8489ZM17.0623 16.8301C17.0623 16.3243 16.7427 16.0014 16.2421 16.0014H11.7579C11.2679 16.0014 10.9271 16.3243 10.9271 16.8301V23.8905H7.31626C6.64523 23.8905 6.28308 23.5138 6.28308 22.8358V11.6748L13.558 5.51848C13.8456 5.26018 14.1757 5.26018 14.4633 5.51848L21.653 11.6102V22.8358C21.653 23.5138 21.2909 23.8905 20.6198 23.8905H17.0623V16.8301Z"
        fill="#B9B9B9"
      />
    </svg>
  );
}

export default HomeLogo;
