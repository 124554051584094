import ExitFilled from '@/components/Icon/components/ExitIcon/ExitFilled';
import styles from './SelectedFormField.module.css';

interface SelectedFormFieldProps {
  title?: string;
  content?: string | React.ReactElement | Array<React.ReactElement>;
  showClear?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  clearHandler?: VoidFunction;
}

function SelectedFormField({ title, content, showClear, onClick, clearHandler }: SelectedFormFieldProps) {
  return (
    <div className={styles.wrapper}>
      {showClear ? <ExitFilled className={styles.clear} onClick={clearHandler}/> : null}
      <div className={styles.flexItem} onClick={onClick}>
        <span className={styles.title}>{title}</span>
        <span className={styles.dates}>{content}</span>
      </div>
    </div>
  );
}

export default SelectedFormField;
