import styles from './EntityWaitingListModal.module.css';
import { GameInviteView, InvitationStatus, TournamentInviteView, TournamentMemberView } from '@/integration-api/server-rest-lundapadelApi';
import BasePageWrapper from '../BasePageWrapper/BasePageWrapper';
import Header from '../Header/Header';
import { useMemo, useState } from 'react';
import CountCircle from '../CountCircle/CountCircle';
import GameJoiningCard from '../GameJoiningCard/GameJoiningCard';
import { GameActions, HandleGameActionParams } from '@/pages/application/game/GameStatusContent/GameStatusContent.interfaces';
import Headling from '../Headling/Headling';
import PlayerItem from '../PlayerItem/PlayerItem';
import { ItemType } from '../PlayerItem/PlayerItem.interfaces';
import TournamentParticipants from '@/pages/application/tournament/components/TournamentParticipants/TournamentParticipants';
import { useAppSelector } from '@/hooks/hooks';
import EditListIcon from '@/static/images/icons/EditListIcon';
import cn from 'classnames';

type EntityWaitingListModalProps = {
  visible: boolean;
  owner: boolean;
  onClose: VoidFunction;
  queue?: Array<TournamentMemberView>;
  invitations: Array<GameInviteView> | Array<TournamentInviteView>;
  entity: 'game' | 'tournament';
  loading: boolean;
  handleInviteAction?: (action: Extract<GameActions, 'acceptInvite' | 'removeInvite'>, playerData?: HandleGameActionParams) => Promise<void>;
  deleteFromWaitingList?: (playerData: { displayName?: string, uid?: string }) => void;
  clearInviteFromTournament?: (playerData: { displayName?: string, uid?: string }) => void;
}

const EntityWaitingListModal = ({ visible, onClose, queue, entity, loading, invitations, owner, handleInviteAction, deleteFromWaitingList, clearInviteFromTournament }: EntityWaitingListModalProps) => {
  const current = useAppSelector(state => state.auth.currentProfile);
  const [editMode, setEditMode] = useState(false);

  const renderQueue = useMemo(() => {
    if(entity === 'tournament') {
      const queuePlacement = queue?.findIndex(inv => inv.player?.uid === current.identity?.uid) ?? -1;
      let subHeadling: JSX.Element | null = null;
      if(queuePlacement > -1) {
        subHeadling = <div className={styles['subheadling']}>Перед вами игроков: {queuePlacement}</div>;
      }
      if(queuePlacement === 0) {
        subHeadling = <div className={styles['subheadling']}>Вы следующий, следите за уведомлениями!</div>;
      }
      return queue?.length ? (
        <div className={styles['group-wrapper']}>
          <div className={styles['headling-wrapper']}>
            <Headling appearence='medium-bold'  className={cn(styles['headling'], {[styles['headling-type-edit']]: owner})}>
              <span>Лист ожидания: {queue?.length ?? 0}</span>
              {owner ?            
                <span onClick={() => setEditMode(!editMode)}>
                  <EditListIcon/>
                </span> : null
              }
            </Headling>
            {subHeadling}
          </div>       
          <TournamentParticipants
            participants={queue ?? []}
            queue
            editMode={editMode}
            setEditMode={state => setEditMode(state)}
            kickPlayer={player => deleteFromWaitingList?.({ uid: player?.uid, displayName: player?.displayName })}
            owner={owner}
          />
        </div>
      ) : null;
    }
    return null;
  }, [queue, entity, owner, current.identity?.uid, editMode, deleteFromWaitingList]);

  const renderJoiningUsers = useMemo(() => {
    if (invitations.length) {
      const suitableInvites = invitations?.filter(inv => inv.sender?.uid === inv.player?.uid && inv.invitationStatus === InvitationStatus.SENT);
      if(owner) {
        return suitableInvites?.length ? (
          <div className={styles['group-wrapper']}>
            <Headling appearence='medium-bold' className={styles['headling']}>
              <span>Заявки:</span>
              <CountCircle>{suitableInvites.length}</CountCircle>
            </Headling>
            <div className={`${styles['players-list']} ${styles['requests-list']}`}>
              {suitableInvites?.map(inv => (
                <GameJoiningCard
                  key={inv.player?.uid}
                  player={inv.player ?? {}}
                  loading={loading}
                  acceptHandler={playerUid => handleInviteAction?.('acceptInvite', { playerUid })}
                  declineHandler={playerUid => handleInviteAction?.('removeInvite', { playerUid })}
                />
              ))}
            </div>            
          </div>
        ) : null;
      } else {
        if(suitableInvites.length) {
          return (
            <div className={styles['group-wrapper']}>
              <Headling appearence='medium-bold' className={styles['headling']}>
                <span>Отправили заявку: {suitableInvites.length}</span>
              </Headling>
              <div className={styles['players-list']}>
                {suitableInvites.map((inv, i) => (
                  <PlayerItem
                    key={inv.player?.uid}
                    player={inv.player ?? {}}
                    type={ItemType.WAITING_LIST}
                    withTopBorder={!!i}
                  />
                ))}
              </div>
            </div>
          )
        }
      }
    } else {
      return null;
    }
  }, [owner, invitations, entity, handleInviteAction, loading]);

  const renderInvitedPlayers = useMemo(() => {
    if(invitations.length) {
      const invitedPlayers = invitations.filter(inv => (inv.player?.uid !== inv.sender?.uid) && inv.invitationStatus === InvitationStatus.SENT);
      if(invitedPlayers.length) {
        return (
          <div className={styles['group-wrapper']}>
            <Headling appearence='medium-bold'  className={cn(styles['headling'], {[styles['headling-type-edit']]: owner})}>
              <span>Приглашенные: {invitedPlayers.length}</span>
              {owner ? 
                <span onClick={() => setEditMode(!editMode)}>
                  <EditListIcon/>
                </span> : null
              }
            </Headling>
            <div className={styles['players-list']}>
              {invitedPlayers.map((inv, i) => (
                <PlayerItem
                  key={inv.player?.uid}
                  player={inv.player ?? {}}
                  type={ItemType.WAITING_LIST}
                  withTopBorder={!!i}
                  editMode={editMode}
                  deleteHandler={playerData => entity === 'game' ? deleteFromWaitingList?.(playerData) : clearInviteFromTournament?.(playerData)}
                />
              ))}
            </div>
          </div>
        )
      }
    }
    return null;
  }, [owner, entity, invitations, editMode, deleteFromWaitingList, clearInviteFromTournament]);

  return visible ? (  
    <BasePageWrapper>
      <Header handleClick={onClose} className={styles['header']}>
        {entity === 'game' ? 'Ожидают подтверждения' : 'Лист ожидания'}
      </Header>
      <div className={styles['wrapper']}>
        {renderQueue}
        {renderJoiningUsers}
        {renderInvitedPlayers}
      </div>
    </BasePageWrapper>
  ) : null;
}
 
export default EntityWaitingListModal;
