import { ConfirmationModalProps } from '@/components/ConfirmationModal/ConfirmationModal.interfaces';
import styles from './CancelReservationModal.module.css';
import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal';
import ModalBallIcon from '@/static/images/icons/ModalBallIcon';
import ModalArrowIcon from '@/static/images/icons/ModalArrowIcon';

type CancelReservationModalProps = {
  loading: boolean;
  handler: VoidFunction;
} & ConfirmationModalProps;

const CancelReservationModal = ({ loading, handler, ...props }: CancelReservationModalProps) => {
  return (  
    <ConfirmationModal {...props}>
      <div className={styles['question']}>
        <span>Вы уверены, что хотите отменить бронирование?</span>
      </div>
      <div className={styles['description']}>
        Для возврата средств необходимо предоставить чек об оплате по  номеру <a style={{ color: 'var(--blue)', textDecoration: 'underline' }} href='tel:+79154205000'>89154205000</a> (WhatsApp). 
        <hr style={{ opacity: 0 }}/>
        Денежные средства вернутся в течение 7 дней после отправки чека об оплате.
      </div>
      <button className={styles['button']} onClick={handler} disabled={loading}>
        <div className={styles['button-inner']}>
          <ModalBallIcon/>
          <span>Отменить бронь</span>
          <ModalArrowIcon/>
        </div>
      </button>
    </ConfirmationModal>
  );
}
 
export default CancelReservationModal;
