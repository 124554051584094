import { FC, useEffect, useState } from 'react';
import { ProfileSectionProps } from './ProfileSection.interfaces';
import UserAvatar from '@/components/UserAvatar/UserAvatar';
import styles from './ProfileSection.module.css';
import shareIcon from '@/static/images/icons/share-icon.svg';
import bellIcon from '@/static/images/bell-icon.svg';
import { useAppSelector } from '@/hooks/hooks';
import ballIcon from '@/static/images/icons/notification-ball-icon.svg';
import { CurrentPlayerProfileView } from '@/integration-api/server-rest-lundapadelApi';
import { usePlayerAvatar } from '@/hooks/usePlayerAvatar';
import { Share } from '@capacitor/share';
import { existNotification, notificationLoadList } from '../Notifications/service';
import { FetchErrorMessage } from '@/types/types';
import MedalIconSmall from '@/static/images/icons/MedalIconSmall';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';
import { shareProfileMetric } from '../../service';
import { createImgSrc, getPlatform } from '@/utils/utils';

const ProfileSection: FC<ProfileSectionProps> = (props): JSX.Element => {
  const { onOpenNotifications } = props;
  const [notificationsExist, setNotificationsExist] = useState(false);
  const { currentProfile } = useAppSelector(state => state.auth);
  const avatar = usePlayerAvatar<CurrentPlayerProfileView>(currentProfile);

  const onExistingNotifications = async () => {
    try {
      const { result } = await existNotification();
      setNotificationsExist(!!result?.exist);
    } catch(err: unknown) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    }
  }

  useEffect(() => {
    onExistingNotifications();
  }, []);
  
  const copyIdHandler = async () => {
    if(navigator.onLine) {
      try {        
        const {value} = await Share.canShare()
        if (value) {
          await Share.share({
            url: `${createImgSrc.origin}/profile/${currentProfile?.playerId}`
          })
          await shareProfileMetric({ system: getPlatform() });
          PopupMessage.open('Ссылка на профиль скопирована', 'success');
        } else {
          if (navigator.share !== undefined) {
            const shareData: ShareData = {
              // @ts-ignore удалить, когда бек добавит поле
              url: currentProfile?.telegramUsername ? `https://t.me/${currentProfile?.telegramUsername}` : `${window.location.href}profile/${currentProfile?.playerId}`,
            };
            await navigator.share(shareData);
            await shareProfileMetric({ system: getPlatform() });
            PopupMessage.open('Ссылка на профиль скопирована', 'success');
          } else {
            PopupMessage.open('Функционал не поддерживается');
          }
        }
      } catch(err) {
        console.log(err);
      }
    }
  }

  return (
    <>
      <section className={styles['profile']}>
        <div className={styles['top']}>
          <div className={styles['user-wrapper']}>
            <UserAvatar 
              className={styles['avatar']} 
              avatarUid={avatar} 
              nameInitials={currentProfile?.nameInitials}/>
            <div className={styles['user']}>
              <span style={{ overflow: 'hidden'}}>
                {currentProfile.displayName && currentProfile?.displayName.split(' ')?.[1]?.length >= 20 ?
                  currentProfile.displayName.split(' ')?.[1].substring(0, 17) + '...' 
                  : currentProfile.displayName?.split(' ')?.[1]
                }
              </span>
                
              <img src={shareIcon} alt="иконка поделиться" onClick={copyIdHandler}/>
            </div>
          </div>
          <button onClick={onOpenNotifications} className={styles['notification']}>
            {notificationsExist ? <img src={ballIcon} alt="иконка мячика" className={styles['notification-exist']}/> : null}
            <img src={bellIcon} alt="иконка колокольчика" />
          </button>
        </div>
        {currentProfile?.rankingPlace ?
          <div className={styles['achievment']}>
            <MedalIconSmall />
            <div className={styles['congratulation']}>
              У вас {currentProfile?.rankingPlace} место в рейтинге! Поздравляем!
            </div>
          </div> : null}
      </section>
    </>
  );
};

export default ProfileSection;
