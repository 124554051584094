/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CityClubsLoadListRequest,
    CityClubsLoadListRequestFromJSON,
    CityClubsLoadListRequestToJSON,
    CityClubsLoadListResponse,
    CityClubsLoadListResponseFromJSON,
    CityClubsLoadListResponseToJSON,
    ClubLoadListRequest,
    ClubLoadListRequestFromJSON,
    ClubLoadListRequestToJSON,
    ClubLoadListResponse,
    ClubLoadListResponseFromJSON,
    ClubLoadListResponseToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    LoadListClubCitiesResponse,
    LoadListClubCitiesResponseFromJSON,
    LoadListClubCitiesResponseToJSON,
} from '../models';

export interface LundapadelClubApiCityClubsLoadListOperationRequest {
    cityClubsLoadListRequest: CityClubsLoadListRequest;
}

export interface LundapadelClubApiClubLoadListOperationRequest {
    clubLoadListRequest: ClubLoadListRequest;
}

/**
 * 
 */
export class LundapadelClubApi extends runtime.BaseAPI {

    /**
     * Load list of clubs grouping by city
     */
    async cityClubsLoadListRaw(requestParameters: LundapadelClubApiCityClubsLoadListOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CityClubsLoadListResponse>> {
        if (requestParameters.cityClubsLoadListRequest === null || requestParameters.cityClubsLoadListRequest === undefined) {
            throw new runtime.RequiredError('cityClubsLoadListRequest','Required parameter requestParameters.cityClubsLoadListRequest was null or undefined when calling cityClubsLoadList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/club/load-list-city-group`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CityClubsLoadListRequestToJSON(requestParameters.cityClubsLoadListRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CityClubsLoadListResponseFromJSON(jsonValue));
    }

    /**
     * Load list of clubs grouping by city
     */
    async cityClubsLoadList(requestParameters: LundapadelClubApiCityClubsLoadListOperationRequest, initOverrides?: RequestInit): Promise<CityClubsLoadListResponse> {
        const response = await this.cityClubsLoadListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load list of clubs
     */
    async clubLoadListRaw(requestParameters: LundapadelClubApiClubLoadListOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClubLoadListResponse>> {
        if (requestParameters.clubLoadListRequest === null || requestParameters.clubLoadListRequest === undefined) {
            throw new runtime.RequiredError('clubLoadListRequest','Required parameter requestParameters.clubLoadListRequest was null or undefined when calling clubLoadList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/club/load-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClubLoadListRequestToJSON(requestParameters.clubLoadListRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClubLoadListResponseFromJSON(jsonValue));
    }

    /**
     * Load list of clubs
     */
    async clubLoadList(requestParameters: LundapadelClubApiClubLoadListOperationRequest, initOverrides?: RequestInit): Promise<ClubLoadListResponse> {
        const response = await this.clubLoadListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load list of club cities
     */
    async loadListClubCitiesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadListClubCitiesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/club/cities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadListClubCitiesResponseFromJSON(jsonValue));
    }

    /**
     * Load list of club cities
     */
    async loadListClubCities(initOverrides?: RequestInit): Promise<LoadListClubCitiesResponse> {
        const response = await this.loadListClubCitiesRaw(initOverrides);
        return await response.value();
    }

}
