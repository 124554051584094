import { CalculatedGameStatus } from "@/integration-api/server-rest-lundapadelApi";
import { gameCardSubtype } from "../../myGames/components/CompletedContent/utils";
import { GameListCardSubtype } from "@/components/GameListCard/GameListCard.interfaces";

export function applyReservationGameSubtype(status?: CalculatedGameStatus): GameListCardSubtype | undefined {
  switch(status) {
    case CalculatedGameStatus.FINISHED:
    case CalculatedGameStatus.SCORE_UNDEFINED:
    case CalculatedGameStatus.CANCELED:
      return gameCardSubtype(status);
    case CalculatedGameStatus.AFTER_GAME:
      return 'blue';
    case CalculatedGameStatus.STARTED:
      return 'orange';
    default:
      return;
  }
}
