import { SubscriptionRemoveParam } from '@/integration-api/server-rest-lundapadelApi';
import RestClient from '@/integration/RestClient';

export async function fetchSubscription() {
  try {
    const { result } = await RestClient.profileApi.loadListSubscription();
    return result;
  } catch (err) {
    throw err;
  }
}

export function deleteSubscription(parameters: SubscriptionRemoveParam) {
  return RestClient.profileApi.removeSubscription({
    removeSubscriptionRequest: {
      parameters
    }
  });
}
