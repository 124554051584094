import { useAppSelector } from '@/hooks/hooks';
import { PlayerProfileInfo } from '@/integration-api/server-rest-lundapadelApi';
import PlayerItem from '@/components/PlayerItem/PlayerItem';
import { ItemType } from '@/components/PlayerItem/PlayerItem.interfaces';
import PlayerRating from '../PlayerRating/PlayerRating';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { RatingPlayersListProps } from './RatingPlayersList.interface';
import { parseSAValues } from '@/utils/utils';

export default function RatingPlayersList(props: RatingPlayersListProps) {
  const { playersList, playerListWrapRef } = props;

  const currentUser = useAppSelector((state) => state.auth.currentProfile ?? {});

  const Row = ({ index, style }: any) => {
    if (playersList) {
      const player: PlayerProfileInfo = playersList[index];
      return (
        <PlayerItem 
          id={player.uid}
          type={ItemType.RATING} 
          player={player} 
          playerPlacement={player.ratingPosition} 
          key={player.uid} 
          isActive={player.uid === currentUser.identity?.uid}
          customRating={<PlayerRating player={player} />}
          style={{...style}}
        />
    )} else {
      return null;
    };
  }

  return (
    <AutoSizer>
      {({width}: any) => (  
        playersList && (
        <List
          overscanCount={5}
          key={playersList.length}
          height={document.documentElement.clientHeight - 208 - parseSAValues()}
          itemCount={playersList.length}
          itemSize={57}
          width={width}
          className='players-list'
          // @ts-ignore
          ref={playerListWrapRef}         
        >
          {Row}
        </List>) as any
      )}
    </AutoSizer>
  )
}
