/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Reference,
    ReferenceFromJSON,
    ReferenceFromJSONTyped,
    ReferenceToJSON,
} from './Reference';

/**
 * 
 * @export
 * @interface AvailableCourt
 */
export interface AvailableCourt {
    /**
     * 
     * @type {Reference}
     * @memberof AvailableCourt
     */
    court?: Reference;
    /**
     * 
     * @type {number}
     * @memberof AvailableCourt
     */
    number?: number;
    /**
     * 
     * @type {string}
     * @memberof AvailableCourt
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AvailableCourt
     */
    imageUid?: string;
    /**
     * 
     * @type {number}
     * @memberof AvailableCourt
     */
    price?: number;
}

export function AvailableCourtFromJSON(json: any): AvailableCourt {
    return AvailableCourtFromJSONTyped(json, false);
}

export function AvailableCourtFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableCourt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'court': !exists(json, 'court') ? undefined : ReferenceFromJSON(json['court']),
        'number': !exists(json, 'number') ? undefined : json['number'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'imageUid': !exists(json, 'imageUid') ? undefined : json['imageUid'],
        'price': !exists(json, 'price') ? undefined : json['price'],
    };
}

export function AvailableCourtToJSON(value?: AvailableCourt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'court': ReferenceToJSON(value.court),
        'number': value.number,
        'description': value.description,
        'imageUid': value.imageUid,
        'price': value.price,
    };
}

