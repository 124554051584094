import { PopupMessage } from '@/components/PopupMessage/PopupMessage';
import { useAppDispatch, useAppSelector } from '@/hooks/hooks';
import { PlayerProfileView } from '@/integration-api/server-rest-lundapadelApi';
import { profileActions } from '@/models/profile/profile';
import { fetchProfileInfo } from '@/models/profile/service';
import { getProfileStat } from '@/models/profile/thunks';
import { FetchErrorMessage } from '@/types/types';
import DBManager from '@/utils/DBManager/DBManager';
import { ErrorUtils } from '@/utils/utils';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

export type ProfileOutletContext = {
  profile: PlayerProfileView | null;
  setProfile: Dispatch<SetStateAction<PlayerProfileView | null>>;
}

function ProfilePage() {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector((state) => state.auth.currentProfile);
  const [profile, setProfile] = useState<PlayerProfileView | null>(null);

  const profileUid = useMemo(() => {
    if (id && currentUser) {
      return id === 'me' ? currentUser?.identity?.uid : id;
    }
    return undefined;
  }, [currentUser, id]);

  useEffect(() => {
    if (navigator.onLine) {
      try {
        profile && DBManager.create('profile', { ...profile }, profile?.identity?.uid!)
      } catch (e) {
        if (e instanceof Error) console.log(e.message);
      }
    } else {
      currentUser && DBManager.read('profile', id === 'me' ? (currentUser?.identity?.uid) as string : id as string).then((profileInfo: any) => {
        setProfile(profileInfo)
      }).catch((err) => console.log(err)
      )
    }
  }, [profile, currentUser])

  const getProfileData = useCallback(
    async (profileUid: string) => {
      try {
        const profileData = await fetchProfileInfo(profileUid);
        setProfile(profileData.result!);
        await dispatch(getProfileStat(profileUid));
      } catch (err) {
        ErrorUtils.handleErrorMessage(err);
      }
    }, [dispatch]);
    
  useEffect(() => {
    return () => {
      dispatch(profileActions.reset())
    }
  }, [dispatch])

  useEffect(() => {
    if (profileUid) {
      getProfileData(profileUid);
    }
  }, [getProfileData, profileUid]);

  return (
    <Outlet context={{ profile, setProfile } satisfies ProfileOutletContext }/>
  );
}

export default ProfilePage;
