import { useCallback, useEffect, useState } from "react";
import { castGameRoundToTournamentRound, TournamentResultsNewContext } from "../utils";
import { isGameRoundView, TournamentResultsActiveLineup, TournamentResultsNewContextWrapperProps, TournamentResultsSteps } from "./TournamentResultsNew.interfaces";
import { gameRoundLoad, loadTournamentRound } from "../service";
import { TournamentRoundView } from "@/integration-api/server-rest-lundapadelApi";
import { ErrorUtils } from "@/utils/utils";

const TournamentResultsNewContextWrapper = ({ tournament, loading, tournamentType, setLoading, children }: TournamentResultsNewContextWrapperProps) => {
  const [step, setStep] = useState<TournamentResultsSteps>('rounds');
  const [round, setRound] = useState<TournamentRoundView>(); 
  const [roundNumber, setRoundNumber] = useState<number>(0);
  const [activeLineup, setActiveLineup] = useState<TournamentResultsActiveLineup>();
  const [roundCounter, setRoundCounter] = useState<number>(0)

  const updateStep = useCallback((step: TournamentResultsSteps) => {
    setStep(step);
  }, []);

  const updateRoundNumber = useCallback((roundNumber: number) => {
    setRoundNumber(roundNumber);
  }, []);

  const updateActiveLineup = useCallback((lineup?: TournamentResultsActiveLineup) => {
    if(lineup) {
      setActiveLineup({
        indexes: { ...activeLineup?.indexes, ...lineup.indexes },
        lineup: { ...activeLineup?.lineup, ...lineup.lineup } 
      });
    } else {
      setActiveLineup(undefined);
    }
  }, [activeLineup]);

  const updateRound = useCallback((round?: TournamentRoundView) => {
    setRound(round);
  }, []);

  const updateRoundCounter = useCallback((value: number) => {
    setRoundCounter(value);
  }, [])

  useEffect(() => {
    if(tournament.identity?.uid && (step === "lineups" || step === "courts")) {
      setLoading(true)
      const request = tournamentType === 'mini' ? 
      gameRoundLoad({
        gameUid: tournament.identity.uid,
        roundNumber
      }) : 
      loadTournamentRound({
        tournamentUid: tournament.identity.uid,
        roundNumber
      })
      request
        .then(({ result }) => {
          if(result) {
            isGameRoundView(result) ?
              setRound(castGameRoundToTournamentRound(result)) :
              setRound(result);
          }
        })
        .catch(err => ErrorUtils.handleErrorMessage(err))
        .finally(() => setLoading(false))
    }
  }, [tournament.identity?.uid, step, roundNumber, tournamentType]);

  return (
    <TournamentResultsNewContext.Provider 
      value={{ 
        tournament, step, round, roundNumber, roundCounter, activeLineup, loading, tournamentType, setLoading, updateStep, updateRoundNumber, updateActiveLineup, updateRound, updateRoundCounter 
      }}> 
      {children}
    </TournamentResultsNewContext.Provider>
  )
}

export default TournamentResultsNewContextWrapper;
