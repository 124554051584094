/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OwnerChangeRequestView,
    OwnerChangeRequestViewFromJSON,
    OwnerChangeRequestViewFromJSONTyped,
    OwnerChangeRequestViewToJSON,
} from './OwnerChangeRequestView';

/**
 * 
 * @export
 * @interface CreateRequestChangeGameOwnerRequest
 */
export interface CreateRequestChangeGameOwnerRequest {
    /**
     * 
     * @type {OwnerChangeRequestView}
     * @memberof CreateRequestChangeGameOwnerRequest
     */
    parameters?: OwnerChangeRequestView;
}

export function CreateRequestChangeGameOwnerRequestFromJSON(json: any): CreateRequestChangeGameOwnerRequest {
    return CreateRequestChangeGameOwnerRequestFromJSONTyped(json, false);
}

export function CreateRequestChangeGameOwnerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRequestChangeGameOwnerRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : OwnerChangeRequestViewFromJSON(json['parameters']),
    };
}

export function CreateRequestChangeGameOwnerRequestToJSON(value?: CreateRequestChangeGameOwnerRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': OwnerChangeRequestViewToJSON(value.parameters),
    };
}

