/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalDate,
    LocalDateFromJSON,
    LocalDateFromJSONTyped,
    LocalDateToJSON,
} from './LocalDate';
import {
    Reference,
    ReferenceFromJSON,
    ReferenceFromJSONTyped,
    ReferenceToJSON,
} from './Reference';

/**
 * 
 * @export
 * @interface PaymentAmountCalculateParameters
 */
export interface PaymentAmountCalculateParameters {
    /**
     * 
     * @type {Reference}
     * @memberof PaymentAmountCalculateParameters
     */
    club?: Reference;
    /**
     * 
     * @type {LocalDate}
     * @memberof PaymentAmountCalculateParameters
     */
    date?: LocalDate;
    /**
     * 
     * @type {string}
     * @memberof PaymentAmountCalculateParameters
     */
    time?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentAmountCalculateParameters
     */
    duration?: number;
    /**
     * 
     * @type {Array<Reference>}
     * @memberof PaymentAmountCalculateParameters
     */
    courts?: Array<Reference>;
}

export function PaymentAmountCalculateParametersFromJSON(json: any): PaymentAmountCalculateParameters {
    return PaymentAmountCalculateParametersFromJSONTyped(json, false);
}

export function PaymentAmountCalculateParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentAmountCalculateParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'club': !exists(json, 'club') ? undefined : ReferenceFromJSON(json['club']),
        'date': !exists(json, 'date') ? undefined : LocalDateFromJSON(json['date']),
        'time': !exists(json, 'time') ? undefined : json['time'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'courts': !exists(json, 'courts') ? undefined : ((json['courts'] as Array<any>).map(ReferenceFromJSON)),
    };
}

export function PaymentAmountCalculateParametersToJSON(value?: PaymentAmountCalculateParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'club': ReferenceToJSON(value.club),
        'date': LocalDateToJSON(value.date),
        'time': value.time,
        'duration': value.duration,
        'courts': value.courts === undefined ? undefined : ((value.courts as Array<any>).map(ReferenceToJSON)),
    };
}

