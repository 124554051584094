import { EntryTournamentParam, GameRoundDeleteParam, GameRoundView, GameTournamentFinishParam, LundapadelGameApiGameRoundLoadRequest, LundapadelTournamentApiLoadListTournamentRoundRequest, LundapadelTournamentApiLoadTournamentRoundRequest, LundapadelTournamentApiLoadTournamentTableRequest, LundapadelTournamentApiTournamentFinishRequest, LundapadelTournamentApiTournamentRoundCreateRequest, OwnerChangeRequestView, TournamentCancelParam, TournamentClassicSaveTableParam, TournamentInviteAcceptParam, TournamentInviteRejectParam, TournamentKickPlayerParam, TournamentRoundDeleteParam, TournamentRoundView, TournamentStartParam, TournamentView } from "@/integration-api/server-rest-lundapadelApi";
import RestClient from "@/integration/RestClient";
import { getPlatform } from "@/utils/utils";

export function loadTournament(uid: string) {
  return RestClient.tournamentApi.loadTournament({ uid });
}

export function entryTournament(parameters: EntryTournamentParam) {
  return RestClient.tournamentApi.entryTournament({
    entryTournamentRequest: {
      parameters
    }
  })
}

export function saveTournament(parameters: TournamentView) {
  return RestClient.tournamentApi.saveTournament({
    saveTournamentRequest: {
      parameters
    }
  })
}

export function loadTournamentRound(params: LundapadelTournamentApiLoadTournamentRoundRequest) {
  return RestClient.tournamentApi.loadTournamentRound(params);
}

export function saveTournamentRound(parameters: TournamentRoundView) {
  return RestClient.tournamentApi.saveTournamentRound({
    saveTournamentRoundRequest: {
      parameters
    }
  });
}

export function loadListTournamentRound(params: LundapadelTournamentApiLoadListTournamentRoundRequest) {
  return RestClient.tournamentApi.loadListTournamentRound(params);
}

export function tournamentRoundCreate(params: LundapadelTournamentApiTournamentRoundCreateRequest) {
  return RestClient.tournamentApi.tournamentRoundCreate(params);
}

export function deleteTournamentRound(parameters: TournamentRoundDeleteParam ) {
  return RestClient.tournamentApi.deleteTournamentRound({
    deleteTournamentRoundRequest: {
      parameters
    }
  })
}

export function tournamentFinish(params: LundapadelTournamentApiTournamentFinishRequest) {
  return RestClient.tournamentApi.tournamentFinish(params);
}

export function loadTournamentTable(params: LundapadelTournamentApiLoadTournamentTableRequest) {
  return RestClient.tournamentApi.loadTournamentTable(params)
}

export function ownerKickPlayerTournament(parameters: TournamentKickPlayerParam) {
  return RestClient.tournamentApi.ownerKickPlayerTournament({
    ownerKickPlayerTournamentRequest: {
      parameters
    }
  })
}

export function cancelTournament(parameters: TournamentCancelParam) {
  return RestClient.tournamentApi.cancelTournament({
    cancelTournamentRequest: {
      parameters
    }
  })
}

export function saveClassicTournamentTable(parameters: TournamentClassicSaveTableParam) {
  return RestClient.tournamentApi.saveClassicTournamentTable({
    saveClassicTournamentTableRequest: {
      parameters
    }
  })
}

export function startTournament(parameters: TournamentStartParam) {
  return RestClient.tournamentApi.startTournament({
    startTournamentRequest: {
      parameters
    }
  })
}

export function loadTournamentStatistics(tournamentUid: string) {
  return RestClient.tournamentApi.loadTournamentStatistics({ tournamentUid })
}

export function loadTournamentRoundsPreview(tournamentUid: string) {
  return RestClient.tournamentApi.loadTournamentRoundsPreview({ tournamentUid });
}

export function rejectInviteForTournament(parameters: TournamentInviteRejectParam) {
  return RestClient.tournamentApi.rejectInviteForTournament({
    rejectInviteForTournamentRequest: {
      parameters
    }
  })
}

export function acceptInviteForTournament(parameters: TournamentInviteAcceptParam) {
  return RestClient.tournamentApi.acceptInviteForTournament({
    acceptInviteForTournamentRequest: {
      parameters
    }
  })
}

//minitournament
export function gameRoundLoad(parameters: LundapadelGameApiGameRoundLoadRequest) {
  return RestClient.gameApi.gameRoundLoad(parameters)
}

export function gameRoundSave(parameters: GameRoundView) {
  return RestClient.gameApi.gameRoundSave({
    gameRoundSaveRequest: {
      parameters
    }
  })
}

export function gameRoundCreate(gameUid: string, lockToken: string) {
  return RestClient.gameApi.gameRoundCreate({ gameUid, lockToken, system: getPlatform() })
}

export function gameRoundDelete(parameters: GameRoundDeleteParam) {
  return RestClient.gameApi.gameRoundDelete({
    gameRoundDeleteRequest: {
      parameters
    }
  })
}

export function loadListGameRound(gameUid: string) {
  return RestClient.gameApi.loadListGameRound({ gameUid })
}

export function loadGameTournamentTable(gameUid: string) {
  return RestClient.gameApi.loadGameTournamentTable({ gameUid })
}

export function gameTournamentFinish(parameters: GameTournamentFinishParam) {
  return RestClient.gameApi.gameTournamentFinish({
    gameTournamentFinishRequest: {
      parameters
    }
  })
}

//ownership
export function createRequestChangeTournamentOwner(parameters: OwnerChangeRequestView) {
  return RestClient.tournamentApi.createRequestChangeTournamentOwner({
    createRequestChangeTournamentOwnerRequest: {
      parameters
    }
  });
}

export function coachLoadList() {
  return RestClient.profileApi.coachLoadList({
    limit: 10000,
    offset: 0
  });
}
