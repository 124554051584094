import styles from './RequestsPage.module.css';
import Header from "@/components/Header/Header";
import FullScreenPage from "@/components/Layouts/FullScreenPage";
import { useCallback, useEffect, useMemo, useState } from "react";
import { acceptRequestChangeGameOwner, acceptRequestChangeTournamentOwner, requestChangeOwnerLoadList } from "../home/components/OwnershipChangeRequestsSection/service";
import { OwnerChangeRequestView, RelatedEntityType } from "@/integration-api/server-rest-lundapadelApi";
import { ErrorUtils, parseRelatedEntity } from "@/utils/utils";
import { PuffLoader } from "react-spinners";
import OwnershipChangeRequestsCard from '../home/components/OwnershipChangeRequestsCard/OwnershipChangeRequestsCard';
import { useNavigate } from 'react-router-dom';
import { HandleOwnershipChangeArgs } from '../home/components/OwnershipChangeRequestsSection/OwnershipChangeRequestsSection.interfaces';

const RequestsPage = () => {
  const navigate = useNavigate();
  const [requests, setRequests] = useState<Array<OwnerChangeRequestView>>();
  const [loading, setLoading] = useState(false);

  async function loadRequests() {
    try {
      setLoading(true);
      const { result } = await requestChangeOwnerLoadList({ limit: 50, offset: 0 })
      setRequests(result?.infos);
    } catch(err) {
      ErrorUtils.handleErrorMessage(err);
    } finally {
      setLoading(false);
    }
  }

  const handleAction = useCallback(async ({ accepted, entityType, relatedEntityUid }: HandleOwnershipChangeArgs) => {
    try {
      switch(entityType) {
        case RelatedEntityType.GAME: {
          await acceptRequestChangeGameOwner({ accepted, relatedEntityUid });
          if(accepted) {
            navigate(`/game/${relatedEntityUid}`);
          } else {
            await loadRequests();
          }
          break;
        }
        case RelatedEntityType.TOURNAMENT : {
          await acceptRequestChangeTournamentOwner({ accepted, relatedEntityUid });
          if(accepted) {
            navigate(`/tournament/${relatedEntityUid}`);
          } else {
            await loadRequests();
          }
          break;
        }
        default: {
          break;
        }
      }
    } catch(err) {
      ErrorUtils.handleErrorMessage(err);
    }
  }, [loadRequests, navigate]);

  const renderRequests = useMemo(() => {
    return requests?.map(request => (
      <OwnershipChangeRequestsCard
        request={request}
        key={request.sendDate?.toString()}
        handleAction={handleAction}
        onClick={() => navigate(`/${parseRelatedEntity(request.relatedEntityType)}/${request.relatedEntityUid}`)}
      />
    ))
  }, [requests, handleAction]);

  useEffect(() => {
      loadRequests();
  }, []);

  return (  
    <FullScreenPage
      header={
        <Header>
          Запросы
        </Header>
      }
    >
      {loading ?
        <PuffLoader color='#4EB857' cssOverride={{margin: 'auto'}} /> :
        <section className={styles['content']}>
          {renderRequests}
        </section>
      }
    </FullScreenPage>
  );
}
 
export default RequestsPage;
