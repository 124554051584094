import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import Header from '@/components/Header/Header';
import {
  ChangeEvent,
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import InputLabel from '@/components/InputLabel/InputLabel';
import styles from './createSubscriptionPage.module.css';
import SelectedFormField from '../components/SelectedFormField/SelectedFormField';
import dayjs from 'dayjs';
import EmptyFormField from '../components/EmptyFormField/EmptyFormField';
import ArrowIcon from '@/static/images/icons/ArrowIcon';
import RatingRange from '@/components/RatingRange/RatingRange';
import { useAppSelector } from '@/hooks/hooks';
import {
  ClubView,
  DayOfWeek,
  Grade,
  MetricScreenView,
  SubscriptionEditButton,
  SubscriptionEntity,
  SubscriptionView,
} from '@/integration-api/server-rest-lundapadelApi';
import MultiClubFiled from '../components/MultiClubFiled/MultiClubFiled';
import { createPageReducer, FormValuesState } from '../reducer';
import CalendarModal from '../components/CalendarModal/CalendarModal';
import DaysModal from '../components/DaysModal/DaysModal';
import {
  daysEnum,
  daysLabesShort,
  daySorter,
} from '../components/DaysModal/utils';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Button from '@/components/Button/Button';
import { gradeToIndex, indexToGrade } from '@/components/RatingRange/utils';
import { createSubscription } from './service';
import ClubsModal from '../components/ClubsModal/ClubsModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';
import FullscreenLoader from '@/components/FullscreenLoader/FullscreenLoader';
import { ErrorUtils, getPlatform } from '@/utils/utils';
import { createValidDays } from '../utils';
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch';
import InnerPadding from '@/components/InnerPadding/InnerPadding';
import TimePicker from '../components/TimePicker/TimePicker';

const modalVisibleDefault = {
  clubs: false,
  date: false,
  days: false,
};

const initFormFalues: FormValuesState = {
  clubs: [],
  startDate: undefined,
  endDate: undefined,
  days: [],
  startTime: '00:00:00',
  endTime: '23:59:00',
  minGrade: Grade._3,
  maxGrade: Grade._5,
  onlyTournaments: false,
};

const CreateSubscriptionPage = () => {
  // hooks
  const current = useAppSelector((state) => state.auth.currentProfile);
  const grades = useAppSelector((state) => state.auth.currentProfile.grades);
  const navigate = useNavigate();
  const { search } = useLocation();

  // states
  const [state, dispatch] = useReducer(createPageReducer, initFormFalues);
  const [modalVisible, setModalVisible] = useState(modalVisibleDefault);
  const [isLoading, setIsLoading] = useState(false);
  const [validDays, setValidDays] = useState<Array<DayOfWeek>>(daysEnum);

  // handlers
  const handleClubItemClick = (clubUid: string, club?: ClubView) => {
    let clubs: ClubView[] = [];
    if (state.clubs?.some((club) => club.reference?.uid === clubUid)) {
      clubs = state.clubs.filter(
        (selectedClub) => selectedClub.reference?.uid !== clubUid
      );
    } else if (club) {
      clubs = [...state.clubs!, club];
    }
    dispatch({
      type: 'update',
      payload: { clubs },
    });
  };

  const setSelectedClubs = (clubs: ClubView[]) => {
    dispatch({
      type: 'update',
      payload: { clubs },
    });
  };

  const handleDateChange = (dates: Date[] | undefined) => {
    if (dates) {
      dispatch({
        type: 'update',
        payload: {
          startDate: dayjs(dates[0]).format(
            'YYYY-MM-DD'
          ) as `${number}-${number}-${number}`,
          endDate: dayjs(dates[1]).format(
            'YYYY-MM-DD'
          ) as `${number}-${number}-${number}`,
        },
      });
    }
  };

  const handleDaySelect = useCallback(
    (day: DayOfWeek) => {
      let days: DayOfWeek[] = [];
      if (state.days?.includes(day)) {
        days = state.days
          .filter((stateDay) => stateDay !== day)
          .sort((a, b) => daySorter[a] - daySorter[b]);
      } else {
        days = [...state.days!, day].sort(
          (a, b) => daySorter[a] - daySorter[b]
        );
      }

      dispatch({
        type: 'update',
        payload: { days },
      });
    },
    [dispatch, state.days]
  );

  const restoreSelectedDays = (days: DayOfWeek[]) => {
    dispatch({
      type: 'update',
      payload: { days },
    });
  };

  const handleTimeFieldChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      ({ target }) => {
        dispatch({
          type: 'update',
          payload: {
            [target.name]: target.value
              ? `${target.value}:00`
              : initFormFalues[target.name as keyof FormValuesState],
          },
        });
      },
      [dispatch]
    );

  const handleGradeChange = ([minGrade, maxGrade]: [number, number]) => {
    dispatch({
      type: 'update',
      payload: {
        minGrade: indexToGrade(minGrade),
        maxGrade: indexToGrade(maxGrade),
      },
    });
  };

  const handleToggleSwitch = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: 'update',
        payload: { onlyTournaments: target.checked },
      });
    },
    [dispatch]
  );

  const handleSubscriptionBtnClick = () => {
    setIsLoading(true);
    const subscribeTo: Array<SubscriptionEntity> = state.onlyTournaments
      ? [SubscriptionEntity.TOURNAMENT]
      : Object.values(SubscriptionEntity);
    const request: SubscriptionView = {
      ...state,
      subscribeTo,
      metrics: {
        system: getPlatform(),
        screen: search.includes('fromList')
          ? MetricScreenView.SUBSCRIPTION_LIST
          : MetricScreenView.MAIN_SCREEN,
      },
    };
    createSubscription(request)
      .then(() => {
        PopupMessage.open('Подписка создана', 'success');
        navigate('/subscriptions');
      })
      .catch((err) => ErrorUtils.handleErrorMessage(err))
      .finally(() => setIsLoading(false));
  };

  const toggleModalVisible = (
    modal: keyof typeof modalVisibleDefault,
    state: boolean
  ) => {
    setModalVisible({ ...modalVisible, [modal]: state });
  };

  const renderDaysContent = useMemo(() => {
    return state.days?.map((day, i, array) => {
      const isValid = validDays.join(',').includes(day);
      return (
        <span key={day} className={!isValid ? styles['invalid-day'] : ''}>
          {`${daysLabesShort[day]}${i !== array.length - 1 ? ',' : ''}`}
        </span>
      );
    });
  }, [state.days, validDays]);

  // effects
  useEffect(() => {
    setValidDays(
      createValidDays({
        startDateState: state.startDate,
        endDateState: state.endDate,
      })
    );
  }, [state.days, state.startDate, state.endDate]);

  useEffect(() => {
    if (current.grades && current.grade) {
      const maxValidGrade = Object.keys(current.grades).length;
      const minGrade =
        Number(current.grade.match(/\d/g)?.[0]) - 1 < 1
          ? 1
          : Number(current.grade.match(/\d/g)?.[0]) - 1;
      const maxGrade =
        Number(current.grade.match(/\d/g)?.[0]) + 1 > maxValidGrade
          ? maxValidGrade
          : Number(current.grade.match(/\d/g)?.[0]) + 1;
      dispatch({
        type: 'update',
        payload: {
          minGrade: indexToGrade(minGrade),
          maxGrade: indexToGrade(maxGrade),
        },
      });
    }
  }, [grades, current]);

  return (
    <BasePageWrapper
      className={styles.wrap}
      contentWrapperClassName={styles.wrapContnt}
    >
      <Header>Подписка на игру</Header>

      <InputLabel label="Клуб">
        <MultiClubFiled
          selectedClub={state.clubs!}
          onClick={() => toggleModalVisible('clubs', true)}
        />
      </InputLabel>

      <InputLabel label="День игры">
        <div className={styles.flexContainer}>
          <div>
            {state.startDate || state.endDate ? (
              <SelectedFormField
                onClick={() => toggleModalVisible('date', true)}
                title="Дата"
                showClear={!!(state.endDate && state.endTime)}
                clearHandler={() =>
                  dispatch({
                    type: 'update',
                    payload: {
                      startDate: initFormFalues.startDate,
                      endDate: initFormFalues.endDate,
                    },
                  })
                }
                content={`${dayjs(state.startDate).format('DD.MM')}-${dayjs(
                  state.endDate
                ).format('DD.MM')}`}
              />
            ) : (
              <EmptyFormField onClick={() => toggleModalVisible('date', true)}>
                Дата
                <ArrowIcon direction="rigth" />
              </EmptyFormField>
            )}
          </div>

          <div>
            {state.days?.length ? (
              <SelectedFormField
                onClick={() => toggleModalVisible('days', true)}
                title="День недели"
                showClear={!!state.days.length}
                clearHandler={() =>
                  dispatch({
                    type: 'update',
                    payload: { days: initFormFalues.days },
                  })
                }
                content={renderDaysContent}
              />
            ) : (
              <EmptyFormField onClick={() => toggleModalVisible('days', true)}>
                День недели
                <ArrowIcon direction="rigth" />
              </EmptyFormField>
            )}
          </div>
        </div>
      </InputLabel>

      <InputLabel label="Введите время начала игры">
        <div className={styles.flexContainer}>
          <div>
            <SelectedFormField
              title="От"
              content={
                <TimePicker
                  name="startTime"
                  id="startTime"
                  onChange={handleTimeFieldChange}
                  displayTime={state.startTime}
                />
              }
            />
          </div>

          <div>
            <SelectedFormField
              title="До"
              content={
                <TimePicker
                  name="endTime"
                  id="endTime"
                  onChange={handleTimeFieldChange}
                  displayTime={state.endTime}
                />
              }
            />
          </div>
        </div>
      </InputLabel>

      <InputLabel label="Выберите рейтинг игры" className={styles['rating']}>
        {grades && (
          <RatingRange
            value={[
              gradeToIndex(state.minGrade!),
              gradeToIndex(state.maxGrade!),
            ]}
            onChange={handleGradeChange}
            grades={grades}
          />
        )}
      </InputLabel>

      <InnerPadding className={styles['toggle']}>
        <span>Только турниры</span>
        <ToggleSwitch
          disabled={!navigator.onLine}
          id="onlyTournamentsMain"
          name="onlyTournaments"
          onChange={handleToggleSwitch}
          checked={state.onlyTournaments}
        />
      </InnerPadding>

      {Object.values(modalVisible).every((visible) => !visible) && (
        <OversideWrapper className={styles.btnWrap}>
          <Button
            onClick={handleSubscriptionBtnClick}
            disabled={!state.clubs?.length}
          >
            Подписаться
          </Button>
        </OversideWrapper>
      )}

      {modalVisible.clubs && (
        <ClubsModal
          closeModal={() => toggleModalVisible('clubs', false)}
          handleClubSelect={handleClubItemClick}
          selectedClubs={state.clubs!}
          setSelectedClubs={setSelectedClubs}
        />
      )}

      {modalVisible.date && (
        <CalendarModal
          closeModal={() => toggleModalVisible('date', false)}
          saveDateRange={handleDateChange}
          range={
            state.startDate && state.endDate
              ? [new Date(state.startDate), new Date(state.endDate)]
              : undefined
          }
        />
      )}

      {modalVisible.days && (
        <DaysModal
          selectedDays={state.days!}
          onDaySelect={handleDaySelect}
          closeModal={() => toggleModalVisible('days', false)}
          restoreSelectedDays={restoreSelectedDays}
        />
      )}

      <FullscreenLoader loading={isLoading} />
    </BasePageWrapper>
  );
};

export default CreateSubscriptionPage;
