import React from 'react';
import Icon, { IconProps } from '../Icon';

interface CheckProps extends IconProps {};

const MapAdd: React.FC<CheckProps> = (props) => {

  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
      >
        <path
          d="M6.5 0L0.86 1.9C0.66 1.97 0.5 2.15 0.5 2.38V17.5C0.5 17.6326 0.552679 17.7598 0.646447 17.8536C0.740215 17.9473 0.867392 18 1 18C1.05 18 1.1 18 1.16 17.97L6.5 15.9L10.66 17.36C10.56 16.92 10.5 16.46 10.5 16C10.5 15.77 10.5 15.54 10.54 15.3L6.5 13.9V2L12.5 4.1V11.56C13.57 10.6 14.97 10 16.5 10C17.2 10 17.87 10.13 18.5 10.36V0.5C18.5 0.367392 18.4473 0.240215 18.3536 0.146447C18.2598 0.0526785 18.1326 0 18 0H17.84L12.5 2.1L6.5 0ZM15.5 12V15H12.5V17H15.5V20H17.5V17H20.5V15H17.5V12H15.5Z"
          fill="#C3D82E"
        />
      </svg>
    </Icon>
  );
};
  
export default MapAdd;
  