import styles from './PlannedContent.module.css';
import { FC, useCallback, useEffect, useState } from 'react';
import DateWrapper from '../DateWrapper/DateWrapper';
import { getMatchesByDate } from '../utils';
import { myTourGameLoadList } from '../srevice';
import { useAppSelector } from '@/hooks/hooks';
import {
  TourGameInfo,
  TourGameEntityType,
} from '@/integration-api/server-rest-lundapadelApi';
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch';
import InnerPadding from '@/components/InnerPadding/InnerPadding';
import { FetchErrorMessage } from '@/types/types';
import TournamentCard from '@/components/TournamentCard/TournamentCard';
import { Link } from 'react-router-dom';
import GameListCard from '@/components/GameListCard/GameListCard';
import DBManager from '@/utils/DBManager/DBManager';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';
import FullscreenLoader from '@/components/FullscreenLoader/FullscreenLoader';

const PlannedContent: FC = (): JSX.Element => {
  const [plannedGames, setPlannedGames] =
    useState<Array<[string, Array<TourGameInfo>]>>();
  const [own, setOwn] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const profileUid = useAppSelector(
    (state) => state.auth.currentProfile?.identity?.uid
  );

  const toggleSwitch = () => {
    setOwn((state) => !state);
  };

  const gameLoadListHandle = useCallback(async () => {
    setLoading(true);
    if (navigator.onLine) {
      try {
        const { result } = await myTourGameLoadList({
          ownOnly: false,
          limit: 0,
          offset: 0,
        });
        if (result) {
          const matchesByDate = getMatchesByDate(result.infos ?? []);
          setPlannedGames(matchesByDate);
          DBManager.create('tourgames', matchesByDate, 'plannedTourgames');
        }
      } catch (err: unknown) {
        if (err instanceof Promise) {
          const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> =
            await err;
          PopupMessage.open(userErrorMessage ?? errorMessage);
        }
      } finally {
        setLoading(false);
      }
    } else {
      DBManager.read('tourgames', 'plannedTourgames')
        .then((plannedTourgames) => {
          setPlannedGames(plannedTourgames);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  }, [profileUid]);

  useEffect(() => {
    if (!plannedGames && profileUid) {
      gameLoadListHandle();
    }
  }, [gameLoadListHandle, plannedGames, profileUid]);

  return (
    <>
      <div className={styles['wrapper']}>
        <div className={styles['content-wrapper']}>
          <InnerPadding>
            <div className={styles['toggle']}>
              <span>Только созданные мной игры</span>
              <ToggleSwitch
                id="content"
                onChange={toggleSwitch}
                checked={own}
              />
            </div>
          </InnerPadding>
          {plannedGames?.map(([date, games]) => {
            const filteredGames = games.filter((game) =>
              own ? game.owner?.uid === profileUid : game
            );
            return filteredGames.length ? (
              <DateWrapper key={date} date={new Date(date)}>
                {filteredGames.map((game) => {
                  if (game.type === TourGameEntityType.GAME) {
                    return <GameListCard key={game.uid} game={game} />;
                  } else {
                    return (
                      <Link to={`/tournament/${game.uid}`} key={game.uid}>
                        <TournamentCard tournament={game} restricted />
                      </Link>
                    );
                  }
                })}
              </DateWrapper>
            ) : null;
          })}
        </div>
      </div>
      <FullscreenLoader loading={loading} />
    </>
  );
};

export default PlannedContent;
