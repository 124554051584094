import { InvitationStatus } from '@/integration-api/server-rest-lundapadelApi';
import styles from './EntityWaitingList.module.css';
import Headling from '../Headling/Headling';
import ArrowIcon from '@/static/images/icons/ArrowIcon';
import { useMemo } from 'react';
import { EntityWaitingListProps } from './EntityWaitingList.interfaces';
import { useAppSelector } from '@/hooks/hooks';
import UserAvatar from '../UserAvatar/UserAvatar';
import { createImgSrc, declination } from '@/utils/utils';
import ListRestBage from '../Badge/ListRestBage/ListRestBage';

const wordArray: [string, string, string] = ['заявка', 'заявки', 'заявок'];

const EntityWaitingList = ({ entity, invitations, queue, owner, onOpen }: EntityWaitingListProps) => {
  const current = useAppSelector(state => state.auth.currentProfile);

  const renderSubheadling = useMemo(() => {
    if(entity === 'game') {
      const requestCount = invitations?.filter(inv => (inv.player?.uid === inv.sender?.uid) && inv.invitationStatus === InvitationStatus.SENT) ?? [];
      if(requestCount.length && owner === current.identity?.uid) {
        return (
          <span>В том числе {requestCount.length} {declination(requestCount.length, wordArray)}!</span>
        );
      } 
    } else {
      const queuePlacement = queue?.findIndex(inv => inv.player?.uid === current.identity?.uid) ?? -1;
      if(queuePlacement > -1) {
        if(queuePlacement === 0) {
          return (
            <span>Вы следующий, следите за уведомлениями!</span>
          );
        } else {
          return (
            <span>Перед вами игроков: {queuePlacement}</span>
          );
        }
      }
    }
    return null;
  }, [invitations, current.identity?.uid, queue, entity, owner]);

  const renderAvatars = useMemo(() => {
    const array = entity === 'game' ? invitations : queue;
    const visibleAvatars = array?.slice(0, 9).map(inv => (
      <UserAvatar
        key={inv.player?.uid}
        avatarUid={createImgSrc.playerProfile(inv?.player?.uid, inv?.player?.avatarUid)}
        nameInitials={inv.player?.nameInitials}
      />
    )) ?? [];
    if(array && array?.length > 9) {
      visibleAvatars[8] = <UserAvatar 
        isPlaceholder
        className={styles['placeholder']}
        badge={{
          element: <ListRestBage count={entity === 'game' ? invitations.length - 8 : queue?.length ?? 0 - 8}/>,
          position: 'middle-center'
        }}
      />;
    }
    return visibleAvatars;
  }, [invitations, entity, queue]);

  return (  
    <div className={styles['wrapper']} onClick={onOpen}>
      <Headling appearence='medium-bold' className={styles['headling']}>
        <span>
          {entity === 'game' ? 'Ожидают подтверждения' : 'Лист ожидания'}: {entity === 'game' ? invitations.length : (queue?.length ?? 0) + (invitations.length ?? 0)}
        </span>
        <div style={{ transform: 'rotate(-90deg)' }}>
          <ArrowIcon/>
        </div>
      </Headling>
      <div className={styles['subheadling']}>
        {renderSubheadling}
      </div>
      <div className={styles['avatars']}>
        {renderAvatars}
      </div>
    </div>
  );
}
 
export default EntityWaitingList;
