import s from './ClubAddingRequest.module.css';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import MapAddIcon from '@/components/Icon/components/MapAdd';

const ClubAddingRequest: React.FC = () => {
  const navigate = useNavigate();
  return (
      <button className={s.requestButton} onClick={(e) => {
        navigate('/profile/me/feedback?clubs');
      }}>
          <MapAddIcon />
          <div className={s.textWrapper}>
              <span className={cn(s['text'], s['mainText'])}>Не нашли свой клуб?</span>
              <span className={cn(s['text'], s['subText'])}>Добавим клуб по вашей заявке</span>
          </div>
      </button>
  )
}

export default ClubAddingRequest;
