import { FC } from 'react';
import { HeaderProps } from './Header.interfaces';
import styles from './Header.module.css';
import backIcon from '@/static/images/back-icon.svg';
import { useNavigate } from 'react-router-dom';

const Header: FC<HeaderProps> = ({
  children,
  className,
  additional,
  disabled,
  hideBackButton,
  handleClick,
  ...props
}): JSX.Element => {
  const navigation = useNavigate();

  const handleBackIconClick = () => {
    if (handleClick) {
      handleClick();
    } else {
      navigation(-1);
    }
  };

  return (
    <div {...props} className={`${className} ${styles['header']}`}>
      {hideBackButton ? (
        <div></div>
      ) : (
        <button
          disabled={disabled}
          onClick={handleBackIconClick}
          className={styles['button']}
        >
          <img src={backIcon} alt="иконка назад" />
        </button>
      )}
      <span>{children}</span>
      <span className={styles.additional}>{additional}</span>
    </div>
  );
};

export default Header;
