import Button from '@/components/Button/Button';
import HLWrapper from '@/components/HLWrapper/HLWrapper';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Search from '@/components/Search/Search';
import SearchRecent from '@/components/SearchRecent/SearchRecent';
import { useFormik } from 'formik';
import { useRef } from 'react';
import styles from './InvitePlayerSerach.module.css';
import SelectRating from '../../../create/components/SelectRating/SelectRating';
import { PlayerProfileInfo } from '@/integration-api/server-rest-lundapadelApi';
import { PuffLoader } from 'react-spinners';
import { InvitePlayerSerachProps } from './InvitePlayerSerach.interfaces';
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch';

const InvitePlayerSerach: React.FC<InvitePlayerSerachProps> = ({
  onSubmit,
  ratingRange,
  initialSlectedPlayers,
  excludedUids,
}) => {
  const playersListFetchID = useRef<NodeJS.Timeout | null>(null);

  const formik = useFormik({
    initialValues: {
      fetchParams: {
        range: ratingRange ?? [1, 2],
        textPattern: '',
        onlyPartners: false,
      },
      selectedPlayers: initialSlectedPlayers || ([] as PlayerProfileInfo[]),
      allSearchedPLayers: [] as PlayerProfileInfo[],
    },
    onSubmit: (values) => onSubmit(values.selectedPlayers),
  });

  // handlers
  const handleChangeSearch = (textPattern: string) => {
    formik.setFieldValue('fetchParams.textPattern', textPattern);
  };

  const handleChangeRatingRange = (range: [number, number]) => {
    formik.setFieldValue('fetchParams.range', range);
  };

  const handleChangeOnlyPartners = () => {
    const onlyPartners = !formik.values.fetchParams.onlyPartners;
    formik.setFieldValue('fetchParams.onlyPartners', onlyPartners);
  };

  const handleSelectPlayer = (player: PlayerProfileInfo) => {
    if (
      formik.values.selectedPlayers.some(
        (selectedPlayer) => selectedPlayer.uid === player.uid
      )
    ) {
      formik.setFieldValue('selectedPlayers', [
        ...formik.values.selectedPlayers.filter(
          (selectedPlayer) => selectedPlayer.uid !== player.uid
        ),
      ]);
    } else {
      formik.setFieldValue('selectedPlayers', [
        ...formik.values.selectedPlayers,
        player,
      ]);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <HLWrapper>
          <Search
            searchHandler={({ target }) => handleChangeSearch(target.value)}
            placeholder="Найди игрока"
          />
        </HLWrapper>

        <SelectRating
          title="Рейтинг"
          value={formik.values.fetchParams.range}
          onChange={handleChangeRatingRange}
        />

        <div className={styles.partnersSwitcher}>
          <span>Только мои напарники</span>
          <ToggleSwitch
            id="fetchParams.onlyPartners"
            onChange={handleChangeOnlyPartners}
          />
        </div>

        {playersListFetchID.current ? (
          <PuffLoader color="#4EB857" cssOverride={{ margin: 'auto' }} />
        ) : (
          <SearchRecent
            isInvitation={true}
            addPlayer={handleSelectPlayer}
            invitedPlayers={formik.values.selectedPlayers}
            fetchParams={{ excludedUids, ...formik.values.fetchParams }}
          />
        )}

        <OversideWrapper>
          <Button
            className={styles.createMatchBtn}
            disabled={!formik.values.selectedPlayers.length}
            type="submit"
          >
            Пригласить
          </Button>
        </OversideWrapper>
      </form>
    </>
  );
};

export default InvitePlayerSerach;
