import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import { useEffect } from "react";

export function useCapacitorKeyboard(el: HTMLElement | null, style?: string) {
  useEffect(() => {
    if(Capacitor.isPluginAvailable('Keyboard')) {
      const initialHeightStyle = el?.style.height ?? '';
      Keyboard.addListener('keyboardWillShow', info => {
        if(el) {
          if(style) {
            el.classList.add(style);
          }
          el.style.height = `calc(100% - ${info.keyboardHeight}px + var(--sa-bottom))`;
        }
      })
    
      Keyboard.addListener('keyboardWillHide', () => {
        if(el) {
          if(style) {
            el.classList.remove(style);
          }
          el.style.height = initialHeightStyle;
        }
      })
    }
    return () => {
      if(Capacitor.isPluginAvailable('Keyboard')) {
        Keyboard.removeAllListeners().catch(err => console.log(err))
      }
    }
  }, [el, style]);
}
