/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Reference,
    ReferenceFromJSON,
    ReferenceFromJSONTyped,
    ReferenceToJSON,
} from './Reference';

/**
 * 
 * @export
 * @interface BookedCourtView
 */
export interface BookedCourtView {
    /**
     * 
     * @type {Reference}
     * @memberof BookedCourtView
     */
    court?: Reference;
    /**
     * 
     * @type {string}
     * @memberof BookedCourtView
     */
    time?: string;
    /**
     * 
     * @type {number}
     * @memberof BookedCourtView
     */
    duration?: number;
}

export function BookedCourtViewFromJSON(json: any): BookedCourtView {
    return BookedCourtViewFromJSONTyped(json, false);
}

export function BookedCourtViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookedCourtView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'court': !exists(json, 'court') ? undefined : ReferenceFromJSON(json['court']),
        'time': !exists(json, 'time') ? undefined : json['time'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
    };
}

export function BookedCourtViewToJSON(value?: BookedCourtView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'court': ReferenceToJSON(value.court),
        'time': value.time,
        'duration': value.duration,
    };
}

