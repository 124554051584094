import { countries } from '@/static/images/countries/countries'

export const COUNTRIES_CODES = {
  '+7': countries.ru,
  // '+7': countries.kz,
  '+1': countries.us,
  '+20': countries.eg,
  '+30': countries.gr,
  '+31': countries.nl,
  '+33': countries.mf,
  '+34': countries.es,
  '+351': countries.pt,
  '+358': countries.fi,
  '+36': countries.hu,
  '+370': countries.lt,
  '+371': countries.lv,
  '+372': countries.ee,
  '+374': countries.am,
  '+375': countries.by,
  '+381': countries.rs,
  '+385': countries.hr,
  '+386': countries.si,
  '+39': countries.it,
  '+420': countries.cz,
  '+421': countries.sk,
  '+43': countries.at,
  '+44': countries.gb,
  '+45': countries.dk,
  '+46': countries.se,
  '+47': countries.sj,
  '+48': countries.pl,
  '+49': countries.de,
  '+65': countries.sg,
  '+90': countries.tr,
  '+971': countries.ae,
  '+972': countries.il,
  '+974': countries.qa,
  '+994': countries.az,
  '+995': countries.ge,
} as const;

export const COUNTRIES_FLAGS = [
  '🇷🇺 +7',
  '🇰🇿 +7',
  '🇺🇸 +1',
  '🇨🇦 +1',
  '🇪🇬 +20',
  '🇸🇸 +211',
  '🇲🇦 +212',
  '🇩🇿 +213',
  '🇹🇳 +216',
  '🇱🇾 +218',
  '🇬🇲 +220',
  '🇸🇳 +221',
  '🇲🇷 +222',
  '🇲🇱 +223',
  '🇬🇳 +224',
  '🇨🇮 +225',
  '🇧🇫 +226',
  '🇳🇪 +227',
  '🇹🇬 +228',
  '🇧🇯 +229',
  '🇲🇺 +230',
  '🇱🇷 +231',
  '🇸🇱 +232',
  '🇬🇭 +233',
  '🇳🇬 +234',
  '🇹🇩 +235',
  '🇨🇫 +236',
  '🇨🇲 +237',
  '🇨🇻 +238',
  '🇸🇹 +239',
  '🇬🇶 +240',
  '🇬🇦 +241',
  '🇨🇬 +242',
  '🇨🇩 +243',
  '🇦🇴 +244',
  '🇬🇼 +245',
  '🇮🇴 +246',
  '🇦🇨 +247',
  '🇸🇨 +248',
  '🇸🇩 +249',
  '🇷🇼 +250',
  '🇪🇹 +251',
  '🇸🇴 +252',
  '🇩🇯 +253',
  '🇰🇪 +254',
  '🇹🇿 +255',
  '🇺🇬 +256',
  '🇧🇮 +257',
  '🇲🇿 +258',
  '🇿🇲 +260',
  '🇲🇬 +261',
  '🇨🇵 +262',
  '🇿🇼 +263',
  '🇳🇦 +264',
  '🇲🇼 +265',
  '🇱🇸 +266',
  '🇧🇼 +267',
  '🇸🇿 +268',
  '🇰🇲 +269',
  '🇿🇦 +27',
  '🇸🇭 +290',
  '🇪🇷 +291',
  '🇦🇼 +297',
  '🇬🇱 +299',
  '🇬🇷 +30',
  '🇳🇱 +31',
  '🇧🇪 +32',
  '🇫🇷 +33',
  '🇪🇸 +34',
  '🇬🇮 +350',
  '🇵🇹 +351',
  '🇱🇺 +352',
  '🇮🇪 +353',
  '🇮🇸 +354',
  '🇦🇱 +355',
  '🇲🇹 +356',
  '🇨🇾 +357',
  '🇫🇮 +358',
  '🇧🇬 +359',
  '🇭🇺 +36',
  '🇱🇹 +370',
  '🇱🇻 +371',
  '🇪🇪 +372',
  '🇲🇩 +373',
  '🇦🇲 +374',
  '🇧🇾 +375',
  '🇦🇩 +376',
  '🇲🇨 +377',
  '🇺🇦 +380',
  '🇷🇸 +381',
  '🇲🇪 +382',
  '🇽🇰 +383',
  '🇭🇷 +385',
  '🇸🇮 +386',
  '🇧🇦 +387',
  '🇲🇰 +389',
  '🇮🇹 +39',
  '🇷🇴 +40',
  '🇨🇭 +41',
  '🇨🇿 +420',
  '🇸🇰 +421',
  '🇱🇮 +423',
  '🇦🇹 +43',
  '🇬🇧 +44',
  '🇩🇰 +45',
  '🇸🇪 +46',
  '🇳🇴 +47',
  '🇵🇱 +48',
  '🇩🇪 +49',
  '🇫🇰 +500',
  '🇧🇿 +501',
  '🇬🇹 +502',
  '🇸🇻 +503',
  '🇭🇳 +504',
  '🇳🇮 +505',
  '🇨🇷 +506',
  '🇵🇦 +507',
  '🇫🇷 +508',
  '🇭🇹 +509',
  '🇵🇪 +51',
  '🇲🇽 +52',
  '🇨🇺 +53',
  '🇦🇷 +54',
  '🇧🇷 +55',
  '🇨🇱 +56',
  '🇨🇴 +57',
  '🇻🇪 +58',
  '🇫🇷 +590',
  '🇧🇴 +591',
  '🇬🇾 +592',
  '🇪🇨 +593',
  '🇫🇷 +594',
  '🇵🇾 +595',
  '🇲🇶 +596',
  '🇸🇷 +597',
  '🇺🇾 +598',
  '🇲🇾 +60',
  '🇦🇺 +61',
  '🇮🇩 +62',
  '🇵🇭 +63',
  '🇳🇿 +64',
  '🇸🇬 +65',
  '🇹🇭 +66',
  '🇹🇱 +670',
  '🇧🇳 +673',
  '🇳🇷 +674',
  '🇵🇬 +675',
  '🇹🇴 +676',
  '🇻🇺 +678',
  '🇫🇯 +679',
  '🇵🇼 +680',
  '🇨🇰 +682',
  '🇳🇺 +683',
  '🇼🇸 +685',
  '🇰🇮 +686',
  '🇳🇨 +687',
  '🇹🇻 +688',
  '🇵🇫 +689',
  '🇹🇰 +690',
  '🇫🇲 +691',
  '🇲🇭 +692',
  '🇯🇵 +81',
  '🇰🇷 +82',
  '🇻🇳 +84',
  '🇰🇵 +850',
  '🇭🇰 +852',
  '🇲🇴 +853',
  '🇰🇭 +855',
  '🇱🇦 +856',
  '🇨🇳 +86',
  '🇧🇩 +880',
  '🇹🇼 +886',
  '🇹🇷 +90',
  '🇮🇳 +91',
  '🇵🇰 +92',
  '🇦🇫 +93',
  '🇱🇰 +94',
  '🇲🇲 +95',
  '🇲🇻 +960',
  '🇱🇧 +961',
  '🇯🇴 +962',
  '🇸🇾 +963',
  '🇮🇶 +964',
  '🇰🇼 +965',
  '🇸🇦 +966',
  '🇾🇪 +967',
  '🇴🇲 +968',
  '🇵🇸 +970',
  '🇦🇪 +971',
  '🇮🇱 +972',
  '🇧🇭 +973',
  '🇶🇦 +974',
  '🇧🇹 +975',
  '🇲🇳 +976',
  '🇳🇵 +977',
  '🇮🇷 +98',
  '🇹🇯 +992',
  '🇹🇲 +993',
  '🇦🇿 +994',
  '🇬🇪 +995',
  '🇰🇬 +996',
  '🇺🇿 +998',
 ] as const;
