const tabs: Record<string, string> = {
  planned: 'Запланированные',
  expired: 'Прошедшие',
  match: 'Собрать матч',
  set: 'Тренировочные сеты'
}

export function tabNameParser(tab: string) {
  return tabs?.[tab] ?? tab;
}
