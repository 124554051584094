import { CalculatedGameStatus, CountPlayerMetricView, CurrentPlayerProfileView, GameRole, GameView, Grade, InvitationStatus, MetricScreenView, toLocalDateTime } from "@/integration-api/server-rest-lundapadelApi";
import { GameFormValues } from "./GameCreate/GameCreatePage.interfaces";
import { PopupMessage } from "@/components/PopupMessage/PopupMessage";
import { createGame, updateGame } from "./service";
import { createInviteListForGame, ownerEnterGame } from "../game/service";
import { getPlatform } from "@/utils/utils";

export const loopThourghErrors = (errors: Record<string, any>) => {
  for (let key in errors) {
    if(typeof errors[key] === 'object') {
      loopThourghErrors(errors[key]);
    } else {
      PopupMessage.open(errors[key]);
    }
  }
};

export const getPlannedDate = ({ date, time }:{ date: Date, time?: string }) => {;
  return toLocalDateTime(date, time && time.length === 4 ? `0${time}` : time as any);
}

export const gameInitialValues: GameFormValues = {
  plannedDate: {
    date: new Date(),
  },
  minGrade: 1,
  maxGrade: 2,
  description: '',
  duration: '2',
  courtBooked: false,
  courtNumber: '',
  privateGame: false,
  ranking: true,
  everyoneCanInvite: false,
  isCoachParticipating: true,
  invitations: [],
  members: [],
  everyoneCanSetScore: false,
  playersLimit: 4,
  selectedCourts: []
}

export function timeStringToNumber(time: string) {
  const hoursMinutes = time.split(':');
  const hours = Number(hoursMinutes[0]);
  const minutes = Number(hoursMinutes[1]) ? 0.5 : 0;
  return hours + minutes;
}

export const isWithinDay = (time: string, duration: string) => {
  const parsedTime = timeStringToNumber(time) + Number(duration.replace(',', '.'));
  return parsedTime <= 24;
}

type HandleGameArgs = {
  type: 'create' | 'edit',
  values: GameFormValues,
  current: CurrentPlayerProfileView,
}

export async function handleGame({ type, values, current }: HandleGameArgs) {
  const gameCreationInitiator = sessionStorage.getItem('lastPaths');
  let parsedGameCreationInitiator: Array<string> | null = null;
  try {
    if(gameCreationInitiator) {
      parsedGameCreationInitiator = JSON.parse(gameCreationInitiator) as Array<string>;
    }
  } catch(err) {
    console.log(err);
  } 
  let countPlayers: CountPlayerMetricView | undefined = undefined;
  let screen = MetricScreenView.PLAY_SCREEN;
  if(parsedGameCreationInitiator) {
    if(parsedGameCreationInitiator?.[0].includes('partners')) {
      countPlayers = window.location.search.includes('single') ? CountPlayerMetricView.SINGLE : CountPlayerMetricView.MULTIPLY;
      screen = MetricScreenView.PARTNER_SCREEN;
    } else if(parsedGameCreationInitiator?.[0] === '/') {
      screen = MetricScreenView.MAIN_SCREEN;
    }
  }

  const request: GameView = {
    ...values,
    minGrade: Grade[`_${values.minGrade}` as keyof typeof Grade],
    maxGrade: Grade[`_${values.maxGrade}` as keyof typeof Grade],
    plannedDate: getPlannedDate({ date: values.plannedDate.date, time: values.plannedDate.time }),
    club: {
      address: values.club?.mapAddress,
      displayName: values.club?.name,
      uid: values?.club?.reference?.uid
    },
    duration: Number(values.duration.replace(',', '.')) * 60,
    owner: { ...current, uid: current?.identity?.uid },
    permissions: {
      inviteCreate: values.everyoneCanInvite ? [GameRole.PLAYER] : [],
      scoringResult: values.everyoneCanSetScore ? [GameRole.PLAYER] : []
    },
    playersLimit: values.playersLimit ? Number(values.playersLimit) : 4,
    invitations: [],
    members: [],
    metrics: type === 'create' ? {
      countPlayers,
      screen,
      system: getPlatform()
    } : undefined
  };

  const { result } = type === 'create' ? await createGame(request) : await updateGame(request);

  if(result) {
    const wannaStay = values.isCoachParticipating && values.members.some(member => member.player?.uid === current.identity?.uid);
    if(!wannaStay) {
      await ownerEnterGame({ enter: values.isCoachParticipating, gameUid: result.identity?.uid });
    }
  }
  if(result && values.invitations.length && (result.members?.length! < result.playersLimit!)) {
    await createInviteListForGame({
      gameUid: result.identity?.uid,
      invitations: values.invitations.map(val => ({
        invitationStatus: InvitationStatus.SENT,
        player: val,
        sender: current,
        sendDate: toLocalDateTime(new Date())
      }))
    });
  }
  return result?.identity?.uid;
}
