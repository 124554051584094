import React, {useCallback, useEffect, useRef, useState} from 'react';
import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import LundaPadelLogo from '@/components/LundaPadelLogo/LundaPadelLogo';
import HLWrapper from '@/components/HLWrapper/HLWrapper';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Button from '@/components/Button/Button';
import styles from './SignInPage.module.css';
import InputPhone from '@/components/InputPhone';
import InnerPadding from '@/components/InnerPadding/InnerPadding';
import { InputPhoneValue } from '@/components/InputPhone/InputPhone';
import KeyboardCodeInput from '@/components/KeyboardCodeInput';
import { InitialCodeinputType } from '@/components/KeyboardCodeInput/helpers/types';
import { useNavigate } from 'react-router-dom';
import { checkPhoneValidation } from './utils';
import { PhoneValueState, SessionStorageAuthData } from './SignInPage.interface';
import { AuthStatus, CaptchaData } from '@/integration-api/server-rest-lundapadelApi';
import { authFetch, fetchCaptcha, sendCode } from './service';
import { setAuthData } from '@/models/auth/auth';
import { useAppDispatch } from '@/hooks/hooks';
import TOS from './TOS';
import Policy from './Policy';
import AcceptRulesModal from './components/AcceptRulesModal/AcceptRulesModal';
import RestClient from '@/integration/RestClient';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';
import { COUNTRIES_FLAGS } from '@/components/InputPhone/utils';
import { ErrorUtils } from '@/utils/utils';

const initPhoneValue: PhoneValueState = {
  countryCode: COUNTRIES_FLAGS[0],
  phone: '',
  temporalToken: '',
}

export const minPhoneLength = 7;

function SignInPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()

  const [codeValue, setCodeValue] = useState<InitialCodeinputType>();
  const [phoneValue, setPhoneValue] = useState<PhoneValueState>(initPhoneValue);
  const [captcha, setCaptcha] = useState<CaptchaData & {answer?: string} | undefined>();
  const [signInStep, setSignInStep] = useState<1 | 2>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [tosOpen, setTosOpen] = useState<boolean>(false);
  const [policyOpen, setPolicyOpen] = useState(false);
  const [acceptRulesModal, setAcceptRulesModal] = useState(false);

  const telRef = useRef<HTMLInputElement>(null);
  const captchaRef = useRef<HTMLInputElement>(null);

  const isPhone = phoneValue?.countryCode === COUNTRIES_FLAGS[0];

  const sendPhoneNumber = async (answer: string) => {
    setLoading(true)
    try {
      if (!isPhone) {
        try {
          const { result } = await RestClient.mainApi.telegramCheckAvailable();
          if (!result?.available) throw new Error('Вход через телеграм недоступен в данный момент.')
        } catch (e) {
          throw e;
        }
      }
      
      checkPhoneValidation(phoneValue);
      if (captcha?.ticket) {
        if(phoneValue.countryCode === COUNTRIES_FLAGS[1] && phoneValue.phone?.[0] === '9') {
          throw new Error('Код в телеграм не может быть отправлен для данного номера');
        }
        const { result } = await sendCode(phoneValue, answer, captcha?.ticket, !isPhone);

        // запишем токен в стейт с информацией о телефоне, так при вызове auth удобно деструктуризировать 1 объект
        setPhoneValue({ ...phoneValue, temporalToken: result?.temporalToken });
        setSignInStep(2);
      } else {
        throw new Error('Капча не прошла проверку!')
      }
    } catch (error) {
      ErrorUtils.handleErrorMessage(error);
    } finally {
      setLoading(false);
    }
  }

  const signIn = useCallback(
    async () => {
      setLoading(true)
      try {
        const { result } = await authFetch({ ...phoneValue, code: codeValue?.value.join('') });
        setLoading(false)
        switch (result?.status) {
          case AuthStatus.SUCCESSFUL: {
            navigate('/');
            break;
          }
          case AuthStatus.REGISTRATION_REQUIRED: {
            setAcceptRulesModal(true);
            const authData: SessionStorageAuthData = {
              countryCode: phoneValue?.countryCode!,
              phone: phoneValue?.phone!,
              registrationToken: result.registrationToken!,
              previouslyDeleted: result.previouslyDeleted
            }
            sessionStorage.setItem('authData', JSON.stringify(authData));
            break;
          }
        }
        setLoading(false)
      } catch (err) {
        ErrorUtils.handleErrorMessage(err);
        setLoading(false)
      }
  }, [codeValue?.value, navigate, phoneValue, loading]);

  const getCaptcha = (countryCode: string, phone: string) => {
    fetchCaptcha({
      countryCode,
      phone,
    })
      .then(({ result }) => setCaptcha({...result, answer: ''}))
      .catch(() => PopupMessage.open('Ошибка при загрузке капчи.'));
  }

  const handleCaptchaChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let sanitizeValue = evt.target.value.replace(/[^0-9]/, '');
    setCaptcha({...captcha, answer: sanitizeValue});
  }

  const handleAcceptRules = () => {
    const authData = sessionStorage.getItem('authData');
    if (typeof authData === 'string') {
      dispatch(setAuthData(JSON.parse(authData)))
      navigate('/auth/register');
    }
    setAcceptRulesModal(false);
  }

  const handleSignInBtnClick = () => {
    if (phoneValue?.temporalToken) {
      signIn();
    } else {
      sendPhoneNumber(captcha?.answer!);
    }
  }

  const handleBackToFirstStep = () => {
    setSignInStep(1);
    setCaptcha(undefined);
  }

  const handlePhoneNumberChange = (newPhone: InputPhoneValue) => {
    setPhoneValue(newPhone)
    if (newPhone.phone.length >= minPhoneLength) {
      getCaptcha(newPhone.countryCode, newPhone.phone)
    } else {
      setCaptcha(undefined);
    }
  }

  useEffect(() => {
    return () => {
      setCodeValue(undefined);
      setPhoneValue(initPhoneValue);
    };
  }, []);

  return (
    <div className={styles.background}>
      {tosOpen ? <TOS close={() => setTosOpen(false)}/> : null}
      {policyOpen ? <Policy onClose={() => setPolicyOpen(false)}/> : null}
      <BasePageWrapper>
        <InnerPadding className={styles['content-wrapper']}>
          <div className={styles['content-main']}>
            <HLWrapper className={styles.lodoWrap}>
              <LundaPadelLogo />
            </HLWrapper>

            {signInStep === 1 && <div className={styles.input}>
              <span className={styles['phoneLabel']}>{isPhone ? 'Войти по СМС' : 'Войти по Telegram'}</span>
              <InputPhone onChange={handlePhoneNumberChange} ref={telRef}/>

              {captcha && (phoneValue?.phone.length >= minPhoneLength) && (
                <div className={styles['captcha-section']}>
                  <h3 className={styles['captcha-title']}>Решите пример</h3>
                  <HLWrapper>
                    <div className={styles['captcha-condition']}>
                      {captcha.operand1} {captcha.operation} {captcha.operand2} =
                      <input
                        className={styles['captcha-input']}
                        value={captcha.answer}
                        maxLength={3}
                        type='number'
                        onChange={handleCaptchaChange}
                        ref={captchaRef}
                        onKeyDown={e => {
                          if(e.key === 'Enter') {
                            e.preventDefault();
                           (e.target as HTMLInputElement)?.blur?.();
                          }
                        }}
                      />
                    </div>
                  </HLWrapper>
                </div>
              )}
            </div>}

            {signInStep === 2 && <KeyboardCodeInput
              onChange={setCodeValue}
              resendCode={handleBackToFirstStep}
              signIn={signIn}
              changePhoneNumberHandler={handleBackToFirstStep}
            />}
          </div>

          <div className={styles.signBtnWrapper}>
            <OversideWrapper>
              {signInStep === 1 ?              
                <Button onClick={handleSignInBtnClick} disabled={!phoneValue?.phone || !captcha?.answer || loading}>
                  Прислать код
                </Button> : null
              }
              {signInStep === 2 ?
                <Button onClick={handleSignInBtnClick} disabled={loading}>
                  Войти
                </Button> : null
              }
            </OversideWrapper>
          </div>
        </InnerPadding>
      </BasePageWrapper>
      <AcceptRulesModal
        visible={acceptRulesModal}
        onClose={() => setAcceptRulesModal(false)}
        onPolicyOpen={() => setPolicyOpen(true)}
        onTosOpen={() => setTosOpen(true)}
        handleAcceptRules={handleAcceptRules}
      />
    </div>
  );
}

export default SignInPage;
