import Icon, { IconProps } from "../Icon";

interface TournamentCupProps extends IconProps {}

const TournamentCup = ({ ...props }: TournamentCupProps) => {
  return (  
    <Icon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.07623 15.3072C8.25864 15.3106 7.53801 15.6502 7.10742 16.1659L11.0543 16.1495C10.6182 15.6372 9.89383 15.3037 9.07623 15.3072Z" fill="#FFC500"/>
        <path d="M11.0543 16.1489L7.10742 16.1653C7.54378 16.6779 8.26787 17.0114 9.08547 17.008C9.90307 17.0045 10.6237 16.6649 11.0543 16.1489Z" fill="#FFD900"/>
        <path d="M9.92419 14.5294C10.0327 15.1988 10.4921 15.7455 11.1054 16.0322L10.5822 12.8013C10.0841 13.2243 9.81596 13.8603 9.92419 14.5294Z" fill="#FFAC00"/>
        <path d="M10.5859 12.8013L11.1092 16.0322C11.6076 15.6091 11.876 14.9731 11.7675 14.3038C11.6589 13.6347 11.1992 13.0879 10.5859 12.8013Z" fill="#FFB800"/>
        <path d="M7.34512 13.2445C6.62074 13.0252 5.86144 13.1297 5.29492 13.4687L8.79214 14.5277C8.59041 13.9559 8.06978 13.4638 7.34512 13.2445Z" fill="#FFC500"/>
        <path d="M8.79214 14.5273L5.29492 13.4683C5.49665 14.0401 6.01757 14.5322 6.74195 14.7515C7.46633 14.971 8.22563 14.8666 8.79214 14.5273Z" fill="#FFD900"/>
        <path d="M8.32047 12.7061C8.16405 13.3792 8.39175 14.0385 8.86765 14.4937L9.62349 11.2441C8.98136 11.5026 8.47689 12.0329 8.32047 12.7061Z" fill="#FFAC00"/>
        <path d="M9.62107 11.2441L8.86523 14.4937C9.50736 14.2353 10.0118 13.7049 10.1683 13.0318C10.325 12.3587 10.0973 11.6997 9.62107 11.2441Z" fill="#FFB800"/>
        <path d="M6.3901 10.4954C5.76788 10.0522 4.97713 9.91699 4.2793 10.0733L7.28273 12.2123C7.32112 11.5864 7.01232 10.9386 6.3901 10.4954Z" fill="#FFC500"/>
        <path d="M7.28197 12.2128L4.27853 10.0737C4.24044 10.6999 4.54924 11.3474 5.17117 11.7906C5.79309 12.2338 6.58385 12.3691 7.28197 12.2128Z" fill="#FFD900"/>
        <path d="M7.49939 10.147C7.05553 10.7758 7.00532 11.5142 7.29247 12.1266L9.43502 9.09082C8.67716 9.14521 7.94325 9.51817 7.49939 10.147Z" fill="#FFAC00"/>
        <path d="M9.43747 9.09082L7.29492 12.1266C8.05249 12.0722 8.78668 11.6993 9.23054 11.0705C9.67441 10.4417 9.72462 9.70322 9.43747 9.09082Z" fill="#FFB800"/>
        <path d="M6.06825 7.33732C5.54185 6.80234 4.78601 6.54621 4.0625 6.59341L6.60331 9.17623C6.76868 8.56182 6.59437 7.8723 6.06825 7.33732Z" fill="#FFC500"/>
        <path d="M6.60106 9.1756L4.06024 6.59277C3.89488 7.20747 4.0689 7.89699 4.5953 8.43197C5.12171 8.96696 5.87754 9.22308 6.60106 9.1756Z" fill="#FFD900"/>
        <path d="M7.15851 7.45418C6.61479 7.98543 6.42345 8.6784 6.57611 9.30173L9.20033 6.7379C8.47133 6.67775 7.70222 6.92323 7.15851 7.45418Z" fill="#FFAC00"/>
        <path d="M9.20234 6.7373L6.57812 9.30113C7.30741 9.36157 8.07652 9.11609 8.62024 8.58485C9.16367 8.05361 9.3553 7.36064 9.20234 6.7373Z" fill="#FFB800"/>
        <path d="M6.46272 4.56011C6.03415 3.97706 5.33835 3.63835 4.62695 3.59863L6.6962 6.41312C6.95334 5.83468 6.89158 5.14315 6.46272 4.56011Z" fill="#FFC500"/>
        <path d="M6.69636 6.41312L4.62711 3.59863C4.36997 4.17736 4.43173 4.86889 4.8603 5.45193C5.28915 6.03498 5.98496 6.37369 6.69636 6.41312Z" fill="#FFD900"/>
        <path d="M7.50367 4.80602C6.91666 5.23999 6.63413 5.86505 6.68319 6.46392L9.51664 4.36917C8.84507 4.23046 8.09039 4.37205 7.50367 4.80602Z" fill="#FFAC00"/>
        <path d="M9.51509 4.36914L6.68164 6.46389C7.35321 6.60289 8.10789 6.46101 8.69461 6.02704C9.28162 5.59335 9.56444 4.9683 9.51509 4.36914Z" fill="#FFB800"/>
        <path d="M7.87761 2.72579C7.34803 3.69331 6.96131 4.73651 6.73736 5.81597C6.51658 6.88018 6.45366 7.97691 6.55467 9.05925C6.65482 10.1353 6.91513 11.1986 7.33273 12.196C7.74196 13.1728 8.31974 14.087 9.11367 14.7981C9.94512 15.5429 10.9546 16.0549 11.954 16.5355C12.0805 16.5962 12.2069 16.6563 12.3335 16.7168C12.3503 16.7248 12.365 16.6998 12.348 16.692C11.3468 16.2155 10.3284 15.7354 9.45306 15.0456C8.60631 14.3783 7.96736 13.504 7.51282 12.5325C7.0551 11.5543 6.76015 10.4958 6.62365 9.42559C6.48685 8.35419 6.51167 7.26322 6.69407 6.19872C6.8779 5.12588 7.22162 4.08123 7.70964 3.10768C7.77169 2.98422 7.83605 2.86191 7.90242 2.74047C7.91137 2.72407 7.88655 2.70968 7.87761 2.72579Z" fill="#FFD300"/>
        <path d="M8.70217 2.89186C8.94892 2.21443 8.79828 1.51426 8.36653 1L7.17578 4.27061C7.86899 4.06629 8.45542 3.5693 8.70217 2.89186Z" fill="#FFC500"/>
        <path d="M7.17332 4.27062L8.36407 1C7.67057 1.20404 7.08414 1.70103 6.83739 2.37875C6.59064 3.05647 6.74129 3.75635 7.17332 4.27062Z" fill="#FFD900"/>
        <path d="M14.0562 15.3072C14.8738 15.3106 15.5944 15.6502 16.025 16.1659L12.0781 16.1495C12.5142 15.6372 13.2386 15.3037 14.0562 15.3072Z" fill="#FFC500"/>
        <path d="M12.0781 16.1489L16.025 16.1653C15.5886 16.6779 14.8645 17.0114 14.0469 17.008C13.2293 17.0045 12.5087 16.6649 12.0781 16.1489Z" fill="#FFD900"/>
        <path d="M13.2086 14.5294C13.1001 15.1988 12.6406 15.7455 12.0273 16.0322L12.5506 12.8013C13.0487 13.2243 13.3168 13.8603 13.2086 14.5294Z" fill="#FFAC00"/>
        <path d="M12.5472 12.8013L12.024 16.0322C11.5256 15.6091 11.2572 14.9731 11.3657 14.3038C11.4742 13.6347 11.9339 13.0879 12.5472 12.8013Z" fill="#FFB800"/>
        <path d="M15.7869 13.2445C16.5113 13.0252 17.2706 13.1297 17.8371 13.4687L14.3398 14.5277C14.5419 13.9559 15.0625 13.4638 15.7869 13.2445Z" fill="#FFC500"/>
        <path d="M14.3398 14.5273L17.8371 13.4683C17.6353 14.0401 17.1144 14.5322 16.39 14.7515C15.6657 14.971 14.9061 14.8666 14.3398 14.5273Z" fill="#FFD900"/>
        <path d="M14.803 12.7061C14.9594 13.3792 14.7317 14.0385 14.2558 14.4937L13.5 11.2441C14.1421 11.5026 14.6463 12.0329 14.803 12.7061Z" fill="#FFAC00"/>
        <path d="M13.5026 11.2441L14.2584 14.4937C13.6163 14.2353 13.1118 13.7049 12.9554 13.0318C12.7987 12.3587 13.0264 11.6997 13.5026 11.2441Z" fill="#FFB800"/>
        <path d="M16.7376 10.4954C17.3598 10.0522 18.1506 9.91699 18.8484 10.0733L15.845 12.2123C15.8066 11.5864 16.1154 10.9386 16.7376 10.4954Z" fill="#FFC500"/>
        <path d="M15.8457 12.2128L18.8491 10.0737C18.8872 10.6999 18.5784 11.3474 17.9565 11.7906C17.3343 12.2338 16.5438 12.3691 15.8457 12.2128Z" fill="#FFD900"/>
        <path d="M15.6368 10.147C16.0807 10.7758 16.1309 11.5142 15.8437 12.1266L13.7012 9.09082C14.459 9.14521 15.1929 9.51817 15.6368 10.147Z" fill="#FFAC00"/>
        <path d="M13.6987 9.09082L15.8413 12.1266C15.0837 12.0722 14.3495 11.6993 13.9056 11.0705C13.4618 10.4417 13.4116 9.70322 13.6987 9.09082Z" fill="#FFB800"/>
        <path d="M17.0641 7.33732C17.5905 6.80234 18.3463 6.54621 19.0698 6.59341L16.529 9.17623C16.3636 8.56182 16.5376 7.8723 17.0641 7.33732Z" fill="#FFC500"/>
        <path d="M16.5312 9.17657L19.0721 6.59375C19.2374 7.20845 19.0634 7.89797 18.537 8.43295C18.0106 8.96793 17.2548 9.22406 16.5312 9.17657Z" fill="#FFD900"/>
        <path d="M15.9735 7.45321C16.5172 7.98445 16.7085 8.67742 16.5559 9.30075L13.9316 6.73692C14.6606 6.67678 15.4298 6.92225 15.9735 7.45321Z" fill="#FFAC00"/>
        <path d="M13.9297 6.73633L16.554 9.30016C15.8247 9.36059 15.0556 9.11511 14.5119 8.58387C13.9684 8.05263 13.7768 7.35966 13.9297 6.73633Z" fill="#FFB800"/>
        <path d="M16.667 4.56011C17.0956 3.97706 17.7914 3.63835 18.5028 3.59863L16.4335 6.41312C16.1767 5.83468 16.2384 5.14315 16.667 4.56011Z" fill="#FFC500"/>
        <path d="M16.4336 6.41312L18.5028 3.59863C18.76 4.17736 18.6982 4.86889 18.2697 5.45193C17.8411 6.03498 17.1453 6.37369 16.4336 6.41312Z" fill="#FFD900"/>
        <path d="M15.6282 4.80651C16.2152 5.24048 16.4977 5.86554 16.4487 6.46441L13.6152 4.36966C14.2868 4.23095 15.0412 4.37254 15.6282 4.80651Z" fill="#FFAC00"/>
        <path d="M13.6168 4.36963L16.4503 6.46438C15.7787 6.60338 15.024 6.4615 14.4373 6.02753C13.8503 5.59384 13.5675 4.96879 13.6168 4.36963Z" fill="#FFB800"/>
        <path d="M15.2286 2.74047C15.755 3.70223 16.1397 4.7391 16.3634 5.81194C16.5839 6.86982 16.6477 7.95935 16.5498 9.03565C16.4523 10.1096 16.1937 11.171 15.7793 12.1673C15.3683 13.1549 14.7836 14.0781 13.979 14.7935C13.153 15.5282 12.1522 16.0344 11.1623 16.5104C11.0359 16.5712 10.9095 16.6313 10.7828 16.6917C10.766 16.6998 10.7808 16.7245 10.7972 16.7165C11.8044 16.237 12.8278 15.7536 13.7071 15.0583C14.5576 14.386 15.1977 13.5037 15.6523 12.5258C16.1034 11.5554 16.3948 10.5088 16.5325 9.4489C16.6719 8.37519 16.6488 7.28163 16.4681 6.21426C16.2846 5.13077 15.9383 4.07577 15.4456 3.093C15.3836 2.96954 15.3192 2.84723 15.2529 2.72579C15.2448 2.70968 15.2197 2.72407 15.2286 2.74047Z" fill="#FFD300"/>
        <path d="M14.4292 2.89186C14.1824 2.21443 14.3331 1.51426 14.7648 1L15.9556 4.27061C15.2624 4.06629 14.6759 3.5693 14.4292 2.89186Z" fill="#FFC500"/>
        <path d="M15.9583 4.27062L14.7676 1C15.4611 1.20404 16.0475 1.70103 16.2943 2.37875C16.541 3.05647 16.3904 3.75635 15.9583 4.27062Z" fill="#FFD900"/>
        <path d="M13.6232 6.92773C13.5118 7.16371 13.3978 7.42358 13.2861 7.70532C12.1507 7.20228 10.8717 7.02356 9.64196 7.19911C9.60012 7.10558 9.55885 7.01493 9.51758 6.92773H13.6232Z" fill="#FFD300"/>
        <path d="M13.2867 7.70517C12.8905 8.70463 12.5242 9.97834 12.414 11.4336C12.1681 11.6049 11.8772 11.7116 11.5779 11.7381C11.2928 11.7634 11.0016 11.7151 10.739 11.6017C10.6988 10.9479 10.6062 10.3294 10.4812 9.75416C10.5635 9.78207 10.6469 9.80711 10.7312 9.82898C10.9704 9.89114 11.2325 9.92654 11.4593 9.82812C11.7522 9.70063 11.9182 9.3622 11.8971 9.04421C11.876 8.72621 11.6942 8.43498 11.4567 8.22173C11.0423 7.85021 10.4431 7.70661 9.89972 7.81913C9.81487 7.59955 9.72858 7.39264 9.64258 7.19896C10.8723 7.02342 12.1514 7.20242 13.2867 7.70517Z" fill="#FFDE00"/>
        <path d="M9.07031 6.06474C9.1162 6.07309 9.16237 6.08057 9.20855 6.08776C10.5782 6.30101 12.0201 6.04459 13.2284 5.36572H14.511C14.511 5.36572 14.4501 5.4486 14.3485 5.60631V5.60659L13.9364 6.3082C13.926 6.32748 13.9153 6.34734 13.9046 6.36749H9.23683C9.23048 6.35569 9.22442 6.34389 9.21807 6.33238C9.21201 6.32087 9.20566 6.30935 9.1996 6.29784L9.07031 6.06474Z" fill="#FFDE00"/>
        <path d="M8.79168 5.60659C8.77753 5.58444 8.76426 5.564 8.75156 5.54501C8.67422 5.42731 8.62891 5.36572 8.62891 5.36572H13.2277C12.0193 6.04459 10.5778 6.30101 9.20783 6.08776C9.16166 6.08057 9.11548 6.0728 9.0696 6.06474L8.79168 5.60659Z" fill="#FFE100"/>
        <path d="M14.2905 6.51108C14.2905 6.52029 14.2902 6.52921 14.2896 6.53841C14.283 6.6423 14.2377 6.73583 14.1678 6.80519C14.0922 6.88087 13.9877 6.92749 13.8729 6.92749H9.26916C9.04867 6.92749 8.86657 6.75483 8.85243 6.53841C8.85156 6.52949 8.85156 6.52029 8.85156 6.51108C8.85156 6.47136 8.86772 6.43539 8.89399 6.40949C8.92025 6.3833 8.95632 6.36719 8.99586 6.36719H14.1462C14.1499 6.36719 14.1537 6.36719 14.1571 6.36776C14.1609 6.36805 14.1644 6.36834 14.1681 6.36891C14.1721 6.36949 14.1762 6.37035 14.1802 6.37122C14.1831 6.37208 14.1863 6.37265 14.1892 6.37352C14.1926 6.37467 14.1958 6.37582 14.1993 6.37726C14.2024 6.37841 14.2059 6.37985 14.2088 6.38158C14.2151 6.38445 14.2212 6.38819 14.227 6.39194C14.2307 6.39453 14.2345 6.3974 14.2379 6.40028C14.2417 6.40316 14.2449 6.40632 14.248 6.40978C14.2504 6.41208 14.253 6.41467 14.2553 6.41726C14.2576 6.41985 14.2596 6.42244 14.2619 6.42532C14.2674 6.4328 14.2723 6.44057 14.2763 6.4492C14.2789 6.45438 14.2809 6.45956 14.283 6.46503C14.2835 6.46704 14.2841 6.46877 14.2847 6.47079C14.2864 6.47626 14.2876 6.48172 14.2884 6.48748C14.289 6.48978 14.2893 6.49237 14.2896 6.49467C14.2899 6.4964 14.2899 6.49841 14.2902 6.50014C14.2902 6.50158 14.2905 6.50302 14.2905 6.50446C14.2905 6.50561 14.2905 6.50647 14.2905 6.50762C14.2905 6.50878 14.2905 6.50993 14.2905 6.51108Z" fill="#FFE100"/>
        <path d="M12.3937 13.0321C12.3923 13.0292 12.3908 13.0263 12.3894 13.0235C12.2064 12.6801 11.9071 12.4565 11.5692 12.4565C11.3126 12.4565 11.0786 12.5855 10.9005 12.7976C10.844 12.8649 10.7932 12.9406 10.749 13.0232C10.7502 13.0281 10.7513 13.0327 10.7528 13.0376C10.7502 13.0358 10.7473 13.0338 10.7447 13.0315C10.6287 13.2525 10.5605 13.5222 10.5605 13.8134C10.5605 14.2963 10.7481 14.7205 11.0307 14.9611C11.1865 15.0938 11.3712 15.1706 11.5692 15.1706C11.7672 15.1706 11.9519 15.0938 12.1077 14.9611C12.3903 14.7205 12.5778 14.2966 12.5778 13.8134C12.5778 13.5228 12.5097 13.2531 12.3937 13.0321ZM11.5689 14.7355C11.1906 14.7355 10.8838 14.3228 10.8838 13.8137C10.8838 13.3046 11.1906 12.892 11.5689 12.892C11.9473 12.892 12.254 13.3046 12.254 13.8137C12.2543 14.3228 11.9473 14.7355 11.5689 14.7355Z" fill="#FFE100"/>
        <path opacity="0.63" d="M10.357 6.6466C10.357 6.75106 10.1512 6.83567 9.89725 6.83567C9.64328 6.83567 9.4375 6.75106 9.4375 6.6466C9.4375 6.54213 9.64328 6.45752 9.89725 6.45752C10.1512 6.45752 10.357 6.54242 10.357 6.6466Z" fill="#FFED61"/>
        <path d="M12.0349 17.6496C12.2037 17.2093 12.0992 16.6645 11.804 16.282C11.5558 15.9606 11.2028 15.741 10.8164 15.6043C10.922 15.3206 10.9956 15.0846 11.0323 14.9614C11.1881 15.0941 11.3728 15.1709 11.5708 15.1709C11.7688 15.1709 11.9535 15.0941 12.1093 14.9614C12.2432 15.4109 12.8701 17.365 13.7382 17.6496H12.0349Z" fill="#FFD300"/>
        <path d="M12.0318 17.6495H9.40039C10.0307 17.4428 10.5337 16.3562 10.8134 15.6045C11.1998 15.7412 11.5527 15.9605 11.8009 16.2822C12.0962 16.6644 12.2006 17.2091 12.0318 17.6495Z" fill="#FFDE00"/>
        <path d="M14.1576 18.0392V18.0579C14.1576 18.137 14.0927 18.2018 14.0133 18.2018H9.12477C9.0454 18.2018 8.98047 18.137 8.98047 18.0579V18.0392C8.98047 17.8254 9.15594 17.6504 9.37036 17.6504H13.7677C13.9822 17.6504 14.1576 17.8254 14.1576 18.0392Z" fill="#FFE100"/>
        <path d="M7.98233 5.26494C7.98088 5.45775 7.97252 5.65028 7.9578 5.84252C7.68709 5.75417 7.46978 5.75647 7.13385 5.78928C6.91971 6.4198 7.10672 7.11306 7.335 7.74071C7.37714 7.85669 7.42129 7.97209 7.46718 8.08662C7.38233 8.31598 7.28248 8.54707 7.14741 8.74938C7.06285 8.57786 6.9832 8.40433 6.90845 8.22907C6.58494 7.47164 6.39648 6.67564 6.49489 5.85748C6.51423 5.6969 6.52549 5.49977 6.55694 5.34236C6.70644 5.32308 6.88364 5.3084 7.0363 5.29717C7.35924 5.26753 7.67872 5.23473 7.98233 5.26494Z" fill="#FFDA00"/>
        <path d="M9.23408 6.36713H8.99281C8.95298 6.36713 8.91691 6.38325 8.89093 6.40944C8.86496 6.43534 8.84851 6.47131 8.84851 6.51102C8.84851 6.52023 8.8488 6.52915 8.84937 6.53836C8.68025 6.27159 8.44043 6.04021 8.13077 5.90812C8.06958 5.88222 8.01215 5.86035 7.95703 5.84222C7.97204 5.64999 7.98012 5.45746 7.98156 5.26465C8.17665 5.28393 8.36511 5.3294 8.54375 5.41804C8.61676 5.45401 8.72557 5.55847 8.78963 5.60653C8.86323 5.72107 8.95817 5.87445 9.0664 6.0641C9.10796 6.13691 9.15125 6.2149 9.19627 6.29807C9.20233 6.30958 9.20868 6.3208 9.21474 6.3326C9.22166 6.34353 9.22773 6.35533 9.23408 6.36713Z" fill="#FFD400"/>
        <path d="M10.7493 13.0231C10.7478 13.026 10.7464 13.0288 10.7449 13.0317C10.2618 12.6691 9.80844 12.2754 9.38882 11.8556L9.38853 11.8553C8.46444 10.9309 7.70399 9.87909 7.14844 8.74869C7.2835 8.5461 7.38336 8.3153 7.46821 8.08594C8.16805 9.83103 9.30484 11.4158 10.7545 12.6726C10.753 12.7206 10.751 12.769 10.749 12.8173C10.749 12.817 10.7233 12.9094 10.7493 13.0231Z" fill="#FFD400"/>
        <path d="M12.3929 12.8168C12.3929 12.8168 12.4186 12.9092 12.3923 13.0229C12.2093 12.6796 11.9101 12.456 11.5721 12.456C11.3156 12.456 11.0815 12.5849 10.9034 12.797C10.8469 12.8643 10.7961 12.94 10.7519 13.0226C10.726 12.9086 10.7514 12.8166 10.7514 12.8166C10.7534 12.7682 10.7551 12.7201 10.7568 12.6718C10.7681 12.3057 10.7617 11.9483 10.7404 11.6007C11.003 11.7141 11.2939 11.7621 11.5793 11.7371C11.8783 11.7106 12.1695 11.6038 12.4154 11.4326C12.3851 11.8326 12.3744 12.2462 12.3874 12.6718C12.3891 12.7204 12.3909 12.7685 12.3929 12.8168Z" fill="#FFD300"/>
        <path d="M16.2341 8.21504C16.2321 8.21993 16.2298 8.22511 16.2278 8.23C15.6604 9.5581 14.8128 10.7918 13.7485 11.8566L13.7482 11.8569C13.3285 12.2768 12.8752 12.6704 12.392 13.033C12.3906 13.0302 12.3892 13.0273 12.3877 13.0244C12.4137 12.9104 12.3883 12.8184 12.3883 12.8184C12.3863 12.77 12.3845 12.722 12.3828 12.6736C13.9277 11.3343 15.1173 9.62257 15.8012 7.74106C15.8154 7.7025 15.8292 7.66365 15.8428 7.62451C15.9675 7.82538 16.0982 8.02194 16.2341 8.21504Z" fill="#FFD400"/>
        <path d="M16.2376 8.21405C16.1014 8.02124 15.971 7.8244 15.846 7.62381C16.0541 7.02753 16.2067 6.38061 16.0056 5.78893C15.601 5.74951 15.3686 5.7544 15.0079 5.90836C14.9926 5.91469 14.9779 5.9216 14.9632 5.92851C14.8826 5.74001 14.807 5.54979 14.7363 5.35755C15.1585 5.20301 15.6267 5.25337 16.1034 5.29712C16.2561 5.30834 16.4333 5.32302 16.5828 5.3423C16.6145 5.49972 16.6255 5.69684 16.6448 5.85742C16.7427 6.67011 16.5574 7.46122 16.2376 8.21405Z" fill="#FFDA00"/>
        <path d="M14.3445 5.60664C14.4089 5.55829 14.5174 5.45412 14.5904 5.41815C14.6369 5.39512 14.6842 5.37498 14.7318 5.35742C14.8025 5.54966 14.8784 5.74017 14.9587 5.92838C14.6707 6.06306 14.4455 6.28494 14.2848 6.53847C14.2857 6.52955 14.2857 6.52034 14.2857 6.51113C14.2857 6.50998 14.2857 6.50883 14.2857 6.50768C14.2857 6.50653 14.2857 6.50567 14.2857 6.50451C14.2857 6.50308 14.2857 6.50164 14.2854 6.5002C14.2854 6.49818 14.2851 6.49645 14.2848 6.49473C14.2845 6.49214 14.2842 6.48984 14.2836 6.48753C14.2828 6.48178 14.2813 6.47631 14.2799 6.47084C14.2793 6.46883 14.2787 6.46682 14.2782 6.46509C14.2761 6.45962 14.2741 6.45444 14.2715 6.44926C14.2675 6.44091 14.2629 6.43286 14.2571 6.42538C14.2551 6.4225 14.2528 6.41991 14.2505 6.41732C14.2482 6.41473 14.2458 6.41214 14.2435 6.40983C14.2404 6.40638 14.2369 6.4035 14.2334 6.40034C14.23 6.39746 14.2262 6.39458 14.2225 6.39199C14.2167 6.38796 14.2106 6.38451 14.2043 6.38163C14.2011 6.38019 14.1979 6.37875 14.1948 6.37731C14.1916 6.37588 14.1881 6.37472 14.1847 6.37357C14.1818 6.37271 14.1789 6.37185 14.1757 6.37127C14.1717 6.37041 14.1676 6.36954 14.1636 6.36897C14.1601 6.36839 14.1564 6.36811 14.1526 6.36782C14.1489 6.36753 14.1454 6.36724 14.1417 6.36724H13.9004C13.9114 6.3471 13.9217 6.32724 13.9321 6.30796L14.3445 5.60664Z" fill="#FFD400"/>
        <path d="M9.89844 7.81863C10.4419 7.70611 11.041 7.84971 11.4554 8.22123C11.6932 8.43448 11.8748 8.72571 11.8958 9.04371C11.9169 9.36171 11.7512 9.70042 11.458 9.82762C11.2312 9.92604 10.9694 9.89064 10.7299 9.82848C10.6459 9.80661 10.5625 9.78157 10.48 9.75366L9.89844 7.81863Z" fill="#FFE100"/>
        <path opacity="0.63" d="M11.1789 8.8363C11.2187 9.11977 11.1027 9.37015 10.9194 9.39576C10.7361 9.42137 10.5555 9.21244 10.5156 8.92926C10.4758 8.64607 10.5918 8.39541 10.7751 8.3698C10.9581 8.34419 11.139 8.55312 11.1789 8.8363Z" fill="#FFED61"/>
        <path opacity="0.63" d="M11.313 17.028C11.313 17.1938 11.1274 17.3284 10.8985 17.3284C10.6697 17.3284 10.4844 17.194 10.4844 17.028C10.4844 16.8622 10.6699 16.7275 10.8985 16.7275C11.1274 16.7278 11.313 16.8622 11.313 17.028Z" fill="#FFED61"/>
        <path d="M14.5936 18.2892C14.5488 18.2552 14.494 18.2368 14.4377 18.2368H11.576H8.71425C8.65797 18.2368 8.60314 18.2552 8.55841 18.2892L7.28713 19.2544C7.22307 19.303 7.18555 19.3784 7.18555 19.4587V21.4148H11.5757H15.9658V19.4587C15.9658 19.3787 15.9283 19.303 15.8642 19.2544L14.5936 18.2892Z" fill="#B25908"/>
        <path d="M16.3184 22.0756H6.82812V21.6114C6.82812 21.5026 6.91644 21.4146 7.02553 21.4146H16.1213C16.2303 21.4146 16.3187 21.5026 16.3187 21.6114V22.0756H16.3184Z" fill="#965718"/>
        <path d="M13.0677 20.9596H10.0838C10.0538 20.9596 10.0293 20.9352 10.0293 20.9053V19.5397C10.0293 19.5098 10.0538 19.4854 10.0838 19.4854H13.0677C13.0977 19.4854 13.1222 19.5098 13.1222 19.5397V20.9053C13.1222 20.9352 13.0977 20.9596 13.0677 20.9596Z" fill="#EDAE00"/>
        <path d="M12.2531 13.8138C12.2531 14.3229 11.9463 14.7356 11.5679 14.7356C11.1896 14.7356 10.8828 14.3229 10.8828 13.8138C10.8828 13.3048 11.1896 12.8921 11.5679 12.8921C11.9463 12.8921 12.2531 13.3048 12.2531 13.8138Z" fill="#E88441"/>
        <path d="M11.9541 13.6743C11.9541 14.0384 11.7348 14.3334 11.4644 14.3334C11.1939 14.3334 10.9746 14.0384 10.9746 13.6743C10.9746 13.3106 11.1939 13.0156 11.4644 13.0156C11.7348 13.0156 11.9541 13.3106 11.9541 13.6743Z" fill="#EC984E"/>
        <path opacity="0.63" d="M11.7452 13.5444C11.7452 13.6929 11.6202 13.813 11.4663 13.813C11.3125 13.813 11.1875 13.6927 11.1875 13.5444C11.1875 13.3962 11.3125 13.2759 11.4663 13.2759C11.6202 13.2759 11.7452 13.3962 11.7452 13.5444Z" fill="#FFD5AA"/>
      </svg>
    </Icon>
  );
}
 
export default TournamentCup;
