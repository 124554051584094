import styles from './UserChat.module.css';
import Messages from './components/messages/Messages';
import MessageControl from './components/messageControl/MessageControl';
import { useRef } from 'react';
import { useCapacitorKeyboard } from '@/hooks/useCapacitorKeyboard';

const UserChat = () => {
  const controlRef = useRef<HTMLDivElement>(null);
  useCapacitorKeyboard(controlRef.current);

  return (
    <div className={styles.container} ref={controlRef}>
      <Messages />

      <div className={styles.control}>
        <MessageControl />
      </div>
    </div>
  );
}
 
export default UserChat;
