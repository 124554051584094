import Header from '@/components/Header/Header';
import styles from './LineupScorePage.module.css';
import { useCallback, useContext, useEffect, useState } from 'react';
import { GameResultsContext, LineupScoreContext } from '../GameResults/utils';
import LineupVersus from '../../../../components/LineupVersus/LineupVersus';
import LineupScoresList from '../components/LineupScoresList/LineupSetsScoresList';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Button, { ButtonVariants } from '@/components/Button/Button';
import { loadGameLineup, saveGameLineup } from '../service';
import { useParams } from 'react-router-dom';
import { GameLineupView } from '@/integration-api/server-rest-lundapadelApi';
import {
  LineupScoreTabsValues,
  ModifiedGameMatchView,
  ModifiedGameSetView,
} from '../GameResults/GameResultsPage.interfaces';
import LineupMatchesScoresList from '../components/LineupScoresList/LineupMatchesScoresList';
import { v4 } from 'uuid';
import { PuffLoader } from 'react-spinners';
import GameResultsModal from '../components/GameResultsModal/GameResultsModal';
import { ErrorUtils, getPlatform } from '@/utils/utils';
import UnderlineTabs from '@/components/UnderlineTabs/UnderlineTabs';

const tabs: Array<LineupScoreTabsValues> = ['match', 'set'];

const LineupScorePage = () => {
  const { activeUid, lockToken, updateActiveUid, updateStep } =
    useContext(GameResultsContext);
  const { setScores, matchScores, initScores } = useContext(LineupScoreContext);
  const { id } = useParams();

  const [activeLineup, setActiveLineup] = useState<GameLineupView>();
  const [activeTab, setActiveLineupTab] =
    useState<LineupScoreTabsValues>('match');
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const applyScores = useCallback(async () => {
    try {
      setLoading(true);
      const { result } = await saveGameLineup({
        ...activeLineup,
        gameSets: setScores,
        matches: matchScores,
        gameUid: id,
        lockToken,
        metrics: { system: getPlatform() },
      });
      if (result) {
        updateStep?.('lineup');
      }
    } catch (err) {
      ErrorUtils.handleErrorMessage(err);
      setModalVisible(true);
    } finally {
      setLoading(false);
    }
  }, [id, activeLineup, setScores, matchScores, lockToken]);

  useEffect(() => {
    if (activeUid && id) {
      setLoading(true);
      loadGameLineup({ gameUid: id, lineupUid: activeUid })
        .then(({ result }) => {
          setActiveLineup(result);
          const modifiedSets: Array<ModifiedGameSetView> =
            result?.gameSets?.map((set) => ({ ...set, uid: v4() })) ?? [];
          const modifiedMatches: Array<ModifiedGameMatchView> =
            result?.matches?.map((match) => ({
              ...match,
              gameSets: match.gameSets?.map((set) => ({ ...set, uid: v4() })),
              uid: v4(),
            })) ?? [];
          initScores?.({ sets: modifiedSets, matches: modifiedMatches });
          setLoading(false);
        })
        .catch((err) => ErrorUtils.handleErrorMessage(err));
    }
  }, [activeUid, id]);

  return (
    <>
      <GameResultsModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        type="error"
      />
      <Header
        className={styles['header']}
        handleClick={() => {
          initScores?.({ matches: [], sets: [] });
          updateActiveUid?.(undefined);
          updateStep?.('lineup');
        }}
      >
        Выставление счета
      </Header>
      <div className={styles['wrapper']}>
        {loading ? (
          <PuffLoader color="#4EB857" cssOverride={{ margin: 'auto' }} />
        ) : (
          <>
            <LineupVersus lineup={activeLineup ?? {}} />
            <UnderlineTabs
              className={styles['tabs']}
              tabs={tabs}
              name="lineupScores"
              activeTab={activeTab}
              selectTab={(e) =>
                setActiveLineupTab(e.target.value as LineupScoreTabsValues)
              }
            />
            {activeLineup ? (
              <>
                {activeTab === 'match' ? (
                  <LineupMatchesScoresList />
                ) : (
                  <LineupScoresList />
                )}
              </>
            ) : null}
          </>
        )}
      </div>
      <OversideWrapper className={styles['button-wrapper']}>
        <Button
          variant={ButtonVariants.YELLOW}
          onClick={applyScores}
          disabled={loading || !lockToken}
        >
          Сохранить
        </Button>
      </OversideWrapper>
    </>
  );
};

export default LineupScorePage;
