import { FC } from 'react';
import { SearchRecentProps } from './SearchRecent.interfaces';
import styles from './SearchRecent.module.css';
import Headling from '@/components/Headling/Headling';
import PlayerItem from '@/components/PlayerItem/PlayerItem';
import { ItemType } from '@/components/PlayerItem/PlayerItem.interfaces';
import useSearchRecent from './useSearchRecent';
import ListOverlayLoader from './ListOverlayLoader';

const SearchRecent: FC<SearchRecentProps> = ({
  title,
  isInvitation,
  updateSuggestions,
  handleClick,
  invitationsList,
  addPlayer,
  invitedPlayers,
  fetchParams,
}): JSX.Element => {
  const { suggestions, listRef, loading } = useSearchRecent(
    fetchParams,
    invitationsList
  );

  const renderSuggestions = () => {
    return suggestions
      ? suggestions.map((sugg) => {
          const isAlreadySelect = !!addPlayer
            ? invitedPlayers?.some((player) => player.uid === sugg.uid)
            : invitationsList?.some((el) => el.player?.uid === sugg.uid);
          return (
            <PlayerItem
              key={sugg?.uid}
              type={ItemType.SEARCH}
              player={sugg}
              isInvitation={isInvitation}
              handleClick={handleClick}
              updateSuggestions={updateSuggestions}
              isAlreadySelect={isAlreadySelect}
              addPlayer={addPlayer}
            />
          );
        })
      : null;
  };

  return (
    <section className={styles['recent']} ref={listRef}>
      {!suggestions && (
        <Headling appearence="big-bold" className={styles['headling']}>
          {title ? title : ''}
        </Headling>
      )}
      <div className={styles['items-wrapper']}>
        {renderSuggestions()}
        <ListOverlayLoader
          loading={loading}
          className={styles['overlay-loader']}
        />
      </div>
    </section>
  );
};

export default SearchRecent;
