import styles from './RatingPage.module.css';
import { useEffect, useRef, useState } from 'react';
import FindMeButton from './components/FindMeButton/FindMeButton';
import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import Header from '@/components/Header/Header';
import PageBackground from '@/components/PageBackground/PageBackground';
import bgImg from '@/static/images/rating-page-background.png';
import { useAppSelector } from '@/hooks/hooks';
import Input from '@/components/Input/Input';
import searchIcon from '@/static/images/icons/search-icon.svg';
import filterIcon from '@/static/images/icons/filter.svg';
import InnerPadding from '@/components/InnerPadding/InnerPadding';
import FilterModal from '@/components/FilterModal/FilterModal';
import {
  GradeDetailsInfo,
  PlayerProfileInfo,
  PlayerProfileLoadListResult,
} from '@/integration-api/server-rest-lundapadelApi';
import {
  getSavedSettings,
  gradeInfoLoadList,
  playerLoadList,
  saveSearchParams,
} from './service';
import RatingSection from './components/RatingSection/RatingSection';
import ballIcon from '@/static/images/icons/notification-ball-icon.svg';
import {
  getFirstPlayerIndexByGrade,
  getPlayerIndex,
  objectToSearchParams,
  searchParamsToObject,
} from './utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swiper, { chldrenWidth } from './components/swiper/Swiper';
import RatingPlayersList from './components/RatingPlayersList/RatingPlayersList';

export let scrollToUser = {
  condition: false,
};

function RatingPage(): JSX.Element {
  const timer = useRef<NodeJS.Timeout>();
  const playerListRef = useRef<HTMLDivElement>(null);
  const currentUser = useAppSelector(
    (state) => state.auth.currentProfile ?? {}
  );
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const activeSwiperIndex = useRef<number>(0);
  const initialSearchParams = useRef<string>(window.location.search);

  const [loadedPlayersSummary, setLoadedPlayersSummary] =
    useState<PlayerProfileLoadListResult>();
  const [gradesList, setGradesList] = useState<
    GradeDetailsInfo[] | undefined
  >();
  const [searchParams, setSearchParams] = useSearchParams(
    window.location.search
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [readyToScroll, setReadyToScroll] = useState(false);
  const [initialState, setInitiialState] = useState(true);

  const emptyArray: PlayerProfileInfo[] = [];
  const playersList = loadedPlayersSummary?.infos || emptyArray;

  const scrollToPlayer = (playerIndex: number) => {
    if (playerIndex >= 0)
      // @ts-ignore
      playerListRef.current?.scrollToItem(playerIndex, 'start');
  };

  const playersListScrollToGrade = (gradeIndex: number) => {
    if (playersList.length > 0 && gradesList) {
      // @ts-ignore
      playerListRef.current?.scrollToItem(
        getFirstPlayerIndexByGrade(playersList, gradesList?.[gradeIndex]),
        'start'
      );
      activeSwiperIndex.current = gradeIndex;
    }
  };

  const swiperSlideTo = (
    gradeIndex: number,
    behavior: ScrollBehavior = 'smooth'
  ) => {
    if (activeSwiperIndex.current !== gradeIndex) {
      containerRef.current?.scrollTo({
        behavior,
        left: chldrenWidth * gradeIndex + 1,
      });
    }
    activeSwiperIndex.current = gradeIndex;
  };

  const handleTextPatternChange = (textPattern: string) => {
    if (timer.current) clearTimeout(timer.current);

    timer.current = setTimeout(() => {
      const updatedSearchParams = new URLSearchParams(searchParams);
      updatedSearchParams.set('textPattern', textPattern);
      setSearchParams(updatedSearchParams);
      initialSearchParams.current = window.location.search;

      playerLoadList(searchParamsToObject(updatedSearchParams)).then(
        ({ result }) => {
          setLoadedPlayersSummary(result);

          const gradeIndex = gradesList?.findIndex(
            (grade) => grade.grade === result?.infos?.[0]?.grade
          );
          swiperSlideTo(gradeIndex || 0);
          if (gradeIndex && gradeIndex !== -1)
            playersListScrollToGrade(gradeIndex);
        }
      );
    }, 500);
  };

  const handleFindMeClick = () => {
    const playerIndex = getPlayerIndex(playersList, currentUser.identity?.uid!);

    if (gradesList && playerIndex > -1) {
      const gradeIndex = gradesList.findIndex(
        (g) => g.displayGrade === currentUser.displayGrade
      );
      if (initialState) {
        setTimeout(() => {
          scrollToPlayer(playerIndex - 3);
          swiperSlideTo(gradeIndex < 0 ? 0 : gradeIndex);
          setInitiialState(false);
        }, 100);
      } else {
        swiperSlideTo(gradeIndex < 0 ? 0 : gradeIndex);
        scrollToPlayer(playerIndex - 3);
      }
    }
  };

  const handleFilterModalDone = (newSearchParams: URLSearchParams) => {
    const newSearchParamsStr = newSearchParams.toString();
    const oldSearchParamsStr = new URLSearchParams(
      initialSearchParams.current
    ).toString();

    if (newSearchParamsStr !== oldSearchParamsStr) {
      const currentSearchParams = searchParamsToObject(newSearchParams);

      saveSearchParams({
        onlyPartners: currentSearchParams.onlyPartners === 'true',
        onlyUnverified: currentSearchParams.onlyUnverified === 'true',
      })
        .then(() => {
          playerLoadList(searchParamsToObject(newSearchParams)).then(
            ({ result }) => {
              setLoadedPlayersSummary(result);
              const gradeIndex = gradesList?.findIndex(
                (grade) => grade.grade === result?.infos?.[0]?.grade
              );
              swiperSlideTo(gradeIndex || 0);
              scrollToPlayer(0);
            }
          );
          initialSearchParams.current = window.location.search;
        })
        .catch((e) => console.log(e));
    }
    setModalVisible(false);
  };

  const handleBackBtnClick = () => {
    const lastPath = JSON.parse(sessionStorage.getItem('lastPaths') || '[]');
    if (lastPath?.[0]) {
      navigate(lastPath[0]);
    }
  };

  useEffect(() => {
    getSavedSettings().then(({ result }) => {
      const lostHash = window.location.hash;
      setSearchParams(objectToSearchParams(result!));
      window.location.hash = lostHash;

      initialSearchParams.current = window.location.search;

      const playersFetch = playerLoadList(result!);
      const gradesFetch = gradeInfoLoadList();

      Promise.all([playersFetch, gradesFetch])
        .then(([{ result: players }, { result: grades }]) => {
          setLoadedPlayersSummary(players);
          setGradesList(grades?.infos?.reverse() || []);
          setTimeout(() => setReadyToScroll(true));
        })
        .catch((e) => console.log(e));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (readyToScroll) {
      if (playersList?.length && gradesList?.length) {
        const lastURLPath: string | undefined = JSON.parse(
          sessionStorage.getItem('lastPaths') || ''
        )?.[0];

        if (
          scrollToUser.condition &&
          lastURLPath?.includes('profile') &&
          !lastURLPath.endsWith('me')
        ) {
          const playerUid = lastURLPath.slice(lastURLPath.lastIndexOf('/') + 1);
          const playerIndex = getPlayerIndex(playersList, playerUid);
          const gradeIndex = gradesList.findIndex(
            (grade) => grade.grade === playersList?.[playerIndex]?.grade
          );
          setTimeout(() => {
            scrollToPlayer(playerIndex);
            swiperSlideTo(gradeIndex);
          }, 100);
        } else {
          handleFindMeClick();
        }
      }
      scrollToUser.condition = false;

      setReadyToScroll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToScroll]);

  return (
    <BasePageWrapper
      contentWrapperClassName={styles['content-wrapper']}
      showNavBar
    >
      <PageBackground imgSrc={bgImg} />
      <Header
        handleClick={handleBackBtnClick}
        additional={<FindMeButton onClick={handleFindMeClick} />}
        hideBackButton={true}
      >
        Рейтинг
      </Header>

      <InnerPadding className={styles['input-field']}>
        <img
          src={searchIcon}
          alt="Поиск"
          className={styles['input-field-icon']}
        />
        <Input
          placeholder="Поиск"
          wrapperClassName={styles['input-wrap']}
          className={styles['input']}
          icon={
            <div style={{ position: 'relative' }}>
              {(searchParams.toString().includes('true') ||
                !!searchParams.get('cityFilter')) && (
                <img
                  src={ballIcon}
                  alt="иконка мячика"
                  className={styles['filters-selected']}
                />
              )}
              <img
                src={filterIcon}
                alt="Фильтр"
                onClick={() => setModalVisible(true)}
              />
            </div>
          }
          onChange={(e) => handleTextPatternChange(e.target.value)}
        />
      </InnerPadding>

      {searchParams.get('sortByRating') !== 'true' ? (
        <>
          <Swiper
            containerRef={containerRef}
            onChange={playersListScrollToGrade}
            grades={gradesList || []}
          />

          <RatingSection
            onPlayerIntersecting={(index: number) => {
              swiperSlideTo(index);
            }}
            playersList={playersList}
            grades={gradesList!}
            playerListWrapRef={playerListRef}
          />
        </>
      ) : (
        <RatingPlayersList
          playerListWrapRef={playerListRef}
          playersList={playersList}
        />
      )}

      <FilterModal visible={modalVisible} onDone={handleFilterModalDone} />
    </BasePageWrapper>
  );
}

export default RatingPage;
