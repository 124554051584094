import { ConfirmationModalProps } from '@/components/ConfirmationModal/ConfirmationModal.interfaces';
import styles from './BookingStatusModal.module.css';
import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal';
import { useMemo } from 'react';
import ModalBallIcon from '@/static/images/icons/ModalBallIcon';
import ModalArrowIcon from '@/static/images/icons/ModalArrowIcon';
import { BookingTimeStatus } from '../../GameCreate/GameCreatePage.interfaces';

type BookingStatusModalProps = {
  type: BookingTimeStatus;
} & ConfirmationModalProps;

const BookingStatusModal = ({ type, ...props }: BookingStatusModalProps) => {
  const renderModalContent = useMemo(() => {
    switch(type) {
      case 'booked': {
        return (
          <>
            <div className={styles['question']}>
              <span style={{ color: 'var(--red)' }}>Это время забронировано</span>
            </div>
            <div className={styles['description']}>
              Выберите другое доступное время для бронирования корта. Все участники игры получат уведомления о смене времени/клуба
            </div>
            <button className={styles['button']} onClick={props.onClose}>
              <div className={styles['button-inner']}>
                <ModalBallIcon/>
                <span>Перейти к бронированию</span>
                <ModalArrowIcon/>
              </div>
            </button>
          </>
        );
      }
      case 'free': {
        return (
          <>
            <div className={styles['question']}>
              <span style={{ color: 'var(--green-lunda)'}}>Это время свободно</span>
            </div>
            <div className={styles['description']}>
              Выберите корт для бронирования и оплаты. Все участники получат уведомления о бронировании корта
            </div>
            <button className={styles['button']} onClick={props.onClose}>
              <div className={styles['button-inner']}>
                <ModalBallIcon/>
                <span>Перейти к бронированию</span>
                <ModalArrowIcon/>
              </div>
            </button>
          </>
        );
      }
      default: {
        const _: never = type;
        throw new Error('not every type is handled');
      }
    }
  }, [props.onClose, type]);

  return (  
    <ConfirmationModal {...props}>
      {renderModalContent}
    </ConfirmationModal>
  );
}
 
export default BookingStatusModal;
