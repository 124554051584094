import s from "./DialogBlock.module.css";
import { DialogBlockProps } from "../ChatPage.intefaces";
import { FeedbackMessageView } from "@/integration-api/server-rest-get-info";
import DialogTimeItem from "../DialogTimeItem/DialogTimeItem";
import { useContext, useEffect, useMemo } from "react";
import { ChatContext } from "../ChatContext";
import MapAddGrayIcon from "@/components/Icon/components/MapAddGray";
import EmptyChatIcon from "@/components/Icon/components/EmptyChat";
import { useLocation } from "react-router-dom";

export interface dialogMockData {
  name?: string,
  message?: string,
  time?: string,
  incoming?: boolean,
}

const DialogBlock = <T extends FeedbackMessageView>({ messagesBlocks }: DialogBlockProps<T>) => {
  const { dialogBlockRef } = useContext(ChatContext);
  const { search } = useLocation();

  const renderEmpryChatContent = useMemo(() => {
    switch (search) {
      case '?clubs' : {
        return (
        <div className={s.emptyChat}>
          <div className={s.emptyText}>
            Для добавления клуба отправьте его название и адрес
          </div>
          <MapAddGrayIcon iconSize={'large'} />
        </div>
        )
      }

      default : {
        return (
        <div className={s.emptyChat}>
          <div className={s.emptyText}>
            У вас есть вопрос или предложение, возникла проблема или вам нужна информация – напишите нам
          </div>
          <EmptyChatIcon iconSize="large"/>
        </div>
        )
      }
    }
  }, [search])

  useEffect(() => {
    if(dialogBlockRef?.current && messagesBlocks.length) {
      dialogBlockRef.current.scrollTo({ top: dialogBlockRef.current.scrollHeight })
    }
  }, [dialogBlockRef?.current, messagesBlocks.length])

  return (
    <div className={s.wrap} ref={dialogBlockRef}>
      {messagesBlocks.length === 0 ?
        renderEmpryChatContent :
        <div className={s.chatWithMessages}>
          {messagesBlocks.map((entry, index) =>
            <DialogTimeItem<any>
              key={index}
              //ebal v rot ebanyi typescript
              //@ts-ignore
              messagesBlock={entry}
            />
          )}
        </div>
      }
    </div>
  )
}

export default DialogBlock;
