import Input from '@/components/Input/Input';
import personIcon from '@/static/images/icons/person-icon.svg';
import HLWrapper from '@/components/HLWrapper/HLWrapper';
import LundaPadelLogo from '@/components/LundaPadelLogo/LundaPadelLogo';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Button from '@/components/Button/Button';
import styles from './RegisterStepOne.module.css';
import { RegisterStepProps } from '../../helpers/interfaces';
import { getQuestionaries, saveProfile } from '../../service';
import { useEffect, useRef, useState } from 'react';
import { FetchErrorMessage } from '@/types/types';
import RadioButton from '@/components/RadioButton/RadioButton';
import { Gender, toLocalDate } from '@/integration-api/server-rest-lundapadelApi';
import DatePicker from '@/components/Formik/DatePicker/DatePicker';
import dayjs from 'dayjs';
import FullscreenLoader from '@/components/FullscreenLoader/FullscreenLoader';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

let initialLoad = true;

function RegisterStepOne(props: RegisterStepProps) {
  const { formik, deletedUser, fieldsErrors, setSelectedDate, onSave, updateProfile } = props;
  const timer = useRef<NodeJS.Timeout>();
  const [isLoad, setIsLoad] = useState(false);

  const handleContinueBtnClick = () => {
    if(deletedUser) {
      const { values } = formik
      const fetchData = {
        ...values,

        birthday: toLocalDate(dayjs(formik.values.birthday)),
        tournamentParticipation : formik.values.tournamentParticipation === "" ? undefined : formik.values.tournamentParticipation 
      }

      if (values.birthday && values.firstName && values.lastName && deletedUser) {
        setIsLoad(true);
          saveProfile(fetchData, 'Регистрация')
          .then(({ result }) => {
            updateProfile?.(result ?? {});
            sessionStorage.setItem('profileUid', result?.identity?.uid ?? '');
          })
          .then(() => {
            sessionStorage.removeItem('authData')
            onSave?.()
          })
          .catch(e => {
            if(e instanceof Promise) {
              e.then(err => {
                const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
                PopupMessage.open(userErrorMessage ?? errorMessage);
              })
            }
          })
          .finally(() => setIsLoad(false));
      }
    } else {
      formik.values.firstName?.trim() && formik.values.lastName?.trim() &&
        onSave?.()
    }
  }

  useEffect(() => {
    if(!deletedUser && !initialLoad) {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        saveProfile(formik.values, 'Анкета')
          .catch(e => {
            if(e instanceof Promise) {
              e.then(err => {
                const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
                PopupMessage.open(userErrorMessage ?? errorMessage);
              })
            }
          })
      }, 1000)
    }
  }, [formik.values, deletedUser, initialLoad]);

  useEffect(() => {
    const authData = sessionStorage.getItem('authData');
    if(authData) {
      const parsedAuthData = JSON.parse(authData);
      getQuestionaries(parsedAuthData)
        .then(({ result }) => {
          formik.setValues(values => ({
            ...values,
            firstName: result?.firstName,
            lastName: result?.lastName,
            birthday: result?.birthday ?? values?.birthday,
            gender: result?.gender ?? values?.gender,
            experience: result?.experience ?? values?.experience,
            playedMatches: result?.playedMatches ?? values?.playedMatches,
            fitnessLevel: result?.fitnessLevel ?? values?.fitnessLevel,
            grade: result?.grade ?? values?.grade,
            tournamentParticipation: result?.tournamentParticipation ?? values?.tournamentParticipation
          }))
          initialLoad = false;
        })
        .catch(err => console.log(err));
    }
  }, []);

  useEffect(() => {
    return () => {
      initialLoad = true;
    }
  }, []);

  return (
    <div className={`${styles.container}`}>
      <div className={styles['main-content']}>
        <HLWrapper>
          <LundaPadelLogo />
        </HLWrapper>

        <HLWrapper className={styles.inputName}>
          <Input
            autoComplete='off'
            {...formik.getFieldProps?.('firstName')}
            placeholder="Имя"
            enterKeyHint='done'
            icon={<img src={personIcon} alt="person" />}
          />
        </HLWrapper>
        <HLWrapper>
          <Input
            autoComplete='off'
            {...formik.getFieldProps?.('lastName')}
            placeholder="Фамилия"
            enterKeyHint='done'
            icon={<img src={personIcon} alt="person" />}
          />
        </HLWrapper>
        {deletedUser ?
          <>
            <div className={styles.radioContainer}>
              <h3 className={styles.radioContainerHeader}>Укажите ваш пол</h3>
              <RadioButton
                name="gender"
                onChange={formik.handleChange}
                defaultChecked={formik.values.gender === Gender.MAN ?? false}
                value={Gender.MAN}
                label="Мужской"
              />
              <RadioButton
                name="gender"
                onChange={formik.handleChange}
                defaultChecked={formik.values.gender === Gender.WOMAN ?? false}
                value={Gender.WOMAN}
                label="Женский"
              />
            </div>
            <h3 className={styles.header}>Дата рождения</h3>

            <div className={styles.dateContainer}>
              <HLWrapper fieldsErrors={fieldsErrors?.includes('birthday')}>
                <DatePicker title='Выберите дату рождения'
                  {...formik.getFieldProps('birthday')}
                  isBirthday
                  formik={formik}
                  setSelectedDate={setSelectedDate}
                />
              </HLWrapper>
            </div>
          </>
          : null
        }
      </div>

      <OversideWrapper className={styles.submitBtn}>
        <Button onClick={handleContinueBtnClick}>Продолжить</Button>
      </OversideWrapper>

      <FullscreenLoader loading={isLoad} />
    </div>
  );
}

export default RegisterStepOne;
