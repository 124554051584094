import { useEffect, useState } from 'react';
import RestClient from '@/integration/RestClient';
import { TicketType } from '@/integration-api/server-rest-lundapadelApi';

export function useWebsocketTicket(entityUid?: string | null, entityType?: TicketType) {
  const [ticket, setTicket] = useState('');

  useEffect(() => {
    if (entityUid && entityType) {
      RestClient.mainApi.websocketTicketRegister({
        relatedEntityUid: entityUid,
        ticketType: entityType,
      })
        .then(({ result }) => setTicket(result?.ticket!))
        .catch((e) => {console.log(e)});
    }
  }, [entityUid]);

  let URL: null | string = null;

  if(ticket) {
    switch(true) {
      case (process.env.REACT_APP_ENVIROMENT === 'development') || (window.location.hostname.includes('dev')):
        URL = `wss://app.lundapadel-dev.gridnine.com/api-ws/websocket-listen?ticket=${ticket}`;
        break;
      case (process.env.REACT_APP_ENVIROMENT === 'staging') || (window.location.hostname.includes('stag')):
        URL = `wss://app.lundapadel-stag.gridnine.com/api-ws/websocket-listen?ticket=${ticket}`;
        break;
      default: 
        // URL = `wss://app.lundapadel-stag.gridnine.com/api-ws/websocket-listen?ticket=${ticket}`;
        URL = `wss://app.lundapadel.ru/api-ws/websocket-listen?ticket=${ticket}`;
        break;
    }
  }

  return URL;
}
