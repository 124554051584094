import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import { ViewSubscriptionPageProps } from './viewSubscriptionPage.interfaces';
import Header from '@/components/Header/Header';
import { useEffect, useState } from 'react';
import { MetricScreenView, SubscriptionView, TourGameEntityType, TourGameInfo } from '@/integration-api/server-rest-lundapadelApi';
import styles from './viewSubscriptionPage.module.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import FullscreenLoader from '@/components/FullscreenLoader/FullscreenLoader';
import { fetchSubscription, getSubscriptionTG } from './service';
import { daysLabesShort } from '../components/DaysModal/utils';
import EditGameIcon from '@/static/images/icons/EditGameIcon';
import TrashIcon from '@/static/images/icons/TrashIcon';
import { deleteSubscription } from '../index/service';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';
import GameListCard from '@/components/GameListCard/GameListCard';
import TournamentCard from '@/components/TournamentCard/TournamentCard';
import EmptyMatchListPlaceholder from '@/components/EmptyMatchListPlaceholder/EmptyMatchListPlaceholder';
import { FetchErrorMessage } from '@/types/types';
import { ErrorUtils, getPlatform } from '@/utils/utils';

const ViewSubscriptionPage: React.FC<ViewSubscriptionPageProps> = () => {
  // hooks
  const { id: subscriptionUid } = useParams();
  const navigate = useNavigate();
  
  // states
  const [tgInfo, setTgInfo] = useState<TourGameInfo[]>([]);
  const [ subscription, setSubscription ] = useState<SubscriptionView>({});
  const [isLoading, setIsLoading] = useState(false);

  // handlers
  const handleDeleteCard = () => {
    setIsLoading(true);
    
    if (subscriptionUid) {
      deleteSubscription({
        uid: subscriptionUid,
        metrics: {
          system: getPlatform(),
          screen: MetricScreenView.SUBSCRIPTION_SCREEN
        }
      })
        .then(() => {
          PopupMessage.open('Подписка удалена', 'success');
          navigate('/subscriptions');
        })
        .catch((err) => {
          if(err instanceof Promise) {
            ErrorUtils.handleErrorMessage(err);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }

  // effects
  useEffect(() => {
    if (subscriptionUid) {
      setIsLoading(true);

      Promise.all([fetchSubscription(subscriptionUid), getSubscriptionTG(subscriptionUid)])
        .then(([{ result: subscription }, {result: tgInfo}]) => {
          if (subscription) setSubscription(subscription);
          if (tgInfo?.infos) setTgInfo(tgInfo?.infos!);
        })
        .catch((err) => {
          if(err instanceof Promise) {
            err.then(err => {
              const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
              PopupMessage.open(userErrorMessage ?? errorMessage);
            })
          }
        })
        .finally(() => setIsLoading(false));
    };
  }, []);

  // render vars
  const headerData = {
    title: !!subscription?.clubs?.length && (subscription?.clubs?.length === 1 ? subscription.clubs?.[0].name : `${subscription.clubs?.[0]?.name} +${subscription.clubs.length - 1}`),
    subTitle: `${subscription.days?.map((day) => daysLabesShort[day]).join(',')} | ${subscription?.startTime?.slice(0, 5)} - ${subscription?.endTime?.slice(0, 5)}`
  }

  const headerAdditional = (
    <div className={styles.headerAdditional}>
      <Link to="edit"><EditGameIcon /></Link>
      <TrashIcon onClick={handleDeleteCard} />
    </div>
  )

  return (
    <BasePageWrapper className={styles.wrap} contentWrapperClassName={styles.wrapContent}>
      <Header additional={headerAdditional} handleClick={() => navigate('/subscriptions')} className={styles['header']}>
        {subscription ? (
          <div className={styles.headerInfo}>
            <div className={styles.headerTitle}>{headerData.title}</div>
            <div className={styles.headerSubtitle}>{headerData.subTitle}</div>
          </div>
        ) : null}
      </Header>
      {tgInfo.length ?      
        <div className={styles.tgList}>
          {tgInfo.map((tg) => tg.type === TourGameEntityType.GAME ? (
            <Link to={`/game/${tg.uid}`} key={tg.uid}>
              <GameListCard game={tg}  />
            </Link>
          ) : (
            <Link to={`/tournament/${tg.uid}`} key={tg.uid}>
              <TournamentCard tournament={tg} restricted />
            </Link>
          ))}
        </div> : 
        <EmptyMatchListPlaceholder
          className={styles['empty-subscription']}
          title='По вашей подписке найдено 0 игр'
          action='editSubscription'
          bgImage
        />
      }

      <FullscreenLoader loading={isLoading} />
    </BasePageWrapper>
  );
}
 
export default ViewSubscriptionPage;
