type LocationIconProps = {
  fill?: string
}

const LocationIcon = ({ fill }: LocationIconProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.83333 9.91667C9.2808 9.91667 8.75089 9.69717 8.36019 9.30647C7.96949 8.91577 7.75 8.38587 7.75 7.83333C7.75 7.2808 7.96949 6.7509 8.36019 6.36019C8.75089 5.96949 9.2808 5.75 9.83333 5.75C10.3859 5.75 10.9158 5.96949 11.3065 6.36019C11.6972 6.7509 11.9167 7.2808 11.9167 7.83333C11.9167 8.10692 11.8628 8.37783 11.7581 8.63059C11.6534 8.88335 11.4999 9.11302 11.3065 9.30647C11.113 9.49993 10.8834 9.65339 10.6306 9.75808C10.3778 9.86278 10.1069 9.91667 9.83333 9.91667ZM9.83333 2C8.28624 2 6.80251 2.61458 5.70854 3.70854C4.61458 4.80251 4 6.28624 4 7.83333C4 12.2083 9.83333 18.6667 9.83333 18.6667C9.83333 18.6667 15.6667 12.2083 15.6667 7.83333C15.6667 6.28624 15.0521 4.80251 13.9581 3.70854C12.8642 2.61458 11.3804 2 9.83333 2Z" fill={fill ?? "#C3D82E"}/>
    </svg>
  );
}
 
export default LocationIcon;
