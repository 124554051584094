import { HTMLAttributes, useMemo } from 'react';
import styles from './EmptyMatchListPlaceholder.module.css';
import newGameIcon from '@/static/images/icons/new-game-placeholder-icon.svg';
import placeholderBg from '@/static/images/empty-matches-placeholder-bg.png';
import { useNavigate, useParams } from 'react-router-dom';

interface EmptyMatchListPlaceholderProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  bgImage?: boolean;
  action: 'selectFavoriteClubs' | 'newGame' | 'wipeFiltersOrNewGame' | 'hasNoSubscriptions' | 'editSubscription' | 'emptyActiveReservations' | 'emptyExpiredReservations';
  clearFilters?: () => void;
}

const EmptyMatchListPlaceholder = ({ title, bgImage, action, clearFilters, className, ...props }: EmptyMatchListPlaceholderProps) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const renderActionText = useMemo(() => {
    switch(action) {
      case 'selectFavoriteClubs':
        return (
          <div className={styles['action']} onClick={() => navigate('/profile/me/edit?favoriteClubs')}>
            <span>Выбрать любимые клубы</span>
            <img src={newGameIcon} alt="новая игра" />
          </div>
        );
      case 'wipeFiltersOrNewGame': 
        return (
          <div className={styles['filters-action']}>
            <span onClick={clearFilters}>Сбросить фильтры</span>
            <span style={{ color: 'var(--gray-dark)' }}> или </span>
            <span onClick={() => navigate('/create/game')}>создайте новую игру</span>
          </div>
        );
      case 'newGame':
        return (
          <div className={styles['action']} onClick={() => navigate('/create/game')}>
            <span>Создать новую игру</span>
            <img src={newGameIcon} alt="новая игра" />
          </div>
        );
        case 'hasNoSubscriptions': 
          return (
            <div className={styles['action']} onClick={() => navigate(`/subscriptions/create?fromList`)}>
              <span>Создать подписку</span>
              <img src={newGameIcon} alt="новая игра" />
            </div>
          );
      case 'editSubscription': 
        return (
          <div className={styles['action']} onClick={() => navigate(`/subscriptions/${id}/edit?center`)}>
            <span>Редактировать подписку</span>
            <img src={newGameIcon} alt="новая игра" />
          </div>
        );
      case 'emptyActiveReservations' :
        return (
          <div className={styles['action']} onClick={() => navigate(`/create/game?fromReservations`)}>
            <span>Создать игру с бронированием (Доступно в клубах Lunda Речной)</span>
            <img src={newGameIcon} alt="новая игра" />
          </div>
        );
      case 'emptyExpiredReservations' :
        return (
          <div className={styles['action']} onClick={() => navigate(`/create/game?fromReservations`)}>
            <span>Создать игру с бронированием (Доступно в клубах Lunda Речной)</span>
            <img src={newGameIcon} alt="новая игра" />
          </div>
        );
      default:
        const _: never = action;
        throw new Error('Not every action is handled');
    }
  }, [action, id]);

  return ( 
    <div {...props} className={`${className} ${styles['wrapper']}`}>
      <div className={styles['title']}>{title}</div>
      {renderActionText}
      {bgImage ? <img className={styles['bg-image']} src={placeholderBg} alt="ракетка" /> : null}
    </div>
  );
}
 
export default EmptyMatchListPlaceholder;
