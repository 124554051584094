type ClockIconProps = {
  fill?: string;
}

const ClockIcon = ({ fill }: ClockIconProps) => {
  return (  
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 3C9.64641 3 8.80117 3.16813 8.01256 3.49478C7.22394 3.82144 6.50739 4.30023 5.90381 4.90381C4.68482 6.12279 4 7.77609 4 9.5C4 11.2239 4.68482 12.8772 5.90381 14.0962C6.50739 14.6998 7.22394 15.1786 8.01256 15.5052C8.80117 15.8319 9.64641 16 10.5 16C12.2239 16 13.8772 15.3152 15.0962 14.0962C16.3152 12.8772 17 11.2239 17 9.5C17 8.64641 16.8319 7.80117 16.5052 7.01256C16.1786 6.22394 15.6998 5.50739 15.0962 4.90381C14.4926 4.30023 13.7761 3.82144 12.9874 3.49478C12.1988 3.16813 11.3536 3 10.5 3ZM13.23 12.23L9.85 10.15V6.25H10.825V9.63L13.75 11.385L13.23 12.23Z" fill={fill ?? "#C3D82E"}/>
    </svg>
  );
}
 
export default ClockIcon;
