/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreatePaymentResult,
    CreatePaymentResultFromJSON,
    CreatePaymentResultFromJSONTyped,
    CreatePaymentResultToJSON,
} from './CreatePaymentResult';

/**
 * 
 * @export
 * @interface CreateYookassaPaymentResponse
 */
export interface CreateYookassaPaymentResponse {
    /**
     * 
     * @type {CreatePaymentResult}
     * @memberof CreateYookassaPaymentResponse
     */
    result?: CreatePaymentResult;
    /**
     * 
     * @type {Array<object>}
     * @memberof CreateYookassaPaymentResponse
     */
    messages?: Array<object>;
}

export function CreateYookassaPaymentResponseFromJSON(json: any): CreateYookassaPaymentResponse {
    return CreateYookassaPaymentResponseFromJSONTyped(json, false);
}

export function CreateYookassaPaymentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateYookassaPaymentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : CreatePaymentResultFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function CreateYookassaPaymentResponseToJSON(value?: CreateYookassaPaymentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': CreatePaymentResultToJSON(value.result),
        'messages': value.messages,
    };
}

