/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalDate,
    LocalDateFromJSON,
    LocalDateFromJSONTyped,
    LocalDateToJSON,
} from './LocalDate';
import {
    Reference,
    ReferenceFromJSON,
    ReferenceFromJSONTyped,
    ReferenceToJSON,
} from './Reference';
import {
    RelatedEntityType,
    RelatedEntityTypeFromJSON,
    RelatedEntityTypeFromJSONTyped,
    RelatedEntityTypeToJSON,
} from './RelatedEntityType';

/**
 * 
 * @export
 * @interface BookingParameters
 */
export interface BookingParameters {
    /**
     * 
     * @type {Reference}
     * @memberof BookingParameters
     */
    club?: Reference;
    /**
     * 
     * @type {string}
     * @memberof BookingParameters
     */
    relatedEntityUid?: string;
    /**
     * 
     * @type {RelatedEntityType}
     * @memberof BookingParameters
     */
    relatedEntityType?: RelatedEntityType;
    /**
     * 
     * @type {LocalDate}
     * @memberof BookingParameters
     */
    date?: LocalDate;
    /**
     * 
     * @type {string}
     * @memberof BookingParameters
     */
    time?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingParameters
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingParameters
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof BookingParameters
     */
    duration?: number;
    /**
     * 
     * @type {Array<Reference>}
     * @memberof BookingParameters
     */
    courts?: Array<Reference>;
}

export function BookingParametersFromJSON(json: any): BookingParameters {
    return BookingParametersFromJSONTyped(json, false);
}

export function BookingParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'club': !exists(json, 'club') ? undefined : ReferenceFromJSON(json['club']),
        'relatedEntityUid': !exists(json, 'relatedEntityUid') ? undefined : json['relatedEntityUid'],
        'relatedEntityType': !exists(json, 'relatedEntityType') ? undefined : RelatedEntityTypeFromJSON(json['relatedEntityType']),
        'date': !exists(json, 'date') ? undefined : LocalDateFromJSON(json['date']),
        'time': !exists(json, 'time') ? undefined : json['time'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'courts': !exists(json, 'courts') ? undefined : ((json['courts'] as Array<any>).map(ReferenceFromJSON)),
    };
}

export function BookingParametersToJSON(value?: BookingParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'club': ReferenceToJSON(value.club),
        'relatedEntityUid': value.relatedEntityUid,
        'relatedEntityType': RelatedEntityTypeToJSON(value.relatedEntityType),
        'date': LocalDateToJSON(value.date),
        'time': value.time,
        'fullName': value.fullName,
        'email': value.email,
        'duration': value.duration,
        'courts': value.courts === undefined ? undefined : ((value.courts as Array<any>).map(ReferenceToJSON)),
    };
}

