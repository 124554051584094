import { PuffLoader } from 'react-spinners';
import { FC } from 'react';

interface ListOverlayLoaderProps {
  loading: boolean;
  className: string;
}

const ListOverlayLoader: FC<ListOverlayLoaderProps> = ({
  loading,
  className,
}) => {
  return loading ? (
    <div className={className}>
      <PuffLoader color="#4EB857" />
    </div>
  ) : null;
};

export default ListOverlayLoader;
