/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TournamentView,
    TournamentViewFromJSON,
    TournamentViewFromJSONTyped,
    TournamentViewToJSON,
} from './TournamentView';

/**
 * 
 * @export
 * @interface UpdateTournamentRequest
 */
export interface UpdateTournamentRequest {
    /**
     * 
     * @type {TournamentView}
     * @memberof UpdateTournamentRequest
     */
    parameters?: TournamentView;
}

export function UpdateTournamentRequestFromJSON(json: any): UpdateTournamentRequest {
    return UpdateTournamentRequestFromJSONTyped(json, false);
}

export function UpdateTournamentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTournamentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : TournamentViewFromJSON(json['parameters']),
    };
}

export function UpdateTournamentRequestToJSON(value?: UpdateTournamentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': TournamentViewToJSON(value.parameters),
    };
}

