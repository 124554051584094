import Icon, { IconProps } from '../Icon';

interface SignCircleProps extends IconProps {}

const Ruble = ({ ...props }: SignCircleProps) => {
  return (
    <Icon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.75"
          y="0.75"
          width="22.5"
          height="22.5"
          rx="11.25"
          stroke="#C3D82E"
          strokeWidth="1.5"
        />
        <path
          d="M12.9524 13.4C13.7607 13.4 14.5358 13.0629 15.1074 12.4627C15.6789 11.8626 16 11.0487 16 10.2C16 9.35131 15.6789 8.53737 15.1074 7.93726C14.5358 7.33714 13.7607 7 12.9524 7H10.0952C9.94369 7 9.79834 7.06321 9.69118 7.17574C9.58401 7.28826 9.52381 7.44087 9.52381 7.6V12.2H8.57143C8.41988 12.2 8.27453 12.2632 8.16737 12.3757C8.0602 12.4883 8 12.6409 8 12.8C8 12.9591 8.0602 13.1117 8.16737 13.2243C8.27453 13.3368 8.41988 13.4 8.57143 13.4H9.52381V14.2H8.57143C8.41988 14.2 8.27453 14.2632 8.16737 14.3757C8.0602 14.4883 8 14.6409 8 14.8C8 14.9591 8.0602 15.1117 8.16737 15.2243C8.27453 15.3368 8.41988 15.4 8.57143 15.4H9.52381V16.4C9.52381 16.5591 9.58401 16.7117 9.69118 16.8243C9.79834 16.9368 9.94369 17 10.0952 17C10.2468 17 10.3921 16.9368 10.4993 16.8243C10.6065 16.7117 10.6667 16.5591 10.6667 16.4V15.4H12.7619C12.9135 15.4 13.0588 15.3368 13.166 15.2243C13.2731 15.1117 13.3333 14.9591 13.3333 14.8C13.3333 14.6409 13.2731 14.4883 13.166 14.3757C13.0588 14.2632 12.9135 14.2 12.7619 14.2H10.6667V13.4H12.9524ZM10.6667 8.2H12.9524C13.4576 8.2 13.942 8.41071 14.2993 8.78579C14.6565 9.16086 14.8571 9.66957 14.8571 10.2C14.8571 10.7304 14.6565 11.2391 14.2993 11.6142C13.942 11.9893 13.4576 12.2 12.9524 12.2H10.6667V8.2Z"
          fill="#C3D82E"
        />
      </svg>
    </Icon>
  );
};

export default Ruble;
