import Header from '@/components/Header/Header';
import FullScreenPage from '@/components/Layouts/FullScreenPage';
import UserChat from '@/components/UserChat/UserChat';
import styles from './GameChatPage.module.css';
import TabsOutlined, {
  TabsOutlinedProps,
} from '@/components/TabsOutlined/TabsOutlined';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { IGamePageContext } from '../GamePage.interfaces';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import { ChatInfo, ChatMessageInfo, TicketType } from '@/integration-api/server-rest-lundapadelApi';
import { fetchChatMessages, setChatSilentMode } from './service';
import GameChatPageContext from './GameChatPageContext';
import { useWebsocketTicket } from '../GameStatusContent/utils';
import ChatUserList from './components/ChatUserList';
import BellIcon from '@/components/Icon/components/BellIcon/BellIcon';
import BellIconCrossed from '@/components/Icon/components/BellIcon/BellIconCrossed';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';
import { FetchErrorMessage } from '@/types/types';

export const uidSearchName = 'uid';
dayjs.locale(ru);

const GameChatPage = () => {
  // Hooks
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const chatUid = searchParams.get(uidSearchName);

  const { game, gameChats, updateGame } = useOutletContext<IGamePageContext>();
  const _webSocketURL = useWebsocketTicket(chatUid, TicketType.CHAT);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const webSocketURL = useMemo(() => {
    return _webSocketURL && gameChats.length > 0 ? _webSocketURL : undefined;
  }, [_webSocketURL, gameChats]);

  // States
  const [chatMessages, setChatMessages] = useState<ChatMessageInfo[]>([]);

  // Vars 
  const chatMap = useMemo(() => {
    return gameChats.reduce<{ [index: string]: ChatInfo }>(
      (prevChat, currentChat) => ({
        ...prevChat,
        [currentChat.chatUid!]: currentChat,
      }),
      {}
    );
  }, [gameChats, chatUid]);


  const activeChat = chatUid ? chatMap[chatUid] : null;

  const gameDate = {
    dayString: dayjs(game.plannedDate).format('dd D MMMM'),
    startTime: dayjs(game.plannedDate).format('HH:mm'),
    endTime: dayjs(game.plannedDate)
      .add(game.duration || 0, 'm')
      .format('HH:mm'),
  };
  
  const tabs: TabsOutlinedProps['tabs'] = gameChats?.map((chat) => ({
    label: chat.opened ? (
      <span className={chat?.unreadMessages ? styles.unreadIndicator : ''}>Общий</span>
      ) : (
      <span className={chat?.unreadMessages ? styles.unreadIndicator : ''}>С игроками</span>
      ),
    value: chat?.chatUid!,
  }));

  // Helpers
  const getChatMessages = () => {
    const chatInfo = chatUid ? chatMap[chatUid] : null;
    if (chatUid && chatInfo) {
      fetchChatMessages(chatInfo)
        .then(({ result }) => {
          if (result?.messages) setChatMessages(result.messages.reverse());
        })
        .catch((e) => {
          if(e instanceof Promise) {
            e.then(err => {
              const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
              PopupMessage.open(userErrorMessage ?? errorMessage);
            });
          }
        });
    }
  };

  // Handlers
  const handleTabItemClick = (tabKey: TabsOutlinedProps['activeTabKey']) => {
    setSearchParams({ uid: tabKey });
  };

  const handleBackBtnClick = () => {
    navigate(`/game/${game.identity?.uid}?fromChat`);
  }

  const handleBellIconClick = () => {
    setChatSilentMode(activeChat?.chatUid, true)
      .then(() => {
        PopupMessage.open('Уведомления выключены', 'success');
        updateGame();
      })
      .catch(() => PopupMessage.open('Не получилось выключить уведомления', 'error'));
  }

  const handleBellIconCrossedClick = () => {
    setChatSilentMode(activeChat?.chatUid, false)
    .then(() => {
      PopupMessage.open('Уведомления включены', 'success');
      updateGame();
    })
    .catch(() => PopupMessage.open('Не получилось включить уведомления', 'error'));
  }

  // Effects
  useEffect(() => {
    getChatMessages();
  }, [chatMap]);

  useEffect(() => {

    let ignoreWSConnection = false;
    const socket = webSocketURL ? new WebSocket(webSocketURL) : null;
    
    if (socket) {
      socket.onopen = () => {
        if (ignoreWSConnection) {
          socket?.close();
        } else {
          console.log('Websocket is open!');
          if(intervalRef.current) {
            clearInterval(intervalRef.current);
          }
          intervalRef.current = setInterval(() => socket.send('ping'), 10000);
        }
      };
    
      socket.onerror = (error) => {
        console.log(error);
      };

      socket.onmessage = ({ data }) => {
        const { eventType } = JSON.parse(data);
        if (eventType === 'SENT_MESSAGE') {
          getChatMessages();
        }
      };

      socket.onclose = ((ev) => {
        console.log(ev)
      })
    }

    return () => {
      if(intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (socket?.readyState === 1) {
        socket?.close();
      } else {
        ignoreWSConnection = true;
      }
    }
  }, [webSocketURL])

  // render vars
  const headerAdditional = activeChat?.silentMode ? <BellIconCrossed onClick={handleBellIconCrossedClick} /> : <BellIcon onClick={handleBellIconClick} />;

  return (
    <FullScreenPage
      header={(
        <>
          <Header handleClick={handleBackBtnClick} additional={headerAdditional}>
            <div>
              <span className={styles['game-date']}>{gameDate.dayString}</span> |{' '}
              {gameDate.startTime} - {gameDate.endTime}
            </div>
            <div>{game.club?.displayName}</div>
          </Header>
          {gameChats.length > 1 && chatUid && (
            <TabsOutlined
              tabs={tabs}
              activeTabKey={chatUid}
              onTabClick={handleTabItemClick}
            />
          )}
          {!activeChat?.opened && <ChatUserList />}
        </>
      )}
      >
      <GameChatPageContext.Provider value={{chatMessages, getChatMessages}}>
        <UserChat />
      </GameChatPageContext.Provider>
    </FullScreenPage>
  );
};

export default GameChatPage;
