import React, { useCallback, useEffect, useRef, useState } from 'react';
import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import LundaPadelLogo from '@/components/LundaPadelLogo/LundaPadelLogo';
import HLWrapper from '@/components/HLWrapper/HLWrapper';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Button from '@/components/Button/Button';
import styles from './SignInPage.module.css';
import InputPhone from '@/components/InputPhone';
import InnerPadding from '@/components/InnerPadding/InnerPadding';
import { InputPhoneValue } from '@/components/InputPhone/InputPhone';
import KeyboardCodeInput from '@/components/KeyboardCodeInput';
import { InitialCodeinputType } from '@/components/KeyboardCodeInput/helpers/types';
import { useNavigate } from 'react-router-dom';
import { checkPhoneValidation } from './utils';
import { PhoneValueState, SessionStorageAuthData } from './SignInPage.interface';
import { AuthStatus, CaptchaData } from '@/integration-api/server-rest-lundapadelApi';
import { authFetch, fetchCaptcha, sendCode } from './service';
import TOS from './TOS';
import Policy from './Policy';
import CheckboxSquare from '@/components/CheckboxSquare/CheckboxSquare';
import RestClient from '@/integration/RestClient';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';
import { COUNTRIES_FLAGS } from '@/components/InputPhone/utils';
import { ErrorUtils } from '@/utils/utils';

const initPhoneValue: PhoneValueState = {
  countryCode: COUNTRIES_FLAGS[0],
  phone: '',
  temporalToken: '',
}

export const minPhoneLength = 7;

function SignUpPage() {
  const navigate = useNavigate();

  const [codeValue, setCodeValue] = useState<InitialCodeinputType>();
  const [phoneValue, setPhoneValue] = useState<PhoneValueState>(initPhoneValue);
  const [captcha, setCaptcha] = useState<CaptchaData & {answer?: string} | undefined>();
  const [SignUpStep, setSignUpStep] = useState<1 | 2>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [tosOpen, setTosOpen] = useState<boolean>(false);
  const [policyOpen, setPolicyOpen] = useState(false);

  const [termsRead, setTermsRead] = useState(false);
  const [policyRead, setPolicyRead] = useState(false);

  const telRef = useRef<HTMLInputElement>(null);
  const captchaRef = useRef<HTMLInputElement>(null);

  const isPhone = phoneValue?.countryCode === COUNTRIES_FLAGS[0];

  const sendPhoneNumber = async (answer: string) => {
    setLoading(true)
    try {
      if (!isPhone) {
        try {
          const { result } = await RestClient.mainApi.telegramCheckAvailable();
          if (!result?.available) throw new Error('Регистрация через телеграм недоступен в данный момент.')
        } catch (e) {
          throw e;
        }
      }

      checkPhoneValidation(phoneValue);
      if (captcha?.ticket) {
        if(phoneValue.countryCode === COUNTRIES_FLAGS[1] && phoneValue.phone?.[0] === '9') {
          throw new Error('Код в телеграм не может быть отправлен для данного номера');
        }
        const { result } = await sendCode(phoneValue, answer, captcha?.ticket, !isPhone);

        // запишем токен в стейт с информацией о телефоне, так при вызове auth удобно деструктуризировать 1 объект
        setPhoneValue({ ...phoneValue, temporalToken: result?.temporalToken });
        setSignUpStep(2);
      } else {
        throw new Error('Капча не прошла проверку!')
      }
    } catch (error) {
      ErrorUtils.handleErrorMessage(error);
    } finally {
      setLoading(false);
    }
  }

  const SignUp = useCallback(
    async () => {
      setLoading(true)
      try {
        const { result } = await authFetch({ ...phoneValue, code: codeValue?.value.join('') });
        setLoading(false)
        switch (result?.status) {
          case AuthStatus.SUCCESSFUL: {
            navigate('/');
            break;
          }
          case AuthStatus.REGISTRATION_REQUIRED: {
            navigate('/auth/register')
            const authData: SessionStorageAuthData = {
              countryCode: phoneValue?.countryCode!,
              phone: phoneValue?.phone!,
              registrationToken: result.registrationToken!,
              previouslyDeleted: result.previouslyDeleted
            }
            sessionStorage.setItem('authData', JSON.stringify(authData));
            break;
          }
        }
        setLoading(false)
      } catch (err) {
        ErrorUtils.handleErrorMessage(err);
        setLoading(false)
      }
  }, [phoneValue, codeValue?.value, navigate, PopupMessage.open]);

  const getCaptcha = (countryCode: string, phone: string) => {
    fetchCaptcha({
      countryCode,
      phone,
    })
      .then(({ result }) => setCaptcha({...result, answer: ''}))
      .catch(() => PopupMessage.open('Ошибка при загрузке капчи.'));
  }

  const handleCaptchaChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let sanitizeValue = evt.target.value.replace(/[^0-9]/, '');
    setCaptcha({...captcha, answer: sanitizeValue});
  }

  const handleSignUpBtnClick = () => {
    if (phoneValue?.temporalToken) {
      SignUp();
    } else {
      sendPhoneNumber(captcha?.answer!);
    }
  }

  const handleBackToFirstStep = () => {
    setSignUpStep(1);
    setCaptcha(undefined);
  }

  const handlePhoneNumberChange = (newPhone: InputPhoneValue) => {
    setPhoneValue(newPhone)
    if (newPhone.phone.length >= minPhoneLength) {
      getCaptcha(newPhone.countryCode, newPhone.phone)
    } else {
      setCaptcha(undefined);
    }
  }

  useEffect(() => {
    return () => {
      setCodeValue(undefined);
      setPhoneValue(initPhoneValue);
    };
  }, []);

  const submitBtnDisabled = !phoneValue?.phone || !captcha?.answer || loading || !termsRead || !policyRead;

  return (
    <div className={styles.background}>
      {tosOpen ? <TOS close={() => setTosOpen(false)}/> : null}
      {policyOpen ? <Policy onClose={() => setPolicyOpen(false)}/> : null}
      <BasePageWrapper>
        <InnerPadding className={styles['content-wrapper']}>
          <div className={styles['content-main']}>
            <HLWrapper className={styles.lodoWrap}>
              <LundaPadelLogo />
            </HLWrapper>

            {SignUpStep === 1 && <div className={styles.input}>
              <InputPhone onChange={handlePhoneNumberChange} ref={telRef}/>

              {captcha && (phoneValue?.phone.length >= minPhoneLength) && (
                <div className={styles['captcha-section']}>
                  <h3 className={styles['captcha-title']}>Решите пример</h3>
                  <HLWrapper>
                    <div className={styles['captcha-condition']}>
                      {captcha.operand1} {captcha.operation} {captcha.operand2} =
                      <input 
                        className={styles['captcha-input']} 
                        value={captcha.answer} 
                        maxLength={3} 
                        type='number'
                        onChange={handleCaptchaChange} 
                        ref={captchaRef}
                        onKeyDown={e => {
                          if(e.key === 'Enter') {
                            e.preventDefault();
                            (e.target as HTMLInputElement)?.blur?.();
                          }
                        }}
                      />
                    </div>
                  </HLWrapper>
                </div>
              )}

              <label className={styles['user-agreement']}>
                <CheckboxSquare className={styles['checkbox']} checked={termsRead} onChange={() => setTermsRead(!termsRead)} />
                <span>
                  Я согласен с <span className={styles['accent']} onClick={() => setTosOpen(true)}>условиями пользовательского соглашения</span>
                </span>
              </label>
              <label className={styles['user-agreement']}>
                <CheckboxSquare className={styles['checkbox']} checked={policyRead} onChange={() => setPolicyRead(!policyRead)} />
                <span>
                  Я ознакомлен и принимаю <span className={styles['accent']} onClick={() => setPolicyOpen(true)}>политику конфиденциальности</span>
                </span>
              </label>
            </div>}

            {SignUpStep === 2 && <KeyboardCodeInput
              onChange={setCodeValue}
              resendCode={handleBackToFirstStep}
              signIn={SignUp}
              changePhoneNumberHandler={handleBackToFirstStep}
            />}
          </div>

          <div className={styles.signBtnWrapper}>
            <OversideWrapper>
              <Button onClick={handleSignUpBtnClick} disabled={submitBtnDisabled}>
                Войти
              </Button>
            </OversideWrapper>
          </div>
        </InnerPadding>
      </BasePageWrapper>
    </div>
  );
}

export default SignUpPage;
