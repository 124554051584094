import styles from './ChangeOwnershipModal.module.css';
import { PlayerProfileInfo } from "@/integration-api/server-rest-get-info";
import BasePageWrapper from "../BasePageWrapper/BasePageWrapper";
import Header from "../Header/Header";
import { useMemo } from 'react';
import PlayerItem from '../PlayerItem/PlayerItem';
import { ItemType } from '../PlayerItem/PlayerItem.interfaces';
import InfoIcon from '@/static/images/icons/InfoIcon';

type ChangeOwnershipModalProps = {
  options: Array<PlayerProfileInfo>;
  visible: boolean;
  delegateOnwership: (playerData: { displayName?: string; uid?: string; }) => void;
  onClose: VoidFunction;
}

const ChangeOwnershipModal = ({ visible, options, onClose, delegateOnwership }: ChangeOwnershipModalProps) => {
  const renderOptions = useMemo(() => {
    return (
      <div className={styles['options']}>
        {options.map((opt, i) => (
          <PlayerItem
            key={opt?.uid}
            player={opt}
            type={ItemType.SEARCH}
            withTopBorder={!!i}
            handleClick={() => delegateOnwership({ uid: opt.uid, displayName: opt.displayName })}
          />
        ))}
      </div>
    );
  }, [options]);

  return visible ? (  
    <BasePageWrapper>
      <Header handleClick={onClose} className={styles['header']}>
        Передача прав
      </Header>
      <div className={styles['wrapper']}>
        <div className={styles['warning']}>
          <div>
            <InfoIcon fill="var(--orange)"/>
          </div>
          <div className={styles['warning-text']}>Выберите игрока, которому хотите передать свои права. Ему придет запрос на передачу прав</div>
        </div> 
        {renderOptions}
      </div>
    </BasePageWrapper>
  ) : null;
}
 
export default ChangeOwnershipModal;
