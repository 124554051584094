import Icon, { IconProps } from "../../Icon";

interface ExitFilledProrps extends IconProps {}

const ExitFilled = ({ ...props }: ExitFilledProrps) => {
  return (
    <Icon {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#6E8AA3"/>
        <path d="M11.5 5.205L10.795 4.5L8 7.295L5.205 4.5L4.5 5.205L7.295 8L4.5 10.795L5.205 11.5L8 8.705L10.795 11.5L11.5 10.795L8.705 8L11.5 5.205Z" fill="#C9C9C9"/>
      </svg>
    </Icon>
  );
}
 
export default ExitFilled;
