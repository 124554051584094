import React from 'react';
import Icon, { IconProps } from '../Icon';

interface CheckProps extends IconProps {};

const MapAddGray: React.FC<CheckProps> = (props) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M12 0L0.72 3.8C0.32 3.94 0 4.3 0 4.76V35C0 35.2652 0.105357 35.5196 0.292893 35.7071C0.48043 35.8946 0.734784 36 1 36C1.1 36 1.2 36 1.32 35.94L12 31.8L20.32 34.72C20.12 33.84 20 32.92 20 32C20 31.54 20 31.08 20.08 30.6L12 27.8V4L24 8.2V23.12C26.14 21.2 28.94 20 32 20C33.4 20 34.74 20.26 36 20.72V1C36 0.734784 35.8946 0.48043 35.7071 0.292893C35.5196 0.105357 35.2652 0 35 0H34.68L24 4.2L12 0ZM30 24V30H24V34H30V40H34V34H40V30H34V24H30Z"
          fill="#3A4F62"
        />
      </svg>
    </Icon>
  );
};
  
export default MapAddGray;
  