/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseMetricView,
    BaseMetricViewFromJSON,
    BaseMetricViewFromJSONTyped,
    BaseMetricViewToJSON,
} from './BaseMetricView';
import {
    DeviceSystem,
    DeviceSystemFromJSON,
    DeviceSystemFromJSONTyped,
    DeviceSystemToJSON,
} from './DeviceSystem';
import {
    MetricScreenView,
    MetricScreenViewFromJSON,
    MetricScreenViewFromJSONTyped,
    MetricScreenViewToJSON,
} from './MetricScreenView';
import {
    SubscriptionRemoveMetricViewAllOf,
    SubscriptionRemoveMetricViewAllOfFromJSON,
    SubscriptionRemoveMetricViewAllOfFromJSONTyped,
    SubscriptionRemoveMetricViewAllOfToJSON,
} from './SubscriptionRemoveMetricViewAllOf';

/**
 * 
 * @export
 * @interface SubscriptionRemoveMetricView
 */
export interface SubscriptionRemoveMetricView {
    /**
     * 
     * @type {DeviceSystem}
     * @memberof SubscriptionRemoveMetricView
     */
    system?: DeviceSystem;
    /**
     * 
     * @type {MetricScreenView}
     * @memberof SubscriptionRemoveMetricView
     */
    screen?: MetricScreenView;
}

export function SubscriptionRemoveMetricViewFromJSON(json: any): SubscriptionRemoveMetricView {
    return SubscriptionRemoveMetricViewFromJSONTyped(json, false);
}

export function SubscriptionRemoveMetricViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionRemoveMetricView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'system': !exists(json, 'system') ? undefined : DeviceSystemFromJSON(json['system']),
        'screen': !exists(json, 'screen') ? undefined : MetricScreenViewFromJSON(json['screen']),
    };
}

export function SubscriptionRemoveMetricViewToJSON(value?: SubscriptionRemoveMetricView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'system': DeviceSystemToJSON(value.system),
        'screen': MetricScreenViewToJSON(value.screen),
    };
}

