import Headling from '@/components/Headling/Headling';
import styles from './SelectTournamentType.module.css';
import { GameTournamentKind, TournamentKind } from '@/integration-api/server-rest-lundapadelApi';
import { translateTournamentType } from './utils';
import { useEffect, useState } from 'react';
import RacketIcon from '@/static/images/icons/RacketIcon';
import ArrowIcon from '@/static/images/icons/ArrowIcon';
import InnerPadding from '@/components/InnerPadding/InnerPadding';
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch';
import InfoPopup from '@/components/InfoPopup/InfoPopup';

type SelectTournamentTypeProps = {
  value?: TournamentKind | GameTournamentKind;
  mini?: boolean;
  handleChange: (value: TournamentKind | GameTournamentKind) => void;
  reverseSortFirstRound?: boolean;
  handleReverseSortFirstRound?: (value: boolean) => void; 
}

const SelectTournamentType = ({ value, mini, handleChange, reverseSortFirstRound, handleReverseSortFirstRound }: SelectTournamentTypeProps) => {
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const [showInfo, setShowInfo] = useState(false);

  const handleSelectVisibility = (ev: MouseEvent) => {
    if(!((ev.target as HTMLElement).id === 'fake-kind' || (ev.target as HTMLInputElement)?.name?.includes('kind')) && selectOpen) {
      setSelectOpen(false)
    }
  }

  const handlePopupVisibility = (ev: MouseEvent) => {
    if(showInfo && (ev.target as HTMLElement).id !== 'escalera-info') {
      setShowInfo(false)
    }
  }

  const renderOptions = () => {
    const options = mini ? [GameTournamentKind.AMERICANO, GameTournamentKind.MEXICANO] : [TournamentKind.AMERICANO, TournamentKind.MEXICANO, TournamentKind.ESCALERA, TournamentKind.CLASSIC];
    return options.map((option, index) => (
      <div key={option} className={`${styles['option']} ${option === value ? styles['labelChecked'] : ''}`}>
        <input 
          type="radio" 
          name='kind' 
          id={`kind-${options.length - index}`} 
          value={option} 
          onChange={() => {
            setSelectOpen(false);
            handleChange(option);
          }}
          checked={value === option}
          style={{display: 'none'}}/>
        <label className={styles['label-style']} htmlFor={`kind-${options.length - index}`}>{translateTournamentType(option)}</label>
      </div>
    ));
  }

  useEffect(() => {
    document.addEventListener('click', handleSelectVisibility)
    return () => {
      document.removeEventListener('click', handleSelectVisibility);
    }
  }, [selectOpen]);

  useEffect(() => {
    document.addEventListener('click', handlePopupVisibility)
    return () => {
      document.removeEventListener('click', handlePopupVisibility);
    }
  }, [showInfo]);

  return ( 
    <div className={styles['wrapper']}>
      <InnerPadding>
        <Headling appearence='medium-normal'>
          Выберите тип турнира
        </Headling>
      </InnerPadding>
      <div className={styles['fake-select']}>
        <input
          type="checkbox" 
          name="fake-select" 
          id="fake-kind" 
          style={{display: 'none'}}
          onClick={() => setSelectOpen(!selectOpen)}
        />
        <label htmlFor="fake-kind" className={styles['fake-label']}>
          <RacketIcon />
          {value ? 
            <span>{translateTournamentType(value)}</span> :
            <span style={{ color: 'var(--gray-dark)'}}>Выберите тип турнира</span>
          }
          <div style={{ marginLeft: 'auto' }}>
            <ArrowIcon />
          </div>
        </label>
        <div className={styles['fake-options']} style={{ display: selectOpen ? 'flex' : 'none'}}>
          {renderOptions()}
        </div>
      </div>
      {value === TournamentKind.ESCALERA ?
        <div className={styles['escalera-switch']}>
          <div className={styles['escalera-text']}>
            <span>Обратный порядок игроков</span>
            <InfoPopup
              content=' Игроки с наименьшим рейтингом  начнут  на “лучшем корте”, это позволит им сыграть турнир, взаимодействуя с бóльшим количеством игроков'
              id='escalera-info'
              width={210}
              right={-88}
              arrowOffset={98}
              showInfo={showInfo}
              setShowInfo={setShowInfo}
            />
          </div>
          <ToggleSwitch
            checked={!!reverseSortFirstRound}
            id='reverseSortFirstRound'
            name='reverseSortFirstRound'
            onChange={e => handleReverseSortFirstRound?.(e.target.checked)} 
          />
        </div> : null  
      }
    </div> 
  );
}
 
export default SelectTournamentType;
