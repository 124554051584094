import { YooMoneyCheckoutWidgetConfig } from '@/types/youMoneyWidget';
import styles from './BookingPaymentPage.module.css';
import { saveStatusYookassaPayment } from '../service';
import { CreatePaymentResult, PaymentStatus } from '@/integration-api/server-rest-lundapadelApi';
import { NavigateFunction, To } from 'react-router-dom';
import { ErrorUtils } from '@/utils/utils';
import { Updater } from 'use-immer';
import { BookingPaymentLoadingState } from './BookingPaymentPage.interfaces';

export function applyEmailCheckboxStyle(current: string, email: string, toggled: boolean) {
  if(!email) {
    return styles['empty'];
  } else if(toggled && (current === email)) {
    return styles['gray']
  } else {
    return styles['yellow']
  }
}

export const defaultYMapState: ymaps.IMapState = { center: [55.881046, 37.450162], zoom: 15, behaviors: [] };

type InitializeYooMoneyWidgetArgs = {
  paymentData: CreatePaymentResult;
  gameId?: string;
  navigate: NavigateFunction;
  setLoadingState: Updater<BookingPaymentLoadingState>;
}

 export function initializeYooMoneyWidget({ paymentData, gameId, navigate, setLoadingState }: InitializeYooMoneyWidgetArgs) {
  const config: YooMoneyCheckoutWidgetConfig = {
    confirmation_token: paymentData.confirmationToken ?? '',
    customization: {
      modal: true,
      colors: {
        background: '#0D182F',
        control_primary: '#00BF96', 
        control_primary_content: '#FFFFFF',
        control_secondary: '#366093',
        border: '#244166',
        text: '#DBDCE0'
      }
    },
    error_callback: (err) => {
      console.log(err);
    }
  }

  //блок try\catch для обработки ошибки при отсутствии скрипта
  try {
    const checkout = new window.YooMoneyCheckoutWidget(config);
    checkout.render()
      .catch(err => console.log(err));
  
    checkout.on('complete', () => {
      checkout.destroy();
    });
  
    checkout.on('modal_close', () => {
      setLoadingState(state => {
        state.paymentEntities = false;
      })
    })
  
    checkout.on('success', () => {
      saveStatusYookassaPayment({
        status: PaymentStatus.SUCCEEDED,
        paymentUid: paymentData.paymentUid
      })
        .then(() => {
          navigate(`/game/${gameId}?afterCreation`);
        })
        .catch(err => {
          ErrorUtils.handleErrorMessage(err);
          setLoadingState(state => {
            state.paymentEntities = false;
          });
        });
    });
  
    checkout.on('fail', () => {
      saveStatusYookassaPayment({
        status: PaymentStatus.CANCELED,
        paymentUid: paymentData.paymentUid
      })
      .catch(err => {
        ErrorUtils.handleErrorMessage(err);
        setLoadingState(state => {
          state.paymentEntities = false;
        })
      });
    });
  } catch(err) {
    setLoadingState(state => {
      state.paymentEntities = false;
      state.gameEntities = false;
    })
    ErrorUtils.handleErrorMessage('Сервис оплаты временно недоступен')
  }
 }
