import ConfirmationModal from "@/components/ConfirmationModal/ConfirmationModal";
import { ConfirmationModalProps } from "@/components/ConfirmationModal/ConfirmationModal.interfaces";
import styles from './TimeWarningModal.module.css';
import ballIcon from '@/static/images/icons/ball-icon.svg';
import arrowIcon from '@/static/images/icons/right-arrow-green-icon.svg';
import dayjs from "dayjs";
import { TournamentFormValues } from "../../tournamentCreate/TournamentCreatePage.interfaces";
import { GameFormValues } from "../../GameCreate/GameCreatePage.interfaces";

interface TimeWarningModalProps<T> extends ConfirmationModalProps {
  submitForm: (values: T) => Promise<any>;
  formValues: T;
}

const TimeWarningModal = <T extends TournamentFormValues | GameFormValues>({ submitForm, formValues, ...props }: TimeWarningModalProps<T>) => {
  return (
    <ConfirmationModal {...props}>
      <div className={styles['question']}>
        Вы уверены, что хотите продолжить?
      </div>
      <div className={styles['description']}>
        У вас уже есть игра {dayjs(formValues.plannedDate?.date).format('DD.MM')} на {formValues.plannedDate?.time}, проверьте свое расписание в разделе “Мои игры”
      </div>
      <button className={styles['button']} onClick={() => submitForm(formValues)}>
        <div className={styles['button-inner']}>
          <img src={ballIcon} alt="иконка мячика" />
          <span>Продолжить</span>
          <img src={arrowIcon} alt='иконка стрелки вправо'/>
        </div>
      </button>
    </ConfirmationModal> 
  );
}
 
export default TimeWarningModal;
