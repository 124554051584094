/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseMetricView,
    BaseMetricViewFromJSON,
    BaseMetricViewFromJSONTyped,
    BaseMetricViewToJSON,
} from './BaseMetricView';
import {
    DeviceSystem,
    DeviceSystemFromJSON,
    DeviceSystemFromJSONTyped,
    DeviceSystemToJSON,
} from './DeviceSystem';
import {
    MetricScreenView,
    MetricScreenViewFromJSON,
    MetricScreenViewFromJSONTyped,
    MetricScreenViewToJSON,
} from './MetricScreenView';
import {
    SubscriptionEditButton,
    SubscriptionEditButtonFromJSON,
    SubscriptionEditButtonFromJSONTyped,
    SubscriptionEditButtonToJSON,
} from './SubscriptionEditButton';
import {
    SubscriptionMetricViewAllOf,
    SubscriptionMetricViewAllOfFromJSON,
    SubscriptionMetricViewAllOfFromJSONTyped,
    SubscriptionMetricViewAllOfToJSON,
} from './SubscriptionMetricViewAllOf';

/**
 * 
 * @export
 * @interface SubscriptionMetricView
 */
export interface SubscriptionMetricView {
    /**
     * 
     * @type {DeviceSystem}
     * @memberof SubscriptionMetricView
     */
    system?: DeviceSystem;
    /**
     * 
     * @type {MetricScreenView}
     * @memberof SubscriptionMetricView
     */
    screen?: MetricScreenView;
    /**
     * 
     * @type {SubscriptionEditButton}
     * @memberof SubscriptionMetricView
     */
    editButton?: SubscriptionEditButton;
}

export function SubscriptionMetricViewFromJSON(json: any): SubscriptionMetricView {
    return SubscriptionMetricViewFromJSONTyped(json, false);
}

export function SubscriptionMetricViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionMetricView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'system': !exists(json, 'system') ? undefined : DeviceSystemFromJSON(json['system']),
        'screen': !exists(json, 'screen') ? undefined : MetricScreenViewFromJSON(json['screen']),
        'editButton': !exists(json, 'editButton') ? undefined : SubscriptionEditButtonFromJSON(json['editButton']),
    };
}

export function SubscriptionMetricViewToJSON(value?: SubscriptionMetricView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'system': DeviceSystemToJSON(value.system),
        'screen': MetricScreenViewToJSON(value.screen),
        'editButton': SubscriptionEditButtonToJSON(value.editButton),
    };
}

