/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClubInfo,
    ClubInfoFromJSON,
    ClubInfoFromJSONTyped,
    ClubInfoToJSON,
} from './ClubInfo';
import {
    CourtInfo,
    CourtInfoFromJSON,
    CourtInfoFromJSONTyped,
    CourtInfoToJSON,
} from './CourtInfo';
import {
    LocalDateTime,
    LocalDateTimeFromJSON,
    LocalDateTimeFromJSONTyped,
    LocalDateTimeToJSON,
} from './LocalDateTime';
import {
    RelatedEntityType,
    RelatedEntityTypeFromJSON,
    RelatedEntityTypeFromJSONTyped,
    RelatedEntityTypeToJSON,
} from './RelatedEntityType';
import {
    ReservationStatus,
    ReservationStatusFromJSON,
    ReservationStatusFromJSONTyped,
    ReservationStatusToJSON,
} from './ReservationStatus';

/**
 * 
 * @export
 * @interface ReservationInfo
 */
export interface ReservationInfo {
    /**
     * 
     * @type {string}
     * @memberof ReservationInfo
     */
    uid?: string;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof ReservationInfo
     */
    plannedDate?: LocalDateTime;
    /**
     * 
     * @type {ClubInfo}
     * @memberof ReservationInfo
     */
    club?: ClubInfo;
    /**
     * 
     * @type {ReservationStatus}
     * @memberof ReservationInfo
     */
    status?: ReservationStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationInfo
     */
    paid?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReservationInfo
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof ReservationInfo
     */
    profileUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationInfo
     */
    relatedEntityUid?: string;
    /**
     * 
     * @type {RelatedEntityType}
     * @memberof ReservationInfo
     */
    relatedEntityType?: RelatedEntityType;
    /**
     * 
     * @type {number}
     * @memberof ReservationInfo
     */
    duration?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReservationInfo
     */
    ids?: Array<number>;
    /**
     * 
     * @type {Array<CourtInfo>}
     * @memberof ReservationInfo
     */
    courts?: Array<CourtInfo>;
}

export function ReservationInfoFromJSON(json: any): ReservationInfo {
    return ReservationInfoFromJSONTyped(json, false);
}

export function ReservationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'plannedDate': !exists(json, 'plannedDate') ? undefined : LocalDateTimeFromJSON(json['plannedDate']),
        'club': !exists(json, 'club') ? undefined : ClubInfoFromJSON(json['club']),
        'status': !exists(json, 'status') ? undefined : ReservationStatusFromJSON(json['status']),
        'paid': !exists(json, 'paid') ? undefined : json['paid'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'profileUid': !exists(json, 'profileUid') ? undefined : json['profileUid'],
        'relatedEntityUid': !exists(json, 'relatedEntityUid') ? undefined : json['relatedEntityUid'],
        'relatedEntityType': !exists(json, 'relatedEntityType') ? undefined : RelatedEntityTypeFromJSON(json['relatedEntityType']),
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'ids': !exists(json, 'ids') ? undefined : json['ids'],
        'courts': !exists(json, 'courts') ? undefined : ((json['courts'] as Array<any>).map(CourtInfoFromJSON)),
    };
}

export function ReservationInfoToJSON(value?: ReservationInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uid': value.uid,
        'plannedDate': LocalDateTimeToJSON(value.plannedDate),
        'club': ClubInfoToJSON(value.club),
        'status': ReservationStatusToJSON(value.status),
        'paid': value.paid,
        'price': value.price,
        'profileUid': value.profileUid,
        'relatedEntityUid': value.relatedEntityUid,
        'relatedEntityType': RelatedEntityTypeToJSON(value.relatedEntityType),
        'duration': value.duration,
        'ids': value.ids,
        'courts': value.courts === undefined ? undefined : ((value.courts as Array<any>).map(CourtInfoToJSON)),
    };
}

