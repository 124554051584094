/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReservationInfo,
    ReservationInfoFromJSON,
    ReservationInfoFromJSONTyped,
    ReservationInfoToJSON,
} from './ReservationInfo';

/**
 * 
 * @export
 * @interface ReservationLoadListResult
 */
export interface ReservationLoadListResult {
    /**
     * 
     * @type {Array<ReservationInfo>}
     * @memberof ReservationLoadListResult
     */
    infos?: Array<ReservationInfo>;
}

export function ReservationLoadListResultFromJSON(json: any): ReservationLoadListResult {
    return ReservationLoadListResultFromJSONTyped(json, false);
}

export function ReservationLoadListResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationLoadListResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'infos': !exists(json, 'infos') ? undefined : ((json['infos'] as Array<any>).map(ReservationInfoFromJSON)),
    };
}

export function ReservationLoadListResultToJSON(value?: ReservationLoadListResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'infos': value.infos === undefined ? undefined : ((value.infos as Array<any>).map(ReservationInfoToJSON)),
    };
}

