import styles from './ProfileReservationPage.module.css';
import Header from '@/components/Header/Header';
import HLine from '@/components/HLine/HLine';
import FullScreenPage from '@/components/Layouts/FullScreenPage';
import NavBar from '@/components/NavBar/NavBar';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  bookingCancel,
  gameCreateReservation,
  reservationLoad,
} from '../service';
import {
  ReservationDetails,
  ReservationLoadParam,
  ReservationStatus,
} from '@/integration-api/server-rest-lundapadelApi';
import { ErrorUtils, getPlatform } from '@/utils/utils';
import { PuffLoader } from 'react-spinners';
import ActiveReservationCard from '@/components/ActiveReservationCard/ActiveReservationCard';
import GameListCard from '@/components/GameListCard/GameListCard';
import { ProfileReservationJSONObject } from '../ProfileReservations/ProfileReservations.interfaces';
import Button, { ButtonVariants } from '@/components/Button/Button';
import CrossIcon from '@/static/images/icons/CrossIcon';
import TrashIcon from '@/static/images/icons/TrashIcon';
import CancelReservationModal from './CancelReservationModal/CancelReservationModal';
import { applyReservationGameSubtype } from './utils';

const ProfileReservationPage = () => {
  const navigate = useNavigate();
  const { uid: reservationUID } = useParams();

  const [reservation, setReservation] = useState<ReservationDetails>();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const jsonReservation = useMemo(() => {
    const object = sessionStorage.getItem('currentReservation');
    if (object) {
      let parsedObject: ProfileReservationJSONObject | null = null;
      try {
        parsedObject = JSON.parse(object) as ProfileReservationJSONObject;
      } catch (err) {
        console.log(err);
      }
      return parsedObject;
    } else {
      return null;
    }
  }, []);

  const createGameHandler = useCallback(async () => {
    try {
      setLoading(true);
      const { result } = await gameCreateReservation({
        ids: reservation?.info?.ids,
        clubUid: reservation?.info?.club?.uid,
        metrics: { system: getPlatform() },
      });
      navigate(`/game/${result?.gameUid}?afterCreation`);
    } catch (err) {
      ErrorUtils.handleErrorMessage(err);
    } finally {
      setLoading(false);
    }
  }, [reservation?.info?.ids, reservation?.info?.club?.uid, navigate]);

  const cancelReservationHandler = useCallback(async () => {
    try {
      setLoading(true);
      await bookingCancel({
        reservationUid: reservation?.info?.uid,
        ids: reservation?.info?.ids,
        metrics: { system: getPlatform() },
      });
      navigate(-1);
    } catch (err) {
      ErrorUtils.handleErrorMessage(err);
    } finally {
      setLoading(false);
    }
  }, [reservation?.info?.uid, reservation?.info?.ids, navigate]);

  useEffect(() => {
    if (reservationUID || jsonReservation) {
      let params: ReservationLoadParam = reservationUID
        ? { uid: reservationUID }
        : { uid: jsonReservation?.uid, ids: jsonReservation?.ids };
      setLoading(true);
      reservationLoad(params)
        .then(({ result }) => {
          if (result?.info?.status === ReservationStatus.CANCELED) return;
          setReservation(result);
        })
        .catch((err) => ErrorUtils.handleErrorMessage(err))
        .finally(() => setLoading(false));
    }
  }, [jsonReservation, reservationUID]);

  return (
    <FullScreenPage
      header={
        <Header
          additional={
            reservation?.info && !jsonReservation?.expired ? (
              <span
                className={styles['icon']}
                onClick={() => setModalVisible(true)}
              >
                <TrashIcon />
              </span>
            ) : undefined
          }
        >
          Мои бронирования
        </Header>
      }
      footer={
        <>
          <HLine calssName={styles['hline']} />
          <NavBar />
        </>
      }
      className={styles['page']}
    >
      <CancelReservationModal
        visible={modalVisible}
        loading={loading}
        onClose={() => setModalVisible(false)}
        handler={cancelReservationHandler}
      />
      {loading ? (
        <PuffLoader color="#4EB857" cssOverride={{ margin: 'auto' }} />
      ) : (
        <div className={styles['wrapper']}>
          {reservation?.info ? (
            <ActiveReservationCard
              type="detailed"
              reservation={reservation?.info ?? {}}
              expired={!!jsonReservation?.expired}
            />
          ) : null}
          {reservation?.tourGame ? (
            <GameListCard
              subtype={applyReservationGameSubtype(
                reservation.tourGame.gameStatus
              )}
              game={reservation?.tourGame}
            />
          ) : null}
          {reservation?.info && !reservation.tourGame ? (
            <Button
              variant={ButtonVariants.YELLOW}
              className={styles['button']}
              onClick={createGameHandler}
              disabled={loading}
            >
              <span className={styles['icon']}>
                <CrossIcon />
              </span>
              <span>Создать игру к этому бронированию</span>
            </Button>
          ) : null}
        </div>
      )}
    </FullScreenPage>
  );
};

export default ProfileReservationPage;
