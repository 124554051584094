import { useCallback, useEffect, useMemo, useState } from "react";
import { cityClubsLoadList } from "../service";
import styles from './ProfileFavoriteClubs.module.css';
import { CityClubsGroupLoadListParameters, CityClubsItemView, ClubView } from "@/integration-api/server-rest-lundapadelApi";
import Header from "@/components/Header/Header";
import { useAppSelector } from "@/hooks/hooks";
import ClubCityBelonging from "../../create/components/ClubCityBelonging/ClubCityBelonging";
import OversideWrapper from "@/components/OversideWrapper/OversideWrapper";
import Button from "@/components/Button/Button";
import { PuffLoader } from "react-spinners";
import HLWrapper from "@/components/HLWrapper/HLWrapper";
import Search from "@/components/Search/Search";
import useDebounce from "@/hooks/useDebounce";
import { ErrorUtils } from "@/utils/utils";

type ProfileFavoriteClubsProps = {
  favoriteClubs: Array<ClubView>;
  onClose: () => void;
  updateClubs: (clubs: Set<string>) => void;
}

const ProfileFavoriteClubs = ({ favoriteClubs, onClose, updateClubs }: ProfileFavoriteClubsProps) => {
  const profileCity = useAppSelector(state => state.auth.currentProfile.city);
  const [clubs, setClubs] = useState<Array<CityClubsItemView>>([]);
  const [textPattern, setTextPattern] = useState<string>();
  const [visibleClubs, setVisibleClubs] = useState<Array<ClubView>>([]);
  const [selectedClubs, setSelectedClubs] = useState<Set<string>>(new Set());
  const [loading, setLoading] = useState(false);

  const loadClubs = useCallback((params?: CityClubsGroupLoadListParameters) => {
    cityClubsLoadList(params ?? {})
    .then(({ result }) => {
      setClubs(result?.items ?? []);
      const flattedClubs = result?.items?.flatMap(entry => entry.clubs ?? []).filter(club => club.favorite || selectedClubs.has(club.reference?.uid ?? ''));
      setVisibleClubs(flattedClubs ?? []);
      if(!params) {
        setSelectedClubs(new Set(flattedClubs?.map(club => club.reference?.uid ?? '') ?? []));
      }
    })
    .catch(err => ErrorUtils.handleErrorMessage(err))
    .finally(() => setLoading(false));
  }, [favoriteClubs, selectedClubs]);

  const debouncedLoadClubs = useDebounce(loadClubs, 500);

  const handleClubSelect = useCallback((uid: string) => {
    const favClub = clubs.map(entry => entry.clubs ?? []).flat().find(club => club.reference?.uid === uid);
    const favClubIndex = visibleClubs.findIndex(club => club.reference?.uid === favClub?.reference?.uid);
    setVisibleClubs(state => favClubIndex > -1 ? state.slice(0, favClubIndex).concat(state.slice(favClubIndex + 1)) : state.concat([favClub ?? {}]));
    if(selectedClubs.has(uid)) {
      setSelectedClubs(state => {
        const returningSet = new Set(state);
        returningSet.delete(uid);
        return returningSet;
      });
    } else {
      setSelectedClubs(state => new Set(state).add(uid));
    }
  }, [clubs, visibleClubs, selectedClubs]);

  const renderClubs = useMemo(() => {
    return clubs.map(entry => (
      <ClubCityBelonging
        key={entry.city}
        city={entry.city}
        clubs={entry.clubs ?? []}
        handleClubSelect={handleClubSelect}
        favoriteClubSelection
        favoriteSelectedClubs={visibleClubs}
      />
    ));
  }, [clubs, profileCity, visibleClubs]);

  useEffect(() => {
    if(textPattern !== undefined) {
      debouncedLoadClubs({ textPattern });
    } else {
      loadClubs();
    }
  }, [textPattern]);

  return ( 
    <section>
      <Header handleClick={onClose} className={styles['header']}>
        Любимый клуб
      </Header>
      <HLWrapper>
        <Search
          placeholder='Поиск по городу и клубу'
          searchHandler={(e) => setTextPattern(e.target.value)}
        />
      </HLWrapper>
      {loading ? 
        <PuffLoader color='#4EB857' cssOverride={{ margin: 'auto' }} /> :
        <div className={styles['clubs']}>
          {renderClubs}
        </div>
      }
      <OversideWrapper className={styles.saveBtm}>
        <Button onClick={() => updateClubs(selectedClubs)}>Сохранить</Button>
      </OversideWrapper>
    </section>
  );
}
 
export default ProfileFavoriteClubs;
