import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import Header from '@/components/Header/Header';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './ClubsPage.module.css';
import PageBackground from '@/components/PageBackground/PageBackground';
import bgImage from '@/static/images/rating-page-background.jpg';
import { CityClubsItemView } from '@/integration-api/server-rest-lundapadelApi';
import { cityClubsLoadList } from '../services';
import Skeleton from 'react-loading-skeleton';
import AdminSiteIcon from '@/components/Icons/AdminSiteIcon';
import DBManager from '@/utils/DBManager/DBManager';
import ClubCardCityBelonging from './components/ClubCardCityBelonging/ClubCardCityBelonging';
import ClubAddingRequest from './components/ClubAddingRequest/ClubAddingRequest';

const ClubsPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [clubs, setClubs] = useState<Array<CityClubsItemView>>();

  const loadClubs = useCallback(async () => {
    setLoading(true)
    try {
      const { result } = await cityClubsLoadList({ fullInfo: true })
      if (result) {
        setClubs(result.items || []);
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        console.log(err);
      }
    } finally {
      setLoading(false)
    }
  }, []);

  useEffect(() => {
    if (navigator.onLine) {
      DBManager.create('clubs', clubs, 'cityClubs');
      if (!clubs) {
        loadClubs();
      }
    }
    else {
      if(!clubs) {
        DBManager.read('clubs', 'cityClubs')
          .then(clubs => setClubs(clubs))
          .catch((err) => console.log(err))
          .finally(() => setLoading(false))
      }
    } 
  }, [clubs, loadClubs]);

  return (
    <BasePageWrapper showNavBar>
      <Header className={styles.header} additional={<a target='_blank' href='https://lundapadel.ru' rel="noreferrer"><AdminSiteIcon /></a>}>
        Клубы
      </Header>
      <PageBackground imgSrc={bgImage} />
      <div className={styles.clubsWrapper}>
        {loading && <Skeleton count={10} />}
        <ClubAddingRequest />
        {clubs &&
          clubs.map(entry => (
            <ClubCardCityBelonging
              key={entry.city}
              city={entry.city}
              clubs={entry.clubs ?? []}
            />
          ))}
      </div>
    </BasePageWrapper>
  );
};

export default ClubsPage;
