/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AvailableCourtsLoadListRequest,
    AvailableCourtsLoadListRequestFromJSON,
    AvailableCourtsLoadListRequestToJSON,
    AvailableCourtsLoadListResponse,
    AvailableCourtsLoadListResponseFromJSON,
    AvailableCourtsLoadListResponseToJSON,
    AvailableSlotsLoadListRequest,
    AvailableSlotsLoadListRequestFromJSON,
    AvailableSlotsLoadListRequestToJSON,
    AvailableSlotsLoadListResponse,
    AvailableSlotsLoadListResponseFromJSON,
    AvailableSlotsLoadListResponseToJSON,
    BookingAvailableCheckResponse,
    BookingAvailableCheckResponseFromJSON,
    BookingAvailableCheckResponseToJSON,
    BookingCancelRequest,
    BookingCancelRequestFromJSON,
    BookingCancelRequestToJSON,
    BookingCreateRequest,
    BookingCreateRequestFromJSON,
    BookingCreateRequestToJSON,
    BookingCreateResponse,
    BookingCreateResponseFromJSON,
    BookingCreateResponseToJSON,
    CountReservationResponse,
    CountReservationResponseFromJSON,
    CountReservationResponseToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PaymentAmountCalculateRequest,
    PaymentAmountCalculateRequestFromJSON,
    PaymentAmountCalculateRequestToJSON,
    PaymentAmountCalculateResponse,
    PaymentAmountCalculateResponseFromJSON,
    PaymentAmountCalculateResponseToJSON,
    ReservationLoadListResponse,
    ReservationLoadListResponseFromJSON,
    ReservationLoadListResponseToJSON,
    ReservationLoadRequest,
    ReservationLoadRequestFromJSON,
    ReservationLoadRequestToJSON,
    ReservationLoadResponse,
    ReservationLoadResponseFromJSON,
    ReservationLoadResponseToJSON,
} from '../models';

export interface LundapadelBookingApiAvailableCourtsLoadListOperationRequest {
    availableCourtsLoadListRequest: AvailableCourtsLoadListRequest;
}

export interface LundapadelBookingApiAvailableSlotsLoadListOperationRequest {
    availableSlotsLoadListRequest: AvailableSlotsLoadListRequest;
}

export interface LundapadelBookingApiBookingCancelOperationRequest {
    bookingCancelRequest: BookingCancelRequest;
}

export interface LundapadelBookingApiBookingCreateOperationRequest {
    bookingCreateRequest: BookingCreateRequest;
}

export interface LundapadelBookingApiPaymentAmountCalculateOperationRequest {
    paymentAmountCalculateRequest: PaymentAmountCalculateRequest;
}

export interface LundapadelBookingApiReservationLoadOperationRequest {
    reservationLoadRequest: ReservationLoadRequest;
}

export interface LundapadelBookingApiReservationLoadListRequest {
    active: boolean;
}

/**
 * 
 */
export class LundapadelBookingApi extends runtime.BaseAPI {

    /**
     * Load available courts
     */
    async availableCourtsLoadListRaw(requestParameters: LundapadelBookingApiAvailableCourtsLoadListOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AvailableCourtsLoadListResponse>> {
        if (requestParameters.availableCourtsLoadListRequest === null || requestParameters.availableCourtsLoadListRequest === undefined) {
            throw new runtime.RequiredError('availableCourtsLoadListRequest','Required parameter requestParameters.availableCourtsLoadListRequest was null or undefined when calling availableCourtsLoadList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/booking/available-courts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AvailableCourtsLoadListRequestToJSON(requestParameters.availableCourtsLoadListRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AvailableCourtsLoadListResponseFromJSON(jsonValue));
    }

    /**
     * Load available courts
     */
    async availableCourtsLoadList(requestParameters: LundapadelBookingApiAvailableCourtsLoadListOperationRequest, initOverrides?: RequestInit): Promise<AvailableCourtsLoadListResponse> {
        const response = await this.availableCourtsLoadListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load available slots
     */
    async availableSlotsLoadListRaw(requestParameters: LundapadelBookingApiAvailableSlotsLoadListOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AvailableSlotsLoadListResponse>> {
        if (requestParameters.availableSlotsLoadListRequest === null || requestParameters.availableSlotsLoadListRequest === undefined) {
            throw new runtime.RequiredError('availableSlotsLoadListRequest','Required parameter requestParameters.availableSlotsLoadListRequest was null or undefined when calling availableSlotsLoadList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/booking/available-slots`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AvailableSlotsLoadListRequestToJSON(requestParameters.availableSlotsLoadListRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AvailableSlotsLoadListResponseFromJSON(jsonValue));
    }

    /**
     * Load available slots
     */
    async availableSlotsLoadList(requestParameters: LundapadelBookingApiAvailableSlotsLoadListOperationRequest, initOverrides?: RequestInit): Promise<AvailableSlotsLoadListResponse> {
        const response = await this.availableSlotsLoadListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check booking available
     */
    async bookingAvailableCheckRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<BookingAvailableCheckResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/booking/check-available`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BookingAvailableCheckResponseFromJSON(jsonValue));
    }

    /**
     * Check booking available
     */
    async bookingAvailableCheck(initOverrides?: RequestInit): Promise<BookingAvailableCheckResponse> {
        const response = await this.bookingAvailableCheckRaw(initOverrides);
        return await response.value();
    }

    /**
     * Cancel booking
     */
    async bookingCancelRaw(requestParameters: LundapadelBookingApiBookingCancelOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.bookingCancelRequest === null || requestParameters.bookingCancelRequest === undefined) {
            throw new runtime.RequiredError('bookingCancelRequest','Required parameter requestParameters.bookingCancelRequest was null or undefined when calling bookingCancel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/booking/cancel`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BookingCancelRequestToJSON(requestParameters.bookingCancelRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancel booking
     */
    async bookingCancel(requestParameters: LundapadelBookingApiBookingCancelOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.bookingCancelRaw(requestParameters, initOverrides);
    }

    /**
     * Booking available slot
     */
    async bookingCreateRaw(requestParameters: LundapadelBookingApiBookingCreateOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BookingCreateResponse>> {
        if (requestParameters.bookingCreateRequest === null || requestParameters.bookingCreateRequest === undefined) {
            throw new runtime.RequiredError('bookingCreateRequest','Required parameter requestParameters.bookingCreateRequest was null or undefined when calling bookingCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/booking/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BookingCreateRequestToJSON(requestParameters.bookingCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BookingCreateResponseFromJSON(jsonValue));
    }

    /**
     * Booking available slot
     */
    async bookingCreate(requestParameters: LundapadelBookingApiBookingCreateOperationRequest, initOverrides?: RequestInit): Promise<BookingCreateResponse> {
        const response = await this.bookingCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reservation load list for profile
     */
    async countReservationRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<CountReservationResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reservation/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountReservationResponseFromJSON(jsonValue));
    }

    /**
     * Reservation load list for profile
     */
    async countReservation(initOverrides?: RequestInit): Promise<CountReservationResponse> {
        const response = await this.countReservationRaw(initOverrides);
        return await response.value();
    }

    /**
     * Calculate payment amount
     */
    async paymentAmountCalculateRaw(requestParameters: LundapadelBookingApiPaymentAmountCalculateOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaymentAmountCalculateResponse>> {
        if (requestParameters.paymentAmountCalculateRequest === null || requestParameters.paymentAmountCalculateRequest === undefined) {
            throw new runtime.RequiredError('paymentAmountCalculateRequest','Required parameter requestParameters.paymentAmountCalculateRequest was null or undefined when calling paymentAmountCalculate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/booking/payment/calculate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentAmountCalculateRequestToJSON(requestParameters.paymentAmountCalculateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentAmountCalculateResponseFromJSON(jsonValue));
    }

    /**
     * Calculate payment amount
     */
    async paymentAmountCalculate(requestParameters: LundapadelBookingApiPaymentAmountCalculateOperationRequest, initOverrides?: RequestInit): Promise<PaymentAmountCalculateResponse> {
        const response = await this.paymentAmountCalculateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reservation load for profile
     */
    async reservationLoadRaw(requestParameters: LundapadelBookingApiReservationLoadOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReservationLoadResponse>> {
        if (requestParameters.reservationLoadRequest === null || requestParameters.reservationLoadRequest === undefined) {
            throw new runtime.RequiredError('reservationLoadRequest','Required parameter requestParameters.reservationLoadRequest was null or undefined when calling reservationLoad.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reservation/load`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReservationLoadRequestToJSON(requestParameters.reservationLoadRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReservationLoadResponseFromJSON(jsonValue));
    }

    /**
     * Reservation load for profile
     */
    async reservationLoad(requestParameters: LundapadelBookingApiReservationLoadOperationRequest, initOverrides?: RequestInit): Promise<ReservationLoadResponse> {
        const response = await this.reservationLoadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reservation load list for profile
     */
    async reservationLoadListRaw(requestParameters: LundapadelBookingApiReservationLoadListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReservationLoadListResponse>> {
        if (requestParameters.active === null || requestParameters.active === undefined) {
            throw new runtime.RequiredError('active','Required parameter requestParameters.active was null or undefined when calling reservationLoadList.');
        }

        const queryParameters: any = {};

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reservation/load-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReservationLoadListResponseFromJSON(jsonValue));
    }

    /**
     * Reservation load list for profile
     */
    async reservationLoadList(requestParameters: LundapadelBookingApiReservationLoadListRequest, initOverrides?: RequestInit): Promise<ReservationLoadListResponse> {
        const response = await this.reservationLoadListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
