/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CalculatedGameStatus,
    CalculatedGameStatusFromJSON,
    CalculatedGameStatusFromJSONTyped,
    CalculatedGameStatusToJSON,
} from './CalculatedGameStatus';
import {
    ChatMessageInfo,
    ChatMessageInfoFromJSON,
    ChatMessageInfoFromJSONTyped,
    ChatMessageInfoToJSON,
} from './ChatMessageInfo';
import {
    RelatedEntityType,
    RelatedEntityTypeFromJSON,
    RelatedEntityTypeFromJSONTyped,
    RelatedEntityTypeToJSON,
} from './RelatedEntityType';

/**
 * 
 * @export
 * @interface ChatMessageLoadListResult
 */
export interface ChatMessageLoadListResult {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageLoadListResult
     */
    relatedEntityUid?: string;
    /**
     * 
     * @type {RelatedEntityType}
     * @memberof ChatMessageLoadListResult
     */
    relatedEntityType?: RelatedEntityType;
    /**
     * 
     * @type {CalculatedGameStatus}
     * @memberof ChatMessageLoadListResult
     */
    relatedEntityStatus?: CalculatedGameStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ChatMessageLoadListResult
     */
    opened?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatMessageLoadListResult
     */
    active?: boolean;
    /**
     * 
     * @type {Array<ChatMessageInfo>}
     * @memberof ChatMessageLoadListResult
     */
    messages?: Array<ChatMessageInfo>;
}

export function ChatMessageLoadListResultFromJSON(json: any): ChatMessageLoadListResult {
    return ChatMessageLoadListResultFromJSONTyped(json, false);
}

export function ChatMessageLoadListResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatMessageLoadListResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'relatedEntityUid': !exists(json, 'relatedEntityUid') ? undefined : json['relatedEntityUid'],
        'relatedEntityType': !exists(json, 'relatedEntityType') ? undefined : RelatedEntityTypeFromJSON(json['relatedEntityType']),
        'relatedEntityStatus': !exists(json, 'relatedEntityStatus') ? undefined : CalculatedGameStatusFromJSON(json['relatedEntityStatus']),
        'opened': !exists(json, 'opened') ? undefined : json['opened'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'messages': !exists(json, 'messages') ? undefined : ((json['messages'] as Array<any>).map(ChatMessageInfoFromJSON)),
    };
}

export function ChatMessageLoadListResultToJSON(value?: ChatMessageLoadListResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'relatedEntityUid': value.relatedEntityUid,
        'relatedEntityType': RelatedEntityTypeToJSON(value.relatedEntityType),
        'relatedEntityStatus': CalculatedGameStatusToJSON(value.relatedEntityStatus),
        'opened': value.opened,
        'active': value.active,
        'messages': value.messages === undefined ? undefined : ((value.messages as Array<any>).map(ChatMessageInfoToJSON)),
    };
}

