/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RequestLoadListParam,
    RequestLoadListParamFromJSON,
    RequestLoadListParamFromJSONTyped,
    RequestLoadListParamToJSON,
} from './RequestLoadListParam';

/**
 * 
 * @export
 * @interface RequestChangeOwnerLoadListRequest
 */
export interface RequestChangeOwnerLoadListRequest {
    /**
     * 
     * @type {RequestLoadListParam}
     * @memberof RequestChangeOwnerLoadListRequest
     */
    parameters?: RequestLoadListParam;
}

export function RequestChangeOwnerLoadListRequestFromJSON(json: any): RequestChangeOwnerLoadListRequest {
    return RequestChangeOwnerLoadListRequestFromJSONTyped(json, false);
}

export function RequestChangeOwnerLoadListRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestChangeOwnerLoadListRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : RequestLoadListParamFromJSON(json['parameters']),
    };
}

export function RequestChangeOwnerLoadListRequestToJSON(value?: RequestChangeOwnerLoadListRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': RequestLoadListParamToJSON(value.parameters),
    };
}

