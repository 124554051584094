/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreatePaymentParam,
    CreatePaymentParamFromJSON,
    CreatePaymentParamFromJSONTyped,
    CreatePaymentParamToJSON,
} from './CreatePaymentParam';

/**
 * 
 * @export
 * @interface CreateYookassaPaymentRequest
 */
export interface CreateYookassaPaymentRequest {
    /**
     * 
     * @type {CreatePaymentParam}
     * @memberof CreateYookassaPaymentRequest
     */
    parameters?: CreatePaymentParam;
}

export function CreateYookassaPaymentRequestFromJSON(json: any): CreateYookassaPaymentRequest {
    return CreateYookassaPaymentRequestFromJSONTyped(json, false);
}

export function CreateYookassaPaymentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateYookassaPaymentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : CreatePaymentParamFromJSON(json['parameters']),
    };
}

export function CreateYookassaPaymentRequestToJSON(value?: CreateYookassaPaymentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': CreatePaymentParamToJSON(value.parameters),
    };
}

