/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalDateTime,
    LocalDateTimeFromJSON,
    LocalDateTimeFromJSONTyped,
    LocalDateTimeToJSON,
} from './LocalDateTime';
import {
    PaymentStatus,
    PaymentStatusFromJSON,
    PaymentStatusFromJSONTyped,
    PaymentStatusToJSON,
} from './PaymentStatus';
import {
    RelatedEntityType,
    RelatedEntityTypeFromJSON,
    RelatedEntityTypeFromJSONTyped,
    RelatedEntityTypeToJSON,
} from './RelatedEntityType';

/**
 * 
 * @export
 * @interface PaymentView
 */
export interface PaymentView {
    /**
     * 
     * @type {string}
     * @memberof PaymentView
     */
    paymentUid?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentView
     */
    yookassaOperationId?: string;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof PaymentView
     */
    status?: PaymentStatus;
    /**
     * 
     * @type {string}
     * @memberof PaymentView
     */
    confirmationToken?: string | null;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof PaymentView
     */
    createdTimeUTC?: LocalDateTime;
    /**
     * 
     * @type {number}
     * @memberof PaymentView
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentView
     */
    reservationUid?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentView
     */
    relatedEntityUid?: string;
    /**
     * 
     * @type {RelatedEntityType}
     * @memberof PaymentView
     */
    relatedEntityType?: RelatedEntityType;
}

export function PaymentViewFromJSON(json: any): PaymentView {
    return PaymentViewFromJSONTyped(json, false);
}

export function PaymentViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentUid': !exists(json, 'paymentUid') ? undefined : json['paymentUid'],
        'yookassaOperationId': !exists(json, 'yookassaOperationId') ? undefined : json['yookassaOperationId'],
        'status': !exists(json, 'status') ? undefined : PaymentStatusFromJSON(json['status']),
        'confirmationToken': !exists(json, 'confirmationToken') ? undefined : json['confirmationToken'],
        'createdTimeUTC': !exists(json, 'createdTimeUTC') ? undefined : LocalDateTimeFromJSON(json['createdTimeUTC']),
        'price': !exists(json, 'price') ? undefined : json['price'],
        'reservationUid': !exists(json, 'reservationUid') ? undefined : json['reservationUid'],
        'relatedEntityUid': !exists(json, 'relatedEntityUid') ? undefined : json['relatedEntityUid'],
        'relatedEntityType': !exists(json, 'relatedEntityType') ? undefined : RelatedEntityTypeFromJSON(json['relatedEntityType']),
    };
}

export function PaymentViewToJSON(value?: PaymentView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentUid': value.paymentUid,
        'yookassaOperationId': value.yookassaOperationId,
        'status': PaymentStatusToJSON(value.status),
        'confirmationToken': value.confirmationToken,
        'createdTimeUTC': LocalDateTimeToJSON(value.createdTimeUTC),
        'price': value.price,
        'reservationUid': value.reservationUid,
        'relatedEntityUid': value.relatedEntityUid,
        'relatedEntityType': RelatedEntityTypeToJSON(value.relatedEntityType),
    };
}

