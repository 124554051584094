/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TournamentView,
    TournamentViewFromJSON,
    TournamentViewFromJSONTyped,
    TournamentViewToJSON,
} from './TournamentView';

/**
 * 
 * @export
 * @interface CreateTournamentResponse
 */
export interface CreateTournamentResponse {
    /**
     * 
     * @type {TournamentView}
     * @memberof CreateTournamentResponse
     */
    result?: TournamentView;
    /**
     * 
     * @type {Array<object>}
     * @memberof CreateTournamentResponse
     */
    messages?: Array<object>;
}

export function CreateTournamentResponseFromJSON(json: any): CreateTournamentResponse {
    return CreateTournamentResponseFromJSONTyped(json, false);
}

export function CreateTournamentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTournamentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : TournamentViewFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function CreateTournamentResponseToJSON(value?: CreateTournamentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': TournamentViewToJSON(value.result),
        'messages': value.messages,
    };
}

