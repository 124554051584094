/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseMetricView,
    BaseMetricViewFromJSON,
    BaseMetricViewFromJSONTyped,
    BaseMetricViewToJSON,
} from './BaseMetricView';

/**
 * 
 * @export
 * @interface GameCancelParam
 */
export interface GameCancelParam {
    /**
     * 
     * @type {string}
     * @memberof GameCancelParam
     */
    gameUid?: string;
    /**
     * 
     * @type {BaseMetricView}
     * @memberof GameCancelParam
     */
    metrics?: BaseMetricView;
}

export function GameCancelParamFromJSON(json: any): GameCancelParam {
    return GameCancelParamFromJSONTyped(json, false);
}

export function GameCancelParamFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameCancelParam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gameUid': !exists(json, 'gameUid') ? undefined : json['gameUid'],
        'metrics': !exists(json, 'metrics') ? undefined : BaseMetricViewFromJSON(json['metrics']),
    };
}

export function GameCancelParamToJSON(value?: GameCancelParam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gameUid': value.gameUid,
        'metrics': BaseMetricViewToJSON(value.metrics),
    };
}

