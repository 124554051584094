/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReservationDetails,
    ReservationDetailsFromJSON,
    ReservationDetailsFromJSONTyped,
    ReservationDetailsToJSON,
} from './ReservationDetails';

/**
 * 
 * @export
 * @interface ReservationLoadResponse
 */
export interface ReservationLoadResponse {
    /**
     * 
     * @type {ReservationDetails}
     * @memberof ReservationLoadResponse
     */
    result?: ReservationDetails;
    /**
     * 
     * @type {Array<object>}
     * @memberof ReservationLoadResponse
     */
    messages?: Array<object>;
}

export function ReservationLoadResponseFromJSON(json: any): ReservationLoadResponse {
    return ReservationLoadResponseFromJSONTyped(json, false);
}

export function ReservationLoadResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationLoadResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : ReservationDetailsFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function ReservationLoadResponseToJSON(value?: ReservationLoadResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ReservationDetailsToJSON(value.result),
        'messages': value.messages,
    };
}

