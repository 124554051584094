/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookingAvailableCheckResult
 */
export interface BookingAvailableCheckResult {
    /**
     * 
     * @type {boolean}
     * @memberof BookingAvailableCheckResult
     */
    available?: boolean;
}

export function BookingAvailableCheckResultFromJSON(json: any): BookingAvailableCheckResult {
    return BookingAvailableCheckResultFromJSONTyped(json, false);
}

export function BookingAvailableCheckResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingAvailableCheckResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'available': !exists(json, 'available') ? undefined : json['available'],
    };
}

export function BookingAvailableCheckResultToJSON(value?: BookingAvailableCheckResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'available': value.available,
    };
}

