import dayjs from 'dayjs';
import { Calendar, Clock, MapPoint } from '../Icon/icons';
import { SubscriptionCardProps } from './SubscriptionCard.interfaces';
import styles from './SubscriptionCard.module.css';
import CountCircle from '../CountCircle/CountCircle';
import Medal from '../Icon/components/Medal';

const SubscriptionCard: React.FC<SubscriptionCardProps> = (props) => {
  const { subscription, cardClassName, expired } = props;

  return (
    <div className={[styles['card'], cardClassName].join(' ')}>
      <span className={!(subscription.startDate && subscription.endDate) ? styles['no-time'] : ''}><MapPoint className={styles.icon}/>{subscription.title}</span>
      <span><Medal className={`${styles.icon} ${expired ? styles.medal_expired : styles.medal_active}`}/>{subscription.displayMinGrade}...{subscription.displayMaxGrade}</span>
      {subscription.startDate && subscription.endDate ? <span><Calendar className={styles.icon}/>{dayjs(subscription.startDate).format('DD.MM')} - {subscription.endDate ? dayjs(subscription.endDate).format('DD.MM') : ''}</span> : null}
      <span><Clock className={styles.icon}/>{subscription.startTime?.slice(0, 5)} - {subscription.endTime?.slice(0, 5)}</span>
      {subscription.countTourGame ? 
        <CountCircle 
          children={subscription.countTourGame}
          className={styles['countCircle']}  
        /> 
      : null}
    </div>
  );
}
 
export default SubscriptionCard;
