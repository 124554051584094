import { InputHTMLAttributes, useCallback } from 'react';
import styles from './SelectTime.module.css'
import { isBooked, timeData } from './helper';
import { AvailableSlot, LocalDateTime } from '@/integration-api/server-rest-lundapadelApi';
import dayjs from 'dayjs';
import { isWithinDay } from '../../utils';
export interface Time {
  time: string
}
interface SelectTimeInterface<T> extends InputHTMLAttributes<HTMLInputElement> {
  formikValues?: T;
  bookedTimes?: Array<LocalDateTime>;
  availableSlots?: Array<AvailableSlot>
}

function SelectTime<T extends Record<string, any>>({ formikValues, bookedTimes, availableSlots, ...props }: SelectTimeInterface<T>) {
  const renderTimeData = useCallback(() => {
    if(availableSlots) {
      return availableSlots.length ? availableSlots.map((slot) => (
        <label key={slot.time}>
          <input className={styles.radioInput} type="radio" name='plannedDate.time' checked={formikValues?.plannedDate?.time === dayjs(slot.time, 'HH:mm:ss').format('H:mm') ?? false} value={dayjs(slot.time, 'HH:mm:ss').format('H:mm')} {...props} />
          <div className={`${styles.timeItem} ${slot.reserved ? styles.booked 
            : slot.gamePlanned ? styles.reserved : ''}`}>
            {dayjs(slot.time, 'HH:mm:ss').format('H:mm')}
          </div>
        </label>
      )) : (
        <div className={styles['placeholder']}>
          <span>Свободные корты не найдены.<br/>Выберите другое время для бронирования</span>
        </div>
      )
    } else {
      const validTimeData = timeData.filter(time => formikValues?.duration ? isWithinDay(time.time, formikValues?.duration!) : time);
      return validTimeData.map((el: Time, index: number) => (
        <label
          key={index}>
          <input className={styles.radioInput} type="radio" name='plannedDate.time' checked={formikValues?.plannedDate?.time === el.time ?? false} value={el.time} {...props} />
          <div
            key={index}
            className={`${styles.timeItem} ${bookedTimes && isBooked(bookedTimes, el) ? styles.reserved : ''}`} >
            {el.time}
          </div>
        </label>
      ));
    }
  }, [formikValues?.plannedDate?.time, formikValues?.duration, bookedTimes, availableSlots])

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Выберите время</div>
      <div className={styles.timeContainer}>
        {renderTimeData()}
      </div>
    </div>
  );
}

export default SelectTime;
