/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalDateTime,
    LocalDateTimeFromJSON,
    LocalDateTimeFromJSONTyped,
    LocalDateTimeToJSON,
} from './LocalDateTime';
import {
    PlayerProfileInfo,
    PlayerProfileInfoFromJSON,
    PlayerProfileInfoFromJSONTyped,
    PlayerProfileInfoToJSON,
} from './PlayerProfileInfo';
import {
    RelatedEntityType,
    RelatedEntityTypeFromJSON,
    RelatedEntityTypeFromJSONTyped,
    RelatedEntityTypeToJSON,
} from './RelatedEntityType';
import {
    RequestStatus,
    RequestStatusFromJSON,
    RequestStatusFromJSONTyped,
    RequestStatusToJSON,
} from './RequestStatus';

/**
 * 
 * @export
 * @interface OwnerChangeRequestView
 */
export interface OwnerChangeRequestView {
    /**
     * 
     * @type {string}
     * @memberof OwnerChangeRequestView
     */
    relatedEntityUid?: string;
    /**
     * 
     * @type {RelatedEntityType}
     * @memberof OwnerChangeRequestView
     */
    relatedEntityType?: RelatedEntityType;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof OwnerChangeRequestView
     */
    sender?: PlayerProfileInfo;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof OwnerChangeRequestView
     */
    player?: PlayerProfileInfo;
    /**
     * 
     * @type {RequestStatus}
     * @memberof OwnerChangeRequestView
     */
    status?: RequestStatus;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof OwnerChangeRequestView
     */
    sendDate?: LocalDateTime;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof OwnerChangeRequestView
     */
    plannedDate?: LocalDateTime;
    /**
     * 
     * @type {number}
     * @memberof OwnerChangeRequestView
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof OwnerChangeRequestView
     */
    clubName?: string;
}

export function OwnerChangeRequestViewFromJSON(json: any): OwnerChangeRequestView {
    return OwnerChangeRequestViewFromJSONTyped(json, false);
}

export function OwnerChangeRequestViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnerChangeRequestView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'relatedEntityUid': !exists(json, 'relatedEntityUid') ? undefined : json['relatedEntityUid'],
        'relatedEntityType': !exists(json, 'relatedEntityType') ? undefined : RelatedEntityTypeFromJSON(json['relatedEntityType']),
        'sender': !exists(json, 'sender') ? undefined : PlayerProfileInfoFromJSON(json['sender']),
        'player': !exists(json, 'player') ? undefined : PlayerProfileInfoFromJSON(json['player']),
        'status': !exists(json, 'status') ? undefined : RequestStatusFromJSON(json['status']),
        'sendDate': !exists(json, 'sendDate') ? undefined : LocalDateTimeFromJSON(json['sendDate']),
        'plannedDate': !exists(json, 'plannedDate') ? undefined : LocalDateTimeFromJSON(json['plannedDate']),
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'clubName': !exists(json, 'clubName') ? undefined : json['clubName'],
    };
}

export function OwnerChangeRequestViewToJSON(value?: OwnerChangeRequestView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'relatedEntityUid': value.relatedEntityUid,
        'relatedEntityType': RelatedEntityTypeToJSON(value.relatedEntityType),
        'sender': PlayerProfileInfoToJSON(value.sender),
        'player': PlayerProfileInfoToJSON(value.player),
        'status': RequestStatusToJSON(value.status),
        'sendDate': LocalDateTimeToJSON(value.sendDate),
        'plannedDate': LocalDateTimeToJSON(value.plannedDate),
        'duration': value.duration,
        'clubName': value.clubName,
    };
}

