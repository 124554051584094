import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './GameSuggestionsSection.module.css';
import { gameCreateSuggest, gameCreateSuggestLoadList } from './service';
import { GameCreateSuggestInfo, GameCreateSuggestParameters } from '@/integration-api/server-rest-lundapadelApi';
import { ErrorUtils } from '@/utils/utils';
import { Link, useNavigate } from 'react-router-dom';
import Headling from '@/components/Headling/Headling';
import CountCircle from '@/components/CountCircle/CountCircle';
import ActiveReservationCard from '@/components/ActiveReservationCard/ActiveReservationCard';
import SeeMore from '@/components/SeeMore/SeeMore';

const GameSuggestionsSection = () => {
  const navigate = useNavigate();
  const [gameSuggestions, setGameSuggestions] = useState<Array<GameCreateSuggestInfo>>([]);

  async function loadGameSuggestions() {
    try {
      const { result } = await gameCreateSuggestLoadList();
      setGameSuggestions(result?.infos ?? []);
    } catch(err) {
      ErrorUtils.handleErrorMessage(err);
    }
  }

  const handleGameSuggestion = useCallback(async (params: GameCreateSuggestParameters) => {
    try {
      const { result } = await gameCreateSuggest(params);
      if(result?.gameUid) {
        navigate(`/game/${result.gameUid}`);
      } else {
        await loadGameSuggestions();
      }
    } catch(err) {
      ErrorUtils.handleErrorMessage(err);
    }
  }, [navigate]);

  const suggestionClickHandler = useCallback((sugg: GameCreateSuggestInfo) => {
    const jsonReservation = JSON.stringify({ ids: sugg.ids })
    sessionStorage.setItem('currentReservation', jsonReservation);
    navigate(`/profile/me/reservations/reservation`);
  }, [navigate]);

  const renderGameSuggestions = useMemo(() => {
    const wrapperStyles = gameSuggestions?.length > 1 ? styles['list'] : styles['single'];
    return (
      <div className={wrapperStyles}>
        {gameSuggestions.map(sugg => (
          <ActiveReservationCard
            type='profile'
            expired={false}
            reservation={sugg}
            createSuggest={handleGameSuggestion}
            onClick={() => suggestionClickHandler(sugg)}
          />
        ))}
      </div>
    )
  }, [gameSuggestions, suggestionClickHandler]);

  useEffect(() => {
    loadGameSuggestions();
  }, [])

  return gameSuggestions.length ? (
    <section className={styles['suggestions']}>
      <Headling appearence='big-normal' className={styles['headling']}>
        <div className={styles['headling-text']}>
          <span>Бронирования YClients</span>
          <CountCircle children={gameSuggestions.length}/>
        </div>
        <Link to={`/profile/me/reservations`}>
          <SeeMore>Все</SeeMore>
        </Link>
      </Headling>
      {renderGameSuggestions}
    </section>
  ) : null;
}
 
export default GameSuggestionsSection;
