/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GameView,
    GameViewFromJSON,
    GameViewFromJSONTyped,
    GameViewToJSON,
} from './GameView';

/**
 * 
 * @export
 * @interface UpdateGameRequest
 */
export interface UpdateGameRequest {
    /**
     * 
     * @type {GameView}
     * @memberof UpdateGameRequest
     */
    parameters?: GameView;
}

export function UpdateGameRequestFromJSON(json: any): UpdateGameRequest {
    return UpdateGameRequestFromJSONTyped(json, false);
}

export function UpdateGameRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateGameRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : GameViewFromJSON(json['parameters']),
    };
}

export function UpdateGameRequestToJSON(value?: UpdateGameRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': GameViewToJSON(value.parameters),
    };
}

