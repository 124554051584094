import MessageBlock from './MessageBlock/MessageBlock';
import DialogBlock from './DialogBlock/DialogBlock';
import Header from '@/components/Header/Header';
import styles from './ChatPage.module.css'
import { getMessagesDateBlocks } from './utils';
import { ChatPageProps } from './ChatPage.intefaces';
import { ChatContextWrapper } from './ChatContext';
import { FeedbackMessageView } from '@/integration-api/server-rest-lundapadelApi';
import FullScreenPage from '../Layouts/FullScreenPage';
import { useRef } from 'react';
import { useCapacitorKeyboard } from '@/hooks/useCapacitorKeyboard';

function ChatPage<T extends FeedbackMessageView>({ header, messageList, handleSendMessage }: ChatPageProps<T>) {
  const ref = useRef<HTMLDivElement>(null);
  useCapacitorKeyboard(ref.current);

  return (
    <ChatContextWrapper>
      <FullScreenPage header={
        <Header className={styles['header']}>
          {header}
        </Header>
      }>
        <div className={styles['content']} ref={ref}>
          <DialogBlock messagesBlocks={getMessagesDateBlocks(messageList)} />
          <MessageBlock handleSendMessage={handleSendMessage} />
        </div>
      </FullScreenPage>
    </ChatContextWrapper>
  );
}

export default ChatPage;
