import { HTMLAttributes, memo } from 'react';
import styles from './HorizontalClubListItem.module.css';
import Wallet from '@/components/Icon/components/Wallet';

interface HorizontalClubListItemProps extends HTMLAttributes<HTMLDivElement> {
  clubName: string;
  clubCity?: string;
  yClientsIntegrated?: boolean;
}

const HorizontalClubListItem = ({ clubCity, clubName, yClientsIntegrated, ...props }: HorizontalClubListItemProps) => {
  return (
    <div {...props} className={styles['item-wrapper']} style={{ minWidth: clubName.length > 15 ? `${132 + (clubName.length - 15) * 6.9}px`: '132px'}}>
      {yClientsIntegrated ? <Wallet/> : null}
      <div className={styles['text-wrapper']}>
        <span className={styles['item-name']}>{clubName}</span>
        <span className={styles['item-city']}>{clubCity}</span>
      </div>
    </div>
  );
}
 
export default memo(HorizontalClubListItem);
