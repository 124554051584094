import styles from '../GamePage.module.css';
import Header from '@/components/Header/Header';
import { useState } from 'react';
import CompletedGameContent from '../GameStatusContent/CompletedGameContent';
import bgImage from '@/static/images/match-page-background.jpeg';
import PageBackground from '@/components/PageBackground/PageBackground';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { IGamePageContext } from '../GamePage.interfaces';
import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import ChatButton from '@/components/ChatButton/ChatButton';

const LINEUP_STORAGE_KEY = 'lineup';

const CompletedGamePage = () => {
  const { gameChats, goBackHandler } = useOutletContext<IGamePageContext>();
  const { id } = useParams();
  const navigate = useNavigate();

  const [activeLineup, setActiveLineup] = useState<string | undefined>(
    sessionStorage.getItem(LINEUP_STORAGE_KEY) || undefined
  );

  const updateLineup = (lineUp?: string) => {
    sessionStorage.setItem(LINEUP_STORAGE_KEY, lineUp || '');
    setActiveLineup(lineUp);
  };

  const handleBackBtnClick = () => {
    const lastPaths = sessionStorage.getItem('lastPaths');

    try {
      if (
        lastPaths &&
        (JSON.parse(lastPaths) as Array<string>)[0].includes('chat')
      ) {
        return () => {
          navigate('/my-games?tab=completed');
        };
      }
    } catch (err) {
      if (err instanceof Error) {
        console.log(err.message);
      } else {
        console.log(err);
      }
    }

    return !activeLineup
      ? goBackHandler()
      : () => {
          updateLineup(undefined);
        };
  };

  return (
    <BasePageWrapper>
      <Header
        className={styles['header']}
        handleClick={handleBackBtnClick()}
        additional={
          !activeLineup && id ? (
            <div className={styles['header-buttons']}>
              <ChatButton enitity="game" chats={gameChats} id={id} />
            </div>
          ) : undefined
        }
      >
        {!activeLineup ? 'Результаты игры' : 'Детали матча'}
      </Header>
      {activeLineup ? <PageBackground imgSrc={bgImage} /> : null}
      <CompletedGameContent
        activeLineup={activeLineup}
        setActiveLineup={updateLineup}
      />
    </BasePageWrapper>
  );
};

export default CompletedGamePage;
