/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateYookassaPaymentRequest,
    CreateYookassaPaymentRequestFromJSON,
    CreateYookassaPaymentRequestToJSON,
    CreateYookassaPaymentResponse,
    CreateYookassaPaymentResponseFromJSON,
    CreateYookassaPaymentResponseToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    GetEnableStatusYookassaResponse,
    GetEnableStatusYookassaResponseFromJSON,
    GetEnableStatusYookassaResponseToJSON,
    LoadYookassaPaymentResponse,
    LoadYookassaPaymentResponseFromJSON,
    LoadYookassaPaymentResponseToJSON,
    SaveStatusYookassaPaymentRequest,
    SaveStatusYookassaPaymentRequestFromJSON,
    SaveStatusYookassaPaymentRequestToJSON,
    SaveStatusYookassaPaymentResponse,
    SaveStatusYookassaPaymentResponseFromJSON,
    SaveStatusYookassaPaymentResponseToJSON,
} from '../models';

export interface LundapadelYookassaApiCreateYookassaPaymentOperationRequest {
    createYookassaPaymentRequest: CreateYookassaPaymentRequest;
}

export interface LundapadelYookassaApiLoadYookassaPaymentRequest {
    paymentUid: string;
}

export interface LundapadelYookassaApiSaveStatusYookassaPaymentOperationRequest {
    saveStatusYookassaPaymentRequest: SaveStatusYookassaPaymentRequest;
}

/**
 * 
 */
export class LundapadelYookassaApi extends runtime.BaseAPI {

    /**
     * Create Yookassa Payment for Booking Court
     */
    async createYookassaPaymentRaw(requestParameters: LundapadelYookassaApiCreateYookassaPaymentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreateYookassaPaymentResponse>> {
        if (requestParameters.createYookassaPaymentRequest === null || requestParameters.createYookassaPaymentRequest === undefined) {
            throw new runtime.RequiredError('createYookassaPaymentRequest','Required parameter requestParameters.createYookassaPaymentRequest was null or undefined when calling createYookassaPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/yookassa/create-payment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateYookassaPaymentRequestToJSON(requestParameters.createYookassaPaymentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateYookassaPaymentResponseFromJSON(jsonValue));
    }

    /**
     * Create Yookassa Payment for Booking Court
     */
    async createYookassaPayment(requestParameters: LundapadelYookassaApiCreateYookassaPaymentOperationRequest, initOverrides?: RequestInit): Promise<CreateYookassaPaymentResponse> {
        const response = await this.createYookassaPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Players check enabled status
     */
    async getEnableStatusYookassaRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetEnableStatusYookassaResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/yookassa/check-enable-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEnableStatusYookassaResponseFromJSON(jsonValue));
    }

    /**
     * Players check enabled status
     */
    async getEnableStatusYookassa(initOverrides?: RequestInit): Promise<GetEnableStatusYookassaResponse> {
        const response = await this.getEnableStatusYookassaRaw(initOverrides);
        return await response.value();
    }

    /**
     * Load Yookassa Payment for Booking Court
     */
    async loadYookassaPaymentRaw(requestParameters: LundapadelYookassaApiLoadYookassaPaymentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadYookassaPaymentResponse>> {
        if (requestParameters.paymentUid === null || requestParameters.paymentUid === undefined) {
            throw new runtime.RequiredError('paymentUid','Required parameter requestParameters.paymentUid was null or undefined when calling loadYookassaPayment.');
        }

        const queryParameters: any = {};

        if (requestParameters.paymentUid !== undefined) {
            queryParameters['paymentUid'] = requestParameters.paymentUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/yookassa/load-payment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadYookassaPaymentResponseFromJSON(jsonValue));
    }

    /**
     * Load Yookassa Payment for Booking Court
     */
    async loadYookassaPayment(requestParameters: LundapadelYookassaApiLoadYookassaPaymentRequest, initOverrides?: RequestInit): Promise<LoadYookassaPaymentResponse> {
        const response = await this.loadYookassaPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Save status Yookassa Payment for Booking Court
     */
    async saveStatusYookassaPaymentRaw(requestParameters: LundapadelYookassaApiSaveStatusYookassaPaymentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SaveStatusYookassaPaymentResponse>> {
        if (requestParameters.saveStatusYookassaPaymentRequest === null || requestParameters.saveStatusYookassaPaymentRequest === undefined) {
            throw new runtime.RequiredError('saveStatusYookassaPaymentRequest','Required parameter requestParameters.saveStatusYookassaPaymentRequest was null or undefined when calling saveStatusYookassaPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/yookassa/save-status-payment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaveStatusYookassaPaymentRequestToJSON(requestParameters.saveStatusYookassaPaymentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SaveStatusYookassaPaymentResponseFromJSON(jsonValue));
    }

    /**
     * Save status Yookassa Payment for Booking Court
     */
    async saveStatusYookassaPayment(requestParameters: LundapadelYookassaApiSaveStatusYookassaPaymentOperationRequest, initOverrides?: RequestInit): Promise<SaveStatusYookassaPaymentResponse> {
        const response = await this.saveStatusYookassaPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
